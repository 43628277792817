import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import circle from '../../../assets/images/admin/circle.png';
import exclamation from '../../../assets/images/admin/exclamation.jpg';
import iconGreen from '../../../assets/images/admin/icon-green.png';
import completedGreen from '../../../assets/images/admin/completed-green.svg';
import inProgressGren from '../../../assets/images/admin/in-progress.svg';

/* A Data Box Component */
class DataBoxComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            totalCounts: 0,
            newCount : 0,
            completedCounts: 0,
            inProgressCounts: 0,
            callScheduledCounts: 0,
            technicalErrorCounts: 0,
            notStartedCounts: 0,
        };

    }
    componentWillMount() {
        if(this.props.equipment && this.props.equipment.eventsCount){
            let eventsCount = this.props.equipment.eventsCount
            this.setState({ ...eventsCount });
            if(!this.props.isService){
                this.setState({
                    newCount : (eventsCount.totalCounts - (eventsCount.completedCounts+eventsCount.inProgressCounts+eventsCount.callScheduledCounts+eventsCount.technicalErrorCounts+eventsCount.notStartedCounts))
                })
            }
        }
    }

    componentWillReceiveProps(nextProps) {
        //if (this.props !== nextProps) {
            if(nextProps.equipment){
                let eventsCount = nextProps.equipment.eventsCount
                //if(!nextProps.search  && !nextProps.paginate){
                if(!nextProps.paginate && !nextProps.search){
                    this.setState({ ...eventsCount });
                    if(!this.props.isService){
                        let newCount = (eventsCount.totalCounts - (eventsCount.completedCounts+eventsCount.inProgressCounts+eventsCount.callScheduledCounts+eventsCount.technicalErrorCounts+eventsCount.notStartedCounts));
                        newCount = (newCount < 0) ? 0 : newCount;
                        this.setState({
                            newCount : newCount
                        })
                    }
                }
            }
        //}
    }
    
    render() {
        const isServiceClass = 'col-lg-2-sv p-1';
        return (
            <div className="databoxcomponent">
                <Row>
                    <Col xs={6} sm={4} lg={2} className={isServiceClass}><div className="box boxred">Total Alerts<div className="countholder">{this.state.totalCounts}</div></div></Col>
                    <Col xs={6} sm={4} lg={1} className={isServiceClass}><div className="box boxcountred greyed">New<div className="countholder">{this.state.newCount}</div></div></Col>
                    <Col xs={6} sm={4} lg={2} className={isServiceClass}><div className="box boxgreen"><img src={completedGreen} alt="Completed" className='box-img' />Completed<div className="countholder">{this.state.completedCounts}</div></div></Col>
                    <Col xs={6} sm={4} lg={2} className={isServiceClass}><div className="box boxgreen"><img src={inProgressGren} alt="In Progress" className='box-img' />In Progress<div className="countholder">{this.state.inProgressCounts}</div></div></Col>
                    <Col xs={6} sm={4} lg={2} className={isServiceClass}><div className="box boxgreen"><img src={iconGreen} alt="Call Scheduled" className='box-img' />Call Scheduled<div className="countholder">{this.state.callScheduledCounts}</div></div></Col>
                    <Col xs={6} sm={4} lg={2} className={isServiceClass}><div className="box boxyellow"><img src={exclamation} alt="Technical Error" className='box-img' /> Technical Error<div className="countholder">{this.state.technicalErrorCounts}</div></div></Col>
                    <Col xs={6} sm={4} lg={2} className={isServiceClass}><div className="box boxprimary"><img src={circle} alt="Started" className='box-img' />Started<div className="countholder">{this.state.notStartedCounts}</div></div></Col>
                </Row>
            </div>
        );
    }
}

export default DataBoxComponent;
