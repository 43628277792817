import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Table, Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, FormGroup, Label } from 'reactstrap';
import InputField  from '../../../../components/shared/InputField';
import SelectField  from '../../../../components/shared/SelectField';
import moment from 'moment';
import Pagination from '../../../shared/Pagination';
import DatePicker from 'react-datepicker';
import {FaSort, FaSortUp, FaSortDown, FaPencilAlt, FaExternalLinkAlt} from 'react-icons/fa';
import circle from '../../../../assets/images/admin/circle.png';
import completedGreen from '../../../../assets/images/admin/completed-green.svg';
import inProgressGren from '../../../../assets/images/admin/in-progress.svg';
import Loaders from '../../../../messages/loaders/Loaders';
import { Alert } from 'reactstrap';
import Moment from "react-moment";

/* A DataSheet component */
class DataSheet extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data :{
                "maintenanceId": "",
                "application": "",
                "title": "",
                "message": "",     
                "startDate": "",
                "endDate": ""
            },
            modal: false,
            systemMaintainList: [],
            systemMaintainTotal : 0,
            errorAPI : false,
            notify: false,
            notifyType: 'success',
            notifyText: '',
            showLoader : true,
            updated: false,
            allowUpdate : true,
            actionRow: null,
            activePage: 1,
            key: null,
            click: true,
            sortingParams: null,
            errdata: undefined
        };

        this.onBlur = this.onBlur.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }
    componentWillMount() {
        if(this.props.maintainSysData && this.props.maintainSysData.SET_ALERT_NOTIFICATION_DATA){
            this.setState({
                systemMaintainList: this.props.maintainSysData.SET_ALERT_NOTIFICATION_DATA,
                showLoader: this.props.maintainSysData.isSending
            });
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.maintainSysData !== nextProps.maintainSysData) {
            if(nextProps.maintainSysData && nextProps.maintainSysData.SET_ALERT_NOTIFICATION_DATA){
                this.setState({
                    systemMaintainList: nextProps.maintainSysData.SET_ALERT_NOTIFICATION_DATA.systemMaintenance,
                    showLoader : nextProps.maintainSysData.isSending,
                    systemMaintainTotal: (nextProps.maintainSysData.SET_ALERT_NOTIFICATION_DATA.eventsCount.totalCounts === 0 && this.state.systemMaintainTotal !== null) ? this.state.systemMaintainTotal : nextProps.maintainSysData.SET_ALERT_NOTIFICATION_DATA.eventsCount.totalCounts,
                    updated: false
                });
            }
        }
        if ((this.props.sysMaintenance !== nextProps.sysMaintenance) && !nextProps.maintainSysData.isSending ) {
            this.setState({
                showLoader : nextProps.sysMaintenance.isSending,
                updated: false
            });
            if(nextProps.sysMaintenance && nextProps.sysMaintenance.SET_UPDATE_MAINTAIN_DATA && !nextProps.sysMaintenance.isSending){
                this.props.setToastr({
                    toastr:{
                        type: (nextProps.sysMaintenance.error === true) ? 'error' : 'success',
                        message: nextProps.sysMaintenance.resMsg || 'Maintenance Details Updated'
                    }
                });
                /* this.setState({
                    notify: true,
                    notifyType: (nextProps.sysMaintenance.error === true) ? 'danger' : 'success',
                    notifyText: (nextProps.sysMaintenance.error === true) ? nextProps.sysMaintenance.resMsg : 'Maintenance Details Updated'
                }); */

                if(this.state.errdata && !nextProps.sysMaintenance.error){
                    this.setState({
                        systemMaintainList: this.state.errdata
                    });
                }
            }

        }

        if(this.props.apiError !== nextProps.apiError){
            if(nextProps.apiError!== false){
                this.setState({
                    errorAPI : true,
                    showLoader:false
                })
            }
        }
    }

    onBlur(type, data) {
        data = moment(new Date(data.target.value)).format('D MMM YYYY HH:mm:00');

        this.setState( { data: { ...this.state.data, [type]: data } });
    }

    handleChange(value,e){
        let inputName, inputValue 
        this.setState({allowUpdate:true});
        if((value === "startDate" || value === "endDate")){
            if(e){
                inputName = value;
                inputValue = e.format('DD MMM YYYY HH:mm:00');
            }else{
                inputName = value;
                inputValue = undefined;
            }
        }else if(e){
            inputName = e.target.name;
            inputValue = e.target.value; 
        }
        this.setState({
            data: {
                ...this.state.data, [inputName]: inputValue
            } 
        },()=>{
            if(!moment(new Date()).isBefore(this.state.data.startDate, "minute")){
                document.getElementById('startDate').className = "border-danger"
                this.setState({allowUpdate:false})  
            }
            if(!moment(this.state.data.endDate).isSameOrAfter(moment(this.state.data.startDate)))
            {
                document.getElementById('endDate').className = "border-danger"
                document.getElementById('startDate').className = "border-danger"
                this.setState({allowUpdate:false})
            }
        });
    }

    handleSubmit(index,e){
        let data = this.state.systemMaintainList.map((value,loopndex)=>{
            return (index === value.maintenanceId)? this.state.data : value ;
        });
        if(this.state.allowUpdate){
            if(this.state.data.title === "")
            {
                document.getElementById('title').className = "form-control border-danger"
            }else if(this.state.data.message === ""){
                document.getElementById('title').className = "form-control"
                document.getElementById('message').className = "form-control border-danger"
            }else if(!this.state.data.startDate){
                document.getElementById('title').className = "form-control"
                document.getElementById('message').className = "form-control"
                document.getElementById('startDate').className = "form-control border-danger"
            }else if(!this.state.data.endDate){
                document.getElementById('title').className = "form-control"
                document.getElementById('message').className = "form-control"
                document.getElementById('startDate').className = "form-control"
                document.getElementById('endDate').className = "form-control border-danger"
            }else{
                document.getElementById('message').className = "form-control"
                this.props.handleSubmit(this.state.data)
                this.setState({actionRow: null,updated: true, errdata : data});
                this.cancelEditRow(index,e);
            }
        }else{
            if(!this.state.data.startDate){
                document.getElementById('startDate').className = "form-control border-danger"
            }else if(!this.state.data.endDate){
                document.getElementById('startDate').className = "form-control"
                document.getElementById('endDate').className = "form-control border-danger"
            }
        }
    }

    onDismiss() {
        this.setState({ notify: false });
    }
    
    toggle(index,value,e, type, a) {
        this.setState({
            modal: true,
            data : value
        });
    }

    render() {
        const { translate, className } = this.props;
        let sortType = (!this.state.click) ? <FaSortUp/> : <FaSortDown/>
        return (
            <div className={`datasheetsys ${className}`}>
                { this.state.showLoader && <Loaders
                    loadingText={ `Please Wait...` }
                    modalOpen={ true }
                    className={ `loading-contents` }
                    color={ '#08a5e1' }
                    /> 
                } 
                { this.state.notify && <Alert color={this.state.notifyType} isOpen={this.state.notify} toggle={this.onDismiss.bind(this)}> {this.state.notifyText}</Alert> }
                <Table className="tabledashboard table-responsive table table-striped table-advance table-hover">
                    <thead>
                        <tr >
                            <th colSpan="5" className="myspecialth xs-show-table">{ '' }</th>
                            <th>{translate('status') }</th>
                            <th onClick={() => this.sortBy('application')}>{translate('application') }
                                {(this.state.key === 'application') ? sortType : <FaSort/>}
                            </th>
                            <th onClick={() => this.sortBy('title')}>{translate('messageTitle') } 
                                {(this.state.key === 'title') ? sortType : <FaSort/>}
                            </th>
                            <th onClick={() => this.sortBy('start_date')}>{translate('startOn') } 
                                {(this.state.key === 'start_date') ? sortType : <FaSort/>}
                            </th>
                            <th onClick={() => this.sortBy('end_date')}>{translate('endsOn') }
                                {(this.state.key === 'end_date') ? sortType : <FaSort/>}
                            </th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        { this.getTableBody() }
                    </tbody>
                </Table>
                <Pagination
                    limit={15}
                    total={this.state.systemMaintainTotal}
                    activePage={this.state.activePage}
                    onChange={this.handlePageChange.bind(this) }
                    data={this.state.systemMaintainList}
                    />
                <div>
                    <img className="completed" src={completedGreen} alt="completed"/> Completed
                    <img className="inprogress" src={inProgressGren} alt="In Progress"/> In Progress
                    <img className="notstart" src={circle} alt="Started"/> Started
                </div>
                <Modal isOpen={this.state.modal} toggle={this.cancelEditRow.bind(this)} className={this.props.className}>
                    <ModalHeader toggle={this.cancelEditRow.bind(this)}>Update Maintenance Details</ModalHeader>
                    <ModalBody>
                    <Form className ={this.state.data.status  ==='Completed' ? 'lock': ''}>
                        <FormGroup>
                            <Label for="application">Application</Label>
                            <SelectField
                                divClass="selectwrapper"
                                handleChange={ (e) => this.handleChange(this.state.data.application, e) }
                                options = {[
                                    <option key={0} value="SMARTASSIST SERVICE">SMARTASSIST SERVICE</option>,
                                    <option key={1} value="SMARTASSIST CUSTOMER">SMARTASSIST CUSTOMER</option>,
                                ]}
                                type = "select"
                                name = "application"
                                disabled={(this.state.data.status === 'Completed' || this.state.data.status === 'In Progress') ? true : false}
                                value = {(this.state.data.application.trim())}
                                className = { (false)?'border-danger width-edit': 'width-edit' }
                                defaultOption = {null}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="application">Title</Label>
                            <InputField
                                value={this.state.data.title} 
                                max={50}
                                id="title"
                                disabled={(this.state.data.status === 'Completed' || this.state.data.status === 'In Progress') ? true : false}
                                name="title"
                                handleChange={ (e) => this.handleChange(this.state.data.title, e) }
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="application">Message</Label>
                            <textarea name="message" id="message" onChange={ (e) => this.handleChange(this.state.data.title, e) } className="width-edit form-control" row={20} value={this.state.data.message} disabled={(this.state.data.status === 'Completed' || this.state.data.status === 'In Progress') ? true : false}></textarea>
                        </FormGroup>
                        <FormGroup>
                            <Label for="application">Start Date</Label>
                            <DatePicker
                                selected={moment(this.state.data.startDate)}
                                autoComplete='off'
                                onChange={(e) => this.handleChange('startDate', e) }
                                timeIntervals={15}
                                dateFormat="DD MMM YY HH:mm:00"
                                minDate={moment(new Date())}
                                showTimeSelect
                                timeFormat="HH:mm"
                                id="startDate"
                                disabled={(this.state.data.status === 'Completed' || this.state.data.status === 'In Progress') ? true : false}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="application">End Date</Label>
                            <DatePicker
                                selected={moment(this.state.data.endDate)}
                                autoComplete='off'
                                onChange={(e) => this.handleChange('endDate', e) }
                                timeIntervals={15}
                                dateFormat="DD MMM YY HH:mm:00"
                                minDate={moment(new Date())}
                                showTimeSelect
                                timeFormat="HH:mm"
                                id="endDate"
                                disabled={(this.state.data.status === 'Completed' || this.state.data.status === 'In Progress') ? true : false}
                            /> 
                        </FormGroup>
                    </Form>
                    </ModalBody>
                    <ModalFooter>
                        
                        {
                          (this.state.data.status  !=='Completed' && this.state.data.status !== 'In Progress') && 
                       
                        <Button color="primary" onClick={this.handleSubmit.bind(this,this.state.data.maintenanceId)}>Update</Button> 
                        }
                        <Button color="danger" onClick={this.cancelEditRow.bind(this)}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }

     editRow(index,value,e){
        this.setState({
            data : value
        })
    }

    cancelEditRow(index,e){
        this.setState({
            modal: false
        });
    }

    
    handlePageChange(page) {
        this.setState({ activePage: page });
        if (page < 2) {
            page = 0;
        } else {
            page = page - 1;
            page = (15 * page);
        }
        this.props.handlePageChange({ page: page, totalEventsFlag: 'false',sortingParams: this.state.sortingParams });
    }

    sortBy(key) {
        let page = this.state.activePage;
        if (page < 2) {
            page = 0;
        } else {
            page = page - 1;
            page = (15 * page);
        }
        let sortingParams = `?fieldName=${key}&order=${((this.state.click === true) ? 'ASC' : 'DESC')}`
        this.props.handlePageChange({ page: page, totalEventsFlag: 'false', sortingParams: sortingParams });
        this.setState({
            key: key,
            click: !this.state.click,
            sortingParams: sortingParams
        });
    }

    /**
     * get the table body as the category listing
     */
    getTableBody = () => {
        let maintainList = '';
        if (this.state.systemMaintainList.length > 0) {
            maintainList = this.state.systemMaintainList.map((value, index) => {
                let status = '';
                if(value.status === "Completed")
                {
                    status = <img className="statuse" alt="completed" src={ completedGreen }/>
                }
                if(value.status === "Started"){
                    status = <img className="statuse" alt="started" src={ circle }/>
                }
                
                if(value.status === "In Progress"){
                   status = <img className="statuse ssadj" alt="inprogress" src={ inProgressGren }/> 
                }

                return (
                    <tr key={ value.maintenanceId }>
                        <td>{status}</td>
                        <td>{value.application }</td>
                        <td>{value.title}</td>
                        <td>

                        {value.startDate || "NA"}
                        
                        {/* {value.startDate ? 
                                            <Moment tz="Asia/Kolkata" format="DD MMM YYYY HH:mm:ss">{value.startDate}</Moment>
                                            : "N/A"
                                        } */}

                        </td>
                        <td>
                        {value.endDate || "NA"}

                        {/* {value.endDate ? 
                                            <Moment tz="Asia/Kolkata" format="DD MMM YYYY HH:mm:ss">{value.endDate}</Moment>
                                            : "N/A"
                                        } */}
                                        </td>
                        <td>
                            {/* {value.status !== 'Completed' && <button className="editbtn" onClick={this.toggle.bind(this,value.maintenanceId,value)}> <FaPencilAlt /></button>} */}
                            
                            <button className="editbtn" 
                                onClick={this.toggle.bind(this,value.maintenanceId,value)}> 
                                {(value.status === 'Completed' || value.status === 'In Progress') ? <FaExternalLinkAlt/> : <FaPencilAlt />}
                            </button>
                        </td>
                    </tr>
                );
            });
        } else {
            if(this.props.maintainSysData && this.props.maintainSysData.SET_ALERT_NOTIFICATION_DATA && this.props.maintainSysData.SET_ALERT_NOTIFICATION_DATA.systemMaintenance  && this.props.maintainSysData.SET_ALERT_NOTIFICATION_DATA.systemMaintenance.length === 0){
                maintainList = <tr className="custom-no-data"><td className="text-center" colSpan={6}> {'No data Found'} </td></tr>;
            }else{
                maintainList = <tr className="custom-no-data"><td className="text-center" colSpan={6}> {(this.state.errorAPI)? 'Network Error' :'Loading data..'} </td></tr>;
            }
        }
        return maintainList
    }

}

export default withRouter(DataSheet);