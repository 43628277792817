import { SET_DEALER_EQUIPMENT_LIST,SET_DEALERS } from '../../types';

/** @type { Object } default state */
let defaultState = {
    data: {
    }
}

/**
 * dispatched the user data to the store
 * @param  { Object } state  default state
 * @param  { Object } action dispacthed action from middleware
 */
export default function dealerManagement(state = defaultState, action = {}) {
    switch (action.type) {
        case SET_DEALER_EQUIPMENT_LIST:
            return { ...state, dealerEquipment : action.data};
        case SET_DEALERS:
            return { ...state, dealerList : action.data};
        default: return state;
    }
}
