import React, { Component } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import ReactDOM from "react-dom";
import { connect } from 'react-redux';
import { Field, reduxForm, change } from "redux-form";
import { renderTextField } from "../../shared/renderField";
/** importing sub-components */
import { setToastr, setLoader } from "../../../actions/Application";
import DataSheet from './datasheet/DataSheet';
import { translate } from '../../../lang';
/** Adding API */
import { getManageServiceCompanyData, saveManageServiceCompanyData } from "../../../api/admin/manageServiceCompany";
import { getManageServiceEquipmentList } from "../../../actions/admin/manageServiceCompany";
class ManageServiceCompanyComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            pages: 0,
            loading: true,
            startIndex: 0,
            numberOfRows: '15',
            totalCounts: true,
            showPageSizeOptions: true,
            companyData: [],
            totalData: 0,
            roles: [],
            roleCode: {},
            sendData: {},
            showCtrl: { 0: false },
            clickedInlineEdit: { 0: false },
            activePage: 1,
            qs: null,
            searchItems: [],
            keyword: "",
            fieldType: ""
        }
        this.getCompanyDetail = this.getCompanyDetail.bind(this)
        this.getCompanyData = this.getCompanyData.bind(this);
        this.saveCompanyData = this.saveCompanyData.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.clearSearch = this.clearSearch.bind(this);
    }

    componentDidMount() {
        this.getCompanyData();
        this.props.getManageServiceEquipmentList();
    }
    goToItem = (attr, fieldType) => {
        //setting value
        if (fieldType == 'departmentCode')
            this.props.dispatch(change('companysearch', 'department', attr));
        else
            this.props.dispatch(change('companysearch', 'company', attr));
        this.setState({ keyword: "", searchItems: [], fieldType: "" });
    }
    getCompanyData(startIndex, qs) {
        this.props.setLoader({ loader: { open: true } });
        let startIndexValue = startIndex == 0 ? startIndex : this.state.startIndex;
        let countFlag = startIndexValue == 0 ? true : false;
        getManageServiceCompanyData(startIndexValue, this.state.numberOfRows, countFlag, this.state.sortingParams, qs)
            .then(result => {
                if (this.state.startIndex == 0) {
                    this.setState({
                        companyData: result.data.msCompanies,
                        totalData: result.data.countResult.totalCounts,
                        roles: result.data.roles,
                        loading: false
                    })
                    this.props.setLoader({ loader: { open: false } });
                }
                else {
                    this.setState({
                        companyData: result.data.msCompanies,
                        totalData: result.data.countResult.totalCounts,
                        roles: result.data.roles,
                        loading: false
                    })
                    this.props.setLoader({ loader: { open: false } });
                }
            })
            .catch(error => {
                this.setState({
                    loading: false
                })
                this.props.setLoader({ loader: { open: false } });
            })
    }
    handleChange = (type, data) => {
        if (data === undefined) {
            data = type.target.value;
            type = type.target.name;
        }
        this.setState(
            {
                roleCode: {
                    ...this.state.roleCode, [type]: data,
                }
            });
    }
    handleInlineEditClick = (index, event) => {
        event.preventDefault();
        this.setState({
            showCtrl: {
                ...this.state.showCtrl, ['index']: index
            }
        })
    }
    getRoleName(key, value) {
        let roles = this.state.roles;
        for (var i = 0; i < roles.length; i++) {
            if (roles[i][key] === value) {
                return roles[i].roleName;
            }
        }
        return null;
    }

    handlePageChange(data) {
        this.setState({
            startIndex: data.page,
            totalCounts: data.totalCounts,
            showCtrl: { 0: false },
            clickedInlineEdit: { 0: false },
            sortingParams: (data.sortingParams === null) ? null : data.sortingParams
        }, () => {
            this.getCompanyData(data.page, this.state.qs);
        })
    }

    updateCompanydata(index, value, r) {
        let companyData = this.state.companyData;
        companyData[index] = {
            'roleDepartmentId': value.roleDepartmentId,
            'roleCode': r,
            'departmentCode': value.departmentCode,
            'companyCode': value.companyCode,
            'companyName': value.companyName,
            'departmentName': value.departmentName,
            'roleName': this.getRoleName('roleCode', r)
        }
        return companyData;
    }

    saveCompanyData(index, value) {
        let roleCode = this.state.roleCode;
        let sendData = {};
        this.props.setLoader({ loader: { open: true } });
        sendData = {
            [index]: {
                'roleDepartmentId': value.roleDepartmentId,
                'roleCode': roleCode[index],
                'departmentCode': value.departmentCode,
                'companyCode': value.companyCode
            }
        }
        let data = JSON.stringify(sendData[index]);
        saveManageServiceCompanyData(data)
            .then(result => {
                this.props.setToastr({ toastr: { message: "Role Code is updated successfully", type: "success" } });
                this.getCompanyData();
                this.props.setLoader({ loader: { open: false } });
                this.setState({
                    showCtrl: { 0: false },
                    clickedInlineEdit: { 0: false }
                })
            })
            .catch(error => {
                this.props.setToastr({ toastr: { message: "Role Code is not updated successfully", type: "error" } });
                this.props.setLoader({ loader: { open: false } });
            })
    }

    clearSearch() {
        this.getCompanyData();
        this.setState({ qs: null });
    }

    onSubmit(values) {
        if('company' in values){
            values = {
                ...values,
               company:encodeURIComponent(values['company'])
           }
        }
        let startIndex = 0;
        this.setState({ qs: values, startIndex: 0, sortingParams: null, totalCounts: true });
        if (!Object.keys(values).length) return false;

        this.props.setLoader({ loader: { open: true } });



        this.getCompanyData(startIndex, values);
    }

    getCompanyDetail(e) {
        let fieldType = e.target.id
        let departmentCodes = [], companyNames = [];

        var invalid = /[°"§%()\[\]{}=\\?´`'#<>|,;.:+_-]+/g;
        let { value } = ReactDOM.findDOMNode(document.getElementById(e.target.id)), { companyList } = this.props, regex = new RegExp(value.replace(invalid, "").trim(), 'i');
        let filtered = companyList && companyList.filter(e => {
            if (!departmentCodes.includes(e.departmentCode.toString()) && fieldType === 'departmentCode') {
                if (regex.test(e.departmentCode.toString())) {
                    departmentCodes.push(e.departmentCode.toString());
                    return e;
                }
            } else if (!companyNames.includes(e.companyName.toString()) && fieldType === 'companyName') {
                if (regex.test(e.companyName)) {
                    companyNames.push(e.companyName.toString());
                    return e;
                }
            }
        });
        this.setState({ searchItems: filtered, keyword: value, fieldType: fieldType });
    }
    render() {
        let { handleSubmit, submitting, dispatch, reset } = this.props;
        let { qs, searchItems, keyword, fieldType } = this.state;

        return (
            <Row className='c-manage-service-company'>
                <form name="companysearch" id="companysearch" className="companysearch" autoComplete='off' onSubmit={handleSubmit(this.onSubmit)}>
                    <Col xs="12">
                        <div className="members align-items-center form-srch">
                            <div className="row profile-asset">
                                <div className="col-sm-4">
                                    Division Code
                                    <Field name="department" id="departmentCode" component={renderTextField} className='form-control' type="text" onChange={this.getCompanyDetail} />
                                    {keyword && searchItems && fieldType === 'departmentCode' && <div className='search-area'>

                                        <Card className='search-card'>
                                            <CardBody>
                                                <ul className="list-group list-group-flush">
                                                    {searchItems.map((item, i) => {
                                                        return <Item key={i} attr={{ item }} goToItem={this.goToItem} fieldType={'departmentCode'} />;
                                                    })}
                                                </ul>
                                            </CardBody>
                                        </Card>
                                    </div>}
                                </div>

                                <div className="col-sm-5">Company
                                    <Field name="company" id="companyName" component={renderTextField} className='form-control' type="text" onChange={this.getCompanyDetail} />
                                    {keyword && searchItems && fieldType === 'companyName' && <div className='search-area'>

                                        <Card className='search-card'>
                                            <CardBody>
                                                <ul className="list-group list-group-flush">
                                                    {searchItems.map((item, i) => {
                                                        return <Item key={i} attr={{ item }} goToItem={this.goToItem} fieldType={'companyName'} />;
                                                    })}
                                                </ul>
                                            </CardBody>
                                        </Card>
                                    </div>}
                                </div>

                                <div className="col-sm-3">
                                    <button type="submit" className="btn btn-primary srch-btn" disabled={submitting}>Search</button>
                                    {qs && Object.keys(qs).length > 0 &&
                                        <> &nbsp;&nbsp;&nbsp;
                                            <button type="button" className="btn btn-danger srch-btn"
                                                onClick={() => { dispatch(reset()); this.clearSearch(); }}
                                                disabled={submitting}>Clear Search</button>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </Col>
                </form>

                <Col xs="12">
                    <DataSheet activePage={this.state.activePage} getCompanyData={this.getCompanyData} showCtrl={this.state.showCtrl} clickedInlineEdit={this.state.clickedInlineEdit} sendData={this.state.sendData} handleInlineEditClick={this.handleInlineEditClick} roleCode={this.state.roleCode} translate={translate} saveCompanyData={this.saveCompanyData} handleChange={this.handleChange} roles={this.state.roles} equipment={this.state.companyData} totalData={this.state.totalData} handlePageChange={this.handlePageChange.bind(this)} />
                </Col>
            </Row>

        );
    }
}
const Item = props => {
    let { attr, goToItem, fieldType } = props;
    var listOption = attr.item[fieldType];
    return (
        <li className="list-group-item pointer" onClick={() => goToItem(listOption, fieldType)} >
            <Row>
                <Col sm='12' className='no-padd-lr'>
                    <span>{listOption}</span> &nbsp;
                </Col>
            </Row>
        </li>
    );
}
const mapDispatchToProps = { setToastr, setLoader, getManageServiceEquipmentList }

const mapStateToProps = state => {
    return ({
        companyList: state.manageService ? state.manageService.data : []

    });
}
export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({ form: "companysearch" })(ManageServiceCompanyComponent));