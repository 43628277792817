import React, { Component } from "react";
import PropTypes from "prop-types";
import { Col, Row } from "reactstrap";
import { connect } from 'react-redux';
import moment from "moment";

import Datepicker from "../../../shared/Datepicker";
//import { hi} from "moment/locale/hi";
import Map from "../../../shared/Map";

//const __moment = moment(hi);
const __moment = moment();
class ChartComponent extends Component {
    
    constructor(props){
        super(props);
        this.state = {
            day: {
                date: __moment.format("DD"),
                month: __moment.format("MMMM"),
                year: __moment.format("YYYY"),
            }
        }
        /** function binding */
        this.getDate = this.getDate.bind(this);
    }

    getDate(date = null){
        let m = moment(date);
        this.setState({
            day: {
                date: m.format("DD"),
                month: m.format("MMMM"),
                year: m.format("YYYY"),
            }
        });
        if (this.props.dataOnOtherDate) this.props.dataOnOtherDate(date);
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.equipment && nextProps.equipment && this.props.equipment.machineSaleId !== nextProps.equipment.machineSaleId) {
            let m = moment();
            this.setState({
                day: {
                    date: m.format("DD"),
                    month: m.format("MMMM"),
                    year: m.format("YYYY"),
                }
            });
        }
        if(this.props.openToDate!= nextProps.openToDate && nextProps.openToDate > 0){
            let m = moment(nextProps.openToDate);
            this.setState({
                day: {
                    date: m.format("DD"),
                    month: m.format("MMMM"),
                    year: m.format("YYYY"),
                }
            });
            
        }
    }

    render(){
        let { equipment, operatingInfo, workDoneDate,translate,openToDate,years } = this.props;
        let { day } = this.state;
        if (!equipment || !Object.keys(equipment).length) return false;
        return (
            <div className='map-chart-area full-col'>
                <Row className='no-gutters'>
                    <Col lg={2} md="6" sm="6" xs="12" className="greyed" align="center">
                        <p className="cal-date">{day.date}</p>
                        <p className="cal-month">{day.month}</p>
                        <p className="cal-year">{day.year}</p>
                    </Col>
                    <Col lg={4} md="6" sm="6" xs="12" style={{ padding: 0, border: '1px solid #d0d0d0' }}>
                        <Datepicker equipment={ equipment } getDate={this.getDate} highlighted={workDoneDate} maxDate={equipment.contractExpiry && moment(equipment.contractExpiry)} openToDate = {openToDate} years={years}/>
                        <div className='d-flex justify-content-around'>
                            <span><span className='block yellow'>&nbsp;</span><span className='cal-text'>{translate('workDone')}</span></span>
                            <span><span className='block red'>&nbsp;</span><span className='cal-text'>{translate('todayDate')}</span></span>
                        </div>
                    </Col>
                    <Col lg={6} md="12" sm="12" xs="12" className='map mobile-map' style={{ padding: 0, border: '1px solid #d0d0d0' }}>
                        <Map linecords={operatingInfo} showLegend={false}/>
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return ({
        operatingInfo: state.dailywork.operatingInfo || {},
        workDoneDate: state.dailywork.workDoneDate || []
    });
};

ChartComponent.propTypes = {
    //equipment: PropTypes.object.isRequired,
    dataOnOtherDate: PropTypes.func.isRequired
}

export default connect(mapStateToProps)(ChartComponent);