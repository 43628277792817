import React, { Component, Fragment } from "react";
import { Row, Col,Card, CardBody } from "reactstrap";
import { withRouter } from "react-router-dom";
import { renderTextField } from "../../shared/renderField";
import { Field, reduxForm, change } from "redux-form";
import { setLoader, setToastr } from "../../../actions/Application";
import { connect } from "react-redux";
import HourmeterOffsetManagementDetailComponent from "./HourmeterOffsetManagementDetail";
import { getHourMeterGap } from "../../../api/admin/hourmeter-offset-management";
import ReactDOM from "react-dom";
import { isEmpty } from "lodash";
import { Link } from 'react-router-dom';

/*global google*/

/* A Hourmeter offset component */
class HourmeterOffsetManagementComponent extends Component {

    constructor(props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
        this.getEquipmentList = this.getEquipmentList.bind(this);
        this.goToItem = this.goToItem.bind(this);
        this.state = {
            data: null,
            searchData: null,
            machineInfo: null,
            searchItems: [],
            fieldType: "",
            model: "",
            machineNum: "",
            startIndex: 0,
            numberOfRows: '15',
            activePage: 1
        }
    }

    goToItem(attr, fieldType) {
        //setting value
        this.props.dispatch(change('SearchForm', fieldType, attr));
        this.setState({ searchItems: [], fieldType: "", [fieldType]: attr });
    }

    componentDidMount() {
        this.getHourMeterGapInfo({ page: 1 });
    }


    onSubmit() {
        this.getHourMeterGapInfo();
    }


    handleCallback = (childData) => {
        // Update the name in the component's state
        this.setState({ activePage: childData });
        this.getHourMeterGapInfo(childData);
    }

    getHourMeterGapInfo(page) {
        let model = this.state && this.state.model;
        let machineNum = this.state && this.state.machineNum;
            
        if(!page && model=="" && machineNum==""){
            var data = {};
            data.hourMeterGapWithOffSet = [];
            this.setState({data : data});
            return;
        }
        this.props.setLoader({ loader: { open: true } });
        //getHourMeterGap(machineNum,model)
        let queryParams = [];
        if (model != '') {
            queryParams.push(`modelId=${model}`);
        }
        if (machineNum != '') {
            queryParams.push(`machineNum=${machineNum}`);
        }
        getHourMeterGap({ page: page || 1, qs: queryParams.join('&') })
            .then(result => {
                this.setState({ data: result.data });
                if(page){
                    this.setState({ searchData: result.data });
                }
                this.props.setLoader({ loader: { open: false } });
            })
            .catch(error => {
                this.props.setToastr({ toastr: { type: "error", message: error || "Something went wrong." } });
                this.props.setLoader({ loader: { open: false } });
            })
    }

    getEquipmentList(e) {
        let fieldType = e.target.id
        let models = [], machines = [];
        this.setState({ [fieldType]: '' })
        let { value } = ReactDOM.findDOMNode(document.getElementById(e.target.id)), regex = new RegExp(value.trim(), 'i');
        let equipmentList = this.state.searchData && this.state.searchData.hourMeterGapWithOffSet;
        if (isEmpty(value)) {
            if (fieldType === 'model') {
                this.setState({ selectedModel: '' });
            } else if (fieldType === 'machineNum') {
                this.setState({ selectedMachine: '' });
            }
        }
        let filtered = equipmentList.filter(e => {
            if (e.model != null && !models.includes(e.model.toString()) && fieldType === 'model') {
                if (regex.test(e.model.toString())) {
                    models.push(e.model.toString());
                    return e;
                }
            } else if (e.machineNum != null && !machines.includes(e.machineNum.toString()) && fieldType === 'machineNum') {
                if (regex.test(e.machineNum)) {
                    machines.push(e.machineNum.toString())
                    return e;
                }
            }
        });
        this.setState({ searchItems: filtered, keyword: value, fieldType: fieldType });
    }

    render() {
        let { handleSubmit } = this.props;
        let { fieldType, searchItems, keyword, activePage } = this.state;
        return (
            <Fragment>
                <form className="equipmentInfo" name="SearchForm" id="SearchForm" autoComplete='off' onSubmit={handleSubmit(this.onSubmit)}>
                    <Row className="members align-items-center form-srch">
                        <Col sm="12">
                            <div className="row profile-asset">
                                <div className="col-sm-6">
                                    <div className="row">
                                        <div className="col-sm-6">Model
                                            <Field name="model" id="model" component={renderTextField} className='form-control' type="text" onChange={this.getEquipmentList} />
                                            {keyword && searchItems && keyword.length > 0 && searchItems.length > 0 && fieldType === 'model' &&
                                                <div className='search-area'>
                                                    <Card className='search-card'>
                                                        <CardBody style={{ overflowY: "auto", maxHeight: "200px" }}>
                                                            <ul className="list-group list-group-flush">
                                                                {searchItems.map((item, i) => {
                                                                    return <Item key={i} attr={{ item }} goToItem={this.goToItem} fieldType={'model'} />;
                                                                })}
                                                            </ul>
                                                        </CardBody>
                                                    </Card>
                                                </div>}
                                            {keyword && searchItems && keyword.length > 0 && searchItems.length == 0 && fieldType === 'model' &&
                                                <div className='search-area'>
                                                    <Card className='search-card'>
                                                        <CardBody>
                                                            <ul className="list-group list-group-flush">
                                                                <li className="list-group-item pointer" >
                                                                    <Row>
                                                                        <Col sm='12' className='no-padd-lr'>
                                                                            <span>No record found</span>
                                                                        </Col>
                                                                    </Row>
                                                                </li>
                                                            </ul>
                                                        </CardBody>
                                                    </Card>
                                                </div>
                                            }
                                        </div>

                                        <div className="col-sm-6">Machine Number
                                            <Field name="machineNum" id='machineNum' component={renderTextField} className='form-control' type="text" onChange={this.getEquipmentList} />
                                            {keyword && searchItems && keyword.length > 0 && searchItems.length > 0 && fieldType === 'machineNum' &&
                                                <div className='search-area'>
                                                    <Card className='search-card'>
                                                        <CardBody style={{ overflowY: "auto", maxHeight: "200px" }}>
                                                            <ul className="list-group list-group-flush">
                                                                {searchItems.map((item, i) => {
                                                                    return <Item key={i} attr={{ item }} goToItem={this.goToItem} fieldType={'machineNum'} />;
                                                                })}
                                                            </ul>
                                                        </CardBody>
                                                    </Card>
                                                </div>
                                            }
                                            {keyword && searchItems && keyword.length > 0 && searchItems.length == 0 && fieldType === 'machineNum' &&
                                                <div className='search-area'>
                                                    <Card className='search-card'>
                                                        <CardBody>
                                                            <ul className="list-group list-group-flush">
                                                                <li className="list-group-item pointer" >
                                                                    <Row>
                                                                        <Col sm='12' className='no-padd-lr'>
                                                                            <span>No record found</span>
                                                                        </Col>
                                                                    </Row>
                                                                </li>
                                                            </ul>
                                                        </CardBody>
                                                    </Card>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <button type="submit" className="btn btn-primary srch-btn" /* disabled={submitting} */>Search</button>
                                </div>
                            </div>
                        </Col>

                    </Row>

                </form>
                <div style={{ margin: '12px' }}>
                    <Col className='machine-button' md='12'>
                        <Col md='2' className='float-right'>
                            <button className="btn btn-primary srch-btn" style={{ marginTop: '-8px' }}><Link to={`/service/hourmeter-offset-detail?showSearch=true`} className='btn-register' style={{color:'white'}}>ADD OFFSET</Link></button>
                        </Col>
                    </Col>
                </div>

                {<HourmeterOffsetManagementDetailComponent activePage={activePage} data={this.state.data} parentCallback={this.handleCallback} />}

            </Fragment>


        );
    }
}
const Item = props => {
    let { attr, goToItem, fieldType } = props;
    var listOption = attr.item[fieldType];
    return (
        <li className="list-group-item pointer" onClick={() => goToItem(listOption, fieldType)} >
            <Row>
                <Col sm='12' className='no-padd-lr'>
                    <span>{listOption}</span> &nbsp;
                </Col>
            </Row>
        </li>
    );
}

function mapStateToProps(state) {
    return;
}

let formComponent = reduxForm({ form: "SearchForm" })(HourmeterOffsetManagementComponent);
let mapDispatchToProps = { setLoader, setToastr };

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(formComponent));