import React, { Component } from 'react';
import { connect } from "react-redux";
import { FaAngleDown } from 'react-icons/fa'
import { UncontrolledDropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import { bellToggle } from "../../../actions/Application";
import session from "../../../services/session";
/* A Equipment Notification component */
class EquipmentNotificationComponent extends Component {
    constructor(props) {
        super(props)

        this.handleChange = this.handleChange.bind(this);
    }
    

    handleChange(type) {
        session.set("bell", type, "cookie");
        this.props.bellToggle(type);
    }

    
    render() {
        const { showBell } = this.props;
        return (
            <div className="notificationcomponent float-right">
                <UncontrolledDropdown>
                    <div>
                        <DropdownToggle tag="button" className="btn btn-primary notibtntype"> <FaAngleDown/>{ 'Notification Settings' } </DropdownToggle>
                    </div>
                    <DropdownMenu className="maintainanceCover" style={{width: '18rem','fontSize': '0.9rem'}} >
                        <span className="up-caret"></span>
                        <div className="opcover">
                            <div className="inputf"><input type="radio" name="radio1" value={true}   onClick={ () => this.handleChange(true) } defaultChecked={showBell} />  Show Alerts</div>
                            <div className="inputfd">You will receive alerts on screen</div>
                        </div>
                        <div className="opcover">
                            <div className="inputf"><input type="radio" name="radio1" value={false} onClick={ () => this.handleChange(false) } defaultChecked={!showBell} /> Disable Alerts</div>
                            <div className="inputfd">You will not receive alerts on screen</div>
                        </div>
                    </DropdownMenu>
                </UncontrolledDropdown>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return({
        showBell: state.app.showBell || false
    });
}

const mapDispatchToProps = { bellToggle }

export default connect(mapStateToProps, mapDispatchToProps)(EquipmentNotificationComponent);