import { GET_ALERT_DATA, DISPLAY_ALERT_DATA, SET_SAR_OBJ } from '../../types';

/** @type { Object } default state */
let defaultState = {
    alertData: undefined,
    displayAlertSettingData: undefined,
    displayAlertSarData: undefined
}

/**
 * dispatched the user data to the store
 * @param  { Object } state  default state
 * @param  { Object } action dispacthed action from middleware
 */
export default function alertSetting(state = defaultState, action = {}) {
    switch (action.type) {
        case GET_ALERT_DATA:
            return { ...state, alertData: action.data };

        case DISPLAY_ALERT_DATA:
            return { ...state, displayAlertSettingData: action.data };
        
        case SET_SAR_OBJ:
            return { ...state, displayAlertSarData: action.data };
        
        default: return state;
    }
}
