import { URL, ADMINURL, SVPRURL } from '../constants/Constants';
import http from "../services/http";
import { LIMIT } from "../constants/Constants";
import CryptoJS from 'crypto-js';

const secretKey ="ssjagdgajsgdjagsdgaksgdkagsdkmwenqwiiebbcmbhSGisunvbdnmashhhhhhhhhhh";

const setKey = (key) => { 
	const sha1 = CryptoJS.SHA1(CryptoJS.enc.Utf8.parse(key)); 
	const truncatedKey = CryptoJS.enc.Hex.parse(sha1.toString().substring(0, 32)); 
	return truncatedKey; 
}; 
// Function to encrypt data using AES
const encryptData = (plainText) => { 
	const secret = setKey(secretKey); 
	const encrypted = CryptoJS.AES.encrypt((plainText), secret, { 
		mode: CryptoJS.mode.ECB, 
		padding: CryptoJS.pad.Pkcs7, }).toString(); 
		return encrypted; 
};

export default {
	equipmentData: {
		latestEquipment: data => {
			return http.Request(
				"GET", `${URL}Equipment/getEquipmentsDetail`
			).then(response => response)
		},
		updateEquipment: data => {
			return http.Request(
				"POST", `${URL}Equipment/updateEquipmentsDetail`, data, false
			).then(response => response)
		},
		getEquipmentById: data => {
			return http.Request(
				"GET", `${URL}Equipment/getEquipmentsDetail/${data.id}`
			).then(response => response)
		},
		getEquipmentAlertSettings: data => {
			return http.Request(
				"GET", `${URL}Equipment/getAlertSetting/${data.value}`
			).then(response => response)
		}
	},
    
	user: {
		login: (data) => {
			const payload = {
				userId: data.username,
				password: data.password
			};
			const username = encryptData(payload.userId);
			const password = encryptData(payload.password)

			return http.Request(
				"POST", `${URL}customer/login`, { username,password }, false
			).then(response => response)
	},
		
		getProfile: data => {
			return http.Request(
				"GET", `${URL}User/getProfile`
			).then(response => response)
		},
		forgotPassword: data => {
			return http.Request(
				"GET", `${URL}User/ForgetPassword/createToken/${data.loginId}?email=${data.email}&mobileNum=${data.mobileNum}`
			).then(response => response)
		}
    },
	reportsData: {
		latest: data => {
			return http.Request(
				"POST", `${URL}Equipment/getEquipmentUtilReport`, data
			).then(response => response)
		},
		workReport: data => {
			return http.Request(
				"POST", `${URL}Equipment/getEquipmentWorkReport`, data
			).then(response => response)
		}
	},
	serviceHistory:{
		getEquipmentsServices: data => {
			return http.Request(
				"GET",`${URL}Equipment/getEquipmentsServices?id=${data.userId}`
			).then(response => response)
		},
		getEquipmentServiceHistory: data => {
			let offset = (data.page && data.page > 1) ? ((data.page - 1) * LIMIT) : 0;
			
			return http.Request(
				"GET",`${URL}Equipment/getEquipmentsHistory/${offset || 0}/${LIMIT}/${true}?machineNum=${data.selectedEquipment.machineNum}&modelId=${data.selectedEquipment.model}`
			).then(response => response)
		}
	},
	alertNotify: {
		/*getAlerts: data => {
			return http.Request(
				"GET", `${URL}AlertNotification/getAlerts`
			).then(response => response)
		},*/
		saveAlertStatus: data => {
			return http.Request(
				"POST", `${URL}AlertNotification/saveAlertStatus/${data.alertId}`
			).then(response => response)
		},
		getSysMentainInfo: data => {
			let url = `${URL}SystemMaintenance/getInfo/${data.startIndex || 0}/${data.numberOfRows || 15}/${data.totalEventsFlag || true}${data.sortingParams || ''}${data.smFilterParam || ''}`;

			if(data.data && data.data.module){
				url = `${URL}SystemMaintenance/getInfo/${data.startIndex || 0}/${data.numberOfRows || 15}/${data.totalEventsFlag || true}${data.sortingParams || ''}${data.smFilterParam || ''}?module=${(data.data && data.data.module ? data.data.module : "")}`;
			}

			return http.Request(
				"GET", url, null, false
			).then(response => response)
		},
		/*getScheduleSysAlert: data => {
			return http.Request(
				"GET", `${URL}ScheduleServiceAlert/getAlerts`
			).then(response => response)
		},*/
		saveScheduleSysAlertStatus: data => {
			return http.Request(
				"POST", `${URL}ScheduleServiceAlert/updateAlertStatus/${data.serviceAlertId}`
			).then(response => response)
		},
	},
	admin: {
		equipmentData: {
			alertList: data => {
				return http.Request(
					"POST", `${ADMINURL}getEquipmentEvents/${data.startIndex || 0}/${data.numberOfRows || 15}/${data.totalEventsFlag || true}${data.sortingParams || ''}`, data.data || undefined, false, true
				).then(response => response)
			},
			supportInfo: data => {
				return http.Request(
					"GET", `${ADMINURL}getEventSupportInfo/${data.eventId}`, undefined, false, true
				).then(response => response)
			},
			getRecentEvents: data => {
				return http.Request(
					"GET", `${ADMINURL}getRecentEvents`, undefined, false, true
				).then(response => response)
			},
			getSPRecentEvents: data => {
				return http.Request(
					"GET", `${SVPRURL}getSpEvent`, undefined, false, true
				).then(response => response)
			}
		},
		scheduleService: {
			scheduleServiceAlert: data => {
				return http.Request(
					"GET", `${ADMINURL}getEquipmentScheduleServiceAlert`, undefined, false, true
				).then(response => response)
			},
			update: data => {
				return http.Request(
					"POST", `${ADMINURL}updateEquipmentScheduleServiceAlert`, data, false, true
				).then(response => response)
			}
		},
		sysMaintenance: {
			update: data =>{
				return http.Request(
					"POST", `${ADMINURL}SystemMaintenance/update`, data, false, true
				).then(response => response)
			},
			add: data =>{
				return http.Request(
					"POST", `${ADMINURL}SystemMaintenance/add`, data, false, true
				).then(response => response)
			}
		},
		user: {
			login: data => {
				return http.Request(
					"POST", `${ADMINURL}login`, data, false, true
				).then(response => response)
			}
		}
	},
	serviceProvider: {
		user: {
			login: data => {
				return http.Request(
					"POST", `${SVPRURL}login`, data, false
				).then(response => response)
			}
		},
		equipment:{
			getEquipmentEvents: data => {
				return http.Request(
					"POST", `${SVPRURL}getEquipmentEvents/${data.startIndex || 0}/${data.numberOfRows || 15}/${data.totalEventsFlag || true}${data.sortingParams || ''}`, data.data || undefined, false, true
				).then(response => response)
			},
			supportInfo : data => {
				return http.Request(
					"GET", `${SVPRURL}getEventSupportInfo/${data.eventId}`, undefined, false
				).then(response => response)
			},
			updateSupportInfo : data => {
				return http.Request(
					"POST", `${SVPRURL}saveEquipmentEventSupportInfo`, data, false
				).then(response => response)
			},
			updateServiceInfo : data => {
				return http.Request(
					"POST", `${SVPRURL}saveEquipmentService`, data, false
				).then(response => response)
			}
		}
	}
}