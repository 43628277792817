import React, { Component } from 'react';
import EquipmentNotificationComponent  from './EquipmentNotificationsComponent';
import EquipmentSearchComponent  from './EquipmentSearchComponent';
import { connect } from 'react-redux';
import TopTabingBar  from '../../../components/service-provider/TopTabingBar';
import { setLoader } from '../../../actions/Application';
import DataBoxComponent from './DataBoxComponent';
import DataSheet from './datasheet/DataSheet';

/* A Equipment Alert component */
class EquipmentAlertsComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            alertData: [],
            alertIcon : 0,
            filter:null,
            search: false,
            paginate: false
        };

    }
    componentWillMount() {
        this.setState({
            alertData : this.props.equipmentAlertData
        })
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.equipmentAlertData && nextProps.equipmentAlertData.SET_EQUIPMENT_ALERT_LIST_DATA && nextProps.equipmentAlertData.SET_EQUIPMENT_ALERT_LIST_DATA.eventList) {
            this.props.setLoader({loader:{open:nextProps.equipmentAlertData.isSending}});
           this.setState({
                alertData : nextProps.equipmentAlertData
            })
        }
    }

    handleChange(type){
        localStorage.setItem('alertIcon', type);
    }

    handlePageChange(data){
        this.props.handlePageChange({
            startIndex : data.page,
            totalEventsFlag : data.totalEventsFlag,
            data : this.state.filter,
            sortingParams : (data.sortingParams === undefined)? null : data.sortingParams
        });
        if(data.totalEventsFlag === "false"){
            this.setState({ search: false, paginate : true });
        }
    }

    searchData(data){
        if(data.status === undefined && data.startDate === undefined && data.endDate === undefined)
        {
            data = undefined;            
        }
        this.setState({filter : data, search: true, paginate : false})
        this.props.handlePageChange({data : data, totalEventsFlag : 'true'})
    }

    render() {
        const { translate, isService } = this.props;
        return (
            <div className="eqalertcontainer">
                <TopTabingBar active = {1}/> 
                <div className="float-right mb-4">
                    <EquipmentSearchComponent  searchData = {this.searchData.bind(this) } translate = { translate }/>
                    <EquipmentNotificationComponent translate = { translate } handleChange = {this.handleChange.bind(this)} /> 
                </div>
                <div className="clearfix"/>
                <DataBoxComponent translate = { translate } paginate = { this.state.paginate } search = {this.state.search} isService={isService} equipment = {this.state.alertData.SET_EQUIPMENT_ALERT_LIST_DATA} />
                <DataSheet 
                    isService={isService} 
                    handleStatusChange={this.props.handleStatusChange} 
                    filter = {this.state.filter} 
                    apiError = { this.props.apiError } 
                    search = { this.state.search }
                    paginate = { this.state.paginate }
                    equipment = { this.state.alertData.SET_EQUIPMENT_ALERT_LIST_DATA } 
                    handlePageChange = {this.handlePageChange.bind(this)} 
                    translate = { translate }
                />
            </div>
        );
    }
}


const mapDispatchToProps = { setLoader }
export default connect(null, mapDispatchToProps) (EquipmentAlertsComponent)
