import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Moment from "moment";

const count = 24;
const convertHrs = (time) => {
    if (time) {
        let m1 = time.split(":");
        let h = +m1[0], m = +m1[1], s = +m1[2];
        let d = h + (m / 60) + (s / 3600);
        return +(d);
    }
}

const generateTimeInterval = (count) => {
    let interval = [];
    for (let i = 0; i <= count; i++) {
        interval.push(i);
    };
    return interval;
}

const calcLeftMargin = (startTime) => {
    let margin = convertHrs(startTime);
    let m1 = (margin / 24) * 100.00;
    return m1;
}

const getEventTime = (eventTimeStamp, timeZone) => Moment(eventTimeStamp).tz(timeZone).format("HH:mm:ss");

class MachineReportComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            routeDetail: null
        };
    }

    componentWillReceiveProps(props) {
        let routeDetail = props.routeDetail;
        let operatingInfo = props.operatingInfo;
        let group = props.group;
        let isNotTractor = group == "CE" || group == "CH"
        if (routeDetail && Array.isArray(routeDetail) && !isNotTractor) {
            routeDetail = routeDetail[0];

            let data = [];
            let key = Object.keys(routeDetail);
            if (key && key.length) {
                key.forEach(e => {
                    data = [...data, ...routeDetail[e]];
                });
            }
            routeDetail = data;

        }

        let timeZone = props.timezone;
        if (routeDetail && routeDetail.length > 0 && !isNotTractor) {
            let data = [];

            let timeInterval;

            routeDetail.forEach((e, i) => {
                if (i === 0) {
                    timeInterval = e.storageInterval;

                } else {
                    let eventTimeStamp = getEventTime(e.eventTimeStamp, timeZone);
                    let drivingStatus = (e.drivingStatus);
                    let colorStatus = (e.color);
                    let framecode = e.frameCode;
                    let prevEventTime;
                    let p;
                    let diffMinute;
                    let currentEventTime;
                    let prev = routeDetail[i + 1];
                    if (prev && prev.eventTimeStamp) {
                        prevEventTime = convertHrs(getEventTime(prev.eventTimeStamp, timeZone));
                        currentEventTime = convertHrs(getEventTime(e.eventTimeStamp, timeZone));
                    }

                    if (timeInterval > 60) {
                        p = (100 / 24) / (60.0 * 60.0);
                        diffMinute = (prevEventTime - currentEventTime) * 60 * 60;
                    } else {
                        p = (100 / 24) / (60);
                        diffMinute = (prevEventTime - currentEventTime) * 60;
                    }



                    let width = diffMinute * p;


                    let obj = {
                        timeInterval: (eventTimeStamp),
                        leftMargin: calcLeftMargin(eventTimeStamp),
                        drivingStatus: (drivingStatus),
                        color: (colorStatus),
                        width: width
                    };
                    if (drivingStatus) {
                        data.push(obj);
                    }

                }

            });
            this.setState({ routeDetail: data });
        }
        if (operatingInfo && operatingInfo.length > 0 && isNotTractor) {
            let data = [];

            let timeInterval;

            operatingInfo.forEach((e, i) => {




                let eventTimeStamp = getEventTime(e.bootStartDate, timeZone);

                let colorStatus = ("blue");


                let bootStartDate;
                let bootStopDate;
                let p;
                let diffMinute;




                bootStartDate = convertHrs(getEventTime(e.bootStartDate, timeZone));
                bootStopDate = convertHrs(getEventTime(e.bootStopDate, timeZone));
                p = (100 / 24) / (60.0*60 );
                diffMinute = (bootStopDate - bootStartDate) * 60*60 ;



                let width = diffMinute * p;


                let obj = {
                    timeInterval: (eventTimeStamp),
                    leftMargin: calcLeftMargin(eventTimeStamp),

                    color: (colorStatus),
                    width: width
                };

                data.push(obj);



            });
            this.setState({ routeDetail: data });
        }
    }

    render() {
        let { routeDetail } = this.state;
        let { operatingInfo, translate ,operatingInformation,isService} = this.props;

        if (isService){
            operatingInfo =operatingInformation;
        }

        if (!operatingInfo || (operatingInfo && Object.keys(operatingInfo).length === 0)) {
            return false;
        }

        let interval = generateTimeInterval(count);
        return (
            <div>
                <div className="Machine-Chart-Box ">
                    <div className="border-map-box">
                        {/* Map chart */}
                        {routeDetail && routeDetail.map((e, i) =>
                            <Fragment key={i} >
                                <div className="d-flex flex-row h-40 map-column-group time-intervel"
                                    style={{ left: e.leftMargin + '%', width: `${e.width}%`, backgroundColor: e.color }} > </div>
                            </Fragment>
                        )}
                    </div>

                    <div className="timer">
                        <div className="timer-row">
                            {/* time interval 25 hrs */}

                            {interval && interval.map((e, i) => (
                                <div className="timer-column" key={i}
                                    style={{ width: (100 / 25) + '%' }}>
                                    {/* Print value only 0 6 12 18 24 */}
                                    {
                                        (e === 0 || e === 6 || e === 12 || e === 18 || e === 18 || e === 24) ?
                                            <>
                                                <span className="text-small line"> &nbsp; </span>
                                                <small className="box-text"> {e <= 9 ? '0' + e + ":00" : e + ':00'} </small>
                                            </>
                                            :
                                            <span className="text-small line">  &nbsp; </span>
                                    }
                                </div>

                            ))}
                        </div>
                    </div>

                </div>

                <div className="d-flex flex-row justify-content-center">
                    <div className="pl-1 pr-1">{translate('TimeInterval')}</div>
                </div>
                
                  { this.props.group =="TE" ?<div className="d-flex flex-row justify-content-center"><div className="pl-2 pr-2 PTH"><span></span><strong>{translate('ptoRunning')}</strong></div>
                    <div className="pl-2 pr-2 ITH"><span></span><strong>{translate('idling')}</strong></div>
                    <div className="pl-2 pr-2 RTH"><span></span><strong>{translate('running')}</strong></div>
                    <div className="pl-2 pr-2 HSH"><span></span><strong>{translate('highSpeedRunning')}</strong></div></div>:
                    <div className=" d-flex flex-row justify-content-center pl-2 pr-2 OTH"><span></span><strong>{translate('operatingHourChart')}</strong></div>}
   
            </div>
        );
    }
};


const mapStateToProps = state => {

    return ({
        operatingInfo: state.dailywork.operatingInfo || {},
        routeDetail: state.dailywork.routeDetail || {},
        timezone: state.profile.timezone || "Asia/Kolkata",
        operatingInformation: state.dailywork.equipOperatingInfo && state.dailywork.equipOperatingInfo.operatingInfoResponse || {},
        group: state.dailywork.status && state.dailywork.status.toUpperCase() || {}
    });
};

export default connect(mapStateToProps, null)(MachineReportComponent);