import React, { Component, Fragment } from "react";
import ReactDOM from "react-dom";
import { Field, reduxForm, change } from "redux-form";
import ButtonField from '../../shared/ButtonField';
import { required } from "../../shared/Validation";
import { renderTextField } from "../../shared/renderField";
import { Row, Col, CustomInput, FormGroup, Card, CardBody } from "reactstrap";
import { setLoader, setToastr } from "../../../actions/Application";
import { connect } from "react-redux";
import active from '../../../assets/images/map/Active.png';
import offline from '../../../assets/images/map/Offline.png';
import activeError from '../../../assets/images/map/ActiveError.png';
import offlineError from '../../../assets/images/map/OfflineError.png';
import { translate } from '../../../lang';
import MapComponent from '../../shared/MapComponent';
import { getBoundData } from '../../../actions/LocationInformation';
import { isEmpty } from "lodash";
/*global google*/

/* A Equipment Location Info component */
class EquipmentLocationInfoComponent extends Component {

    constructor(props) {
        super(props);
        this.getEquipmentList = this.getEquipmentList.bind(this);

        this.searchBy = this.searchBy.bind(this);
        this.goToItem = this.goToItem.bind(this);
        this.child = React.createRef();
        this.state = {
            searchBy: 'model',
            numberPlateOption: [],
            optionType: 0,
            getServiceData: false,
            data: null,
            vertices: null,
            center: { lat: 23.63936, lng: 68.14712 },
            markers: [],
            bound: null,
            machineInfo: null,
            searchItems: [],
            mapTypeId: 'roadmap',
            keyword: "",
            fieldType: "",
            tractorDetail: [],
            showHideDropDown: 'hidden',
            equipment: {},
            isMarkerShown: false,
            lat: 0,
            lon: 0,
            routeDetail: [],
            performanceParams: {},
            group: '',
            selectedModel: '',
            selectedMachine: '',
            selectedNumber: ''
        }

        //this.getEquipmentDetails = this.getEquipmentDetails.bind(this);
    }


    searchBy(value) {
        this.setState({ searchBy: value })
        if (value == 'model') {
            this.setState({ selectedNumber: '' });
        }
        else if (value == 'numberPlate') {
            this.setState({ selectedModel: '' ,selectedMachine:''});
        }
        this.props.dispatch(change('SearchForm', 'numberPlate', ''));
        this.props.dispatch(change('SearchForm', 'model', ''));
        this.props.dispatch(change('SearchForm', 'machineNum', ''));
    }



    viewLocation(data, init) {
        this.props.setLoader({ loader: { open: true } });
        if (!init && this.state.searchBy === 'model' && (this.state.selectedModel == '' && this.state.selectedMachine == '')) {
            return;
        }
        if (!init && this.state.searchBy === 'numberPlate' && this.state.selectedNumber == '') {
            return;
        }
        var selectedEquipment = [];
        if (!init) {
            let equipmentData = this.props.equipmentList;

            equipmentData.filter(item => {
                if ((item.model == this.state.selectedModel && item.machineNum == this.state.selectedMachine) || 
                (item.model == this.state.selectedModel && this.state.selectedMachine == '' ) || 
                (item.machineNum == this.state.selectedMachine && this.state.selectedModel == '' ) ||
                (item.numberPlate == this.state.selectedNumber)) {
                    selectedEquipment.push(item);

                }
            })
        }
        if ((init && data && data.length > 0) || (!init && selectedEquipment.length > 0)) {

            selectedEquipment = init ? data : selectedEquipment;
            let mapData = this.getMarkerPosition(selectedEquipment);
            if(!init && (selectedEquipment[0].latitude==null || selectedEquipment[0].longitude==null)){
                this.props.setToastr({ toastr: { type: "error", message:  "Machine Location is not Available" } }); 
                this.props.setLoader({ loader: { open: false } });   
                return;
            }
            this.setState({
                tractorDetail: selectedEquipment,
                markers: mapData.markers,
               // bound: mapData.bound,
                showHideDropDown: "hidden"
            })
            setTimeout(() => {
                this.manipulateState();
            }, 0);

        }else if (!init) {
            this.props.setToastr({ toastr: { type: "error", message:  "Please Enter Correct Details" } });
            this.props.setLoader({ loader: { open: false } });
        }
        this.props.setLoader({ loader: { open: false } });
        
    }

    getMarkerPosition(data) {
        let markers = [];
        data.forEach((val, index) => {
            var result = val;
            var eqType = result.equipmentType;
            var eqStatus= result.status;
            let icon = '';
            if (result.errorFlag && result.runningStatus === "Offline") {
                icon = 'offlineError'+eqStatus;
            } else if (result.errorFlag && result.runningStatus === "Active") {
                icon = 'activeError'+eqStatus;
            } else if (!result.errorFlag && result.runningStatus === "Offline") {
                icon = 'offline' + eqStatus;
            } else if (eqType != "tractor" && !result.errorFlag && result.runningStatus === "Active") {
                icon = 'active' + eqStatus;
            } else if (eqType = "tractor" && !result.errorFlag && result.runningStatus === "Active") {
                icon = 'active' + eqStatus;
            }
            else if (!result.errorFlag && result.runningStatus === null) {
                icon = 'offline'+eqStatus;
            } else if (result.errorFlag && result.runningStatus === null) {
                icon = 'offline'+eqStatus;
            }

            markers.push({
                'name': result.id,
                'icon': icon,
                'machineSaleId': result.machineSaleId,
                'runningStatus': result.runningStatus,
                'terminalId': result.terminalId,
                'lat': parseFloat(result.latitude), 'lng': parseFloat(result.longitude),
                'address': result.address,
                'logo': result.logo,
                'model': result.model,
                'machineNum': result.machineNum,
                'equipmentType': (result && result.equipmentType) ? result.equipmentType.toUpperCase() : " ",
                'numberPlate': result.status == 'TE' ? result.numberPlate : "",
                'operatingStatus' : result.lastUpdateTime,
                'hourmeter': result.hourmeter

            });


        })

       // var bound = this.mapBound(markers);
        return { markers};
    }

    mapBound(markers, bounds) {
        //location bounds
        bounds = new this.props.google.maps.LatLngBounds();
        if (markers && markers.length) {
            let processed = 0;
            for (var i = 0; i < markers.length; i++) {
                if (markers[i].lat && markers[i].lng && !isNaN(markers[i].lat) && !isNaN(markers[i].lng)) {
                    var mapLatLng = this.props.google.maps.LatLng(markers[i].lat, markers[i].lng)
                    bounds.extend(mapLatLng);
                    processed += 1;
                }
            }

            if (processed === 0) {
                let mapLatLngj = new google.maps.LatLng(23.63936, 68.14712);
                let mapLatLngj2 = new google.maps.LatLng(28.20453, 97.34466);

                bounds.extend(mapLatLngj);
                bounds.extend(mapLatLngj2);
            }
        } else {

            let mapLatLngj = new google.maps.LatLng(23.63936, 68.14712);
            let mapLatLngj2 = new google.maps.LatLng(28.20453, 97.34466);

            bounds.extend(mapLatLngj);
            bounds.extend(mapLatLngj2);
        }

        return bounds;
    }

    manipulateState() {
        this.setState({ showHideDropDown: "hidden" });
    }

    render() {
        let { fieldType, searchItems, keyword} = this.state;
       //var bound =  this.mapBound(markers);
        return (
            <section className="c-location-information">
                <section className="conatiner">
                    <Fragment>

                        <form className="equipmentInfo" name="SearchForm" id="SearchForm" autoComplete='off' >

                            <Row className="members align-items-center form-srch">
                                <Col sm="12">
                                    <FormGroup>
                                        <Row>
                                            <Col md="2" sm="3"><span className="highlighted">Select Equipment By</span></Col>
                                            <Col md="10" sm="9" className='d-flex justify-content-start'>
                                                <CustomInput type="radio" id="by_model" name="sby" value="model" label="By Model/Machine Number" data-label="Model/Machine Number" onClick={() => this.searchBy('model')} defaultChecked={this.state.searchBy === 'model' ? true : false} />&nbsp;

                                                <CustomInput type="radio" id="by_number_plate" name="sby" value="numberPlate" label="By Number Plate" data-label="Number Plate" onClick={() => this.searchBy('numberPlate')} defaultChecked={this.state.searchBy === 'numberPlate' ? true : false} />


                                            </Col>
                                        </Row>
                                    </FormGroup>
                                </Col>
                                <Col sm="12">

                                    <div className="row profile-asset">
                                        {this.state.searchBy === 'model' && <div className="col-sm-6">
                                            <div className="row">
                                                <div className="col-sm-6">Model
                                                    <Field name="model" id="model" component={renderTextField} className='form-control' type="text" validate={[required]} onChange={this.getEquipmentList} />
                                                    {keyword.length > 0 && searchItems.length > 0 && fieldType === 'model' &&
                                                        <div className='search-area'>

                                                            <Card className='search-card'>
                                                                <CardBody style={{overflowY:"auto" , maxHeight:"400px"}}>
                                                                    <ul className="list-group list-group-flush">
                                                                        {searchItems.map((item, i) => {
                                                                            return <Item key={i} attr={{ item }} goToItem={this.goToItem} fieldType={'model'} />;
                                                                        })}
                                                                    </ul>
                                                                </CardBody>
                                                            </Card>
                                                        </div>}
                                                    {keyword.length > 0 && searchItems.length == 0 && fieldType === 'model' &&
                                                        <div className='search-area'>
                                                            <Card className='search-card'>
                                                                <CardBody>
                                                                    <ul className="list-group list-group-flush">
                                                                        <li className="list-group-item pointer" >
                                                                            <Row>
                                                                                <Col sm='12' className='no-padd-lr'>
                                                                                    <span>No record found</span>
                                                                                </Col>
                                                                            </Row>
                                                                        </li>
                                                                    </ul>
                                                                </CardBody>
                                                            </Card>
                                                        </div>
                                                    }
                                                </div>

                                                <div className="col-sm-6">Machine Number
                                                    <Field name="machineNum" id='machineNum' component={renderTextField} className='form-control' type="text" validate={[required]} onChange={this.getEquipmentList} />
                                                    {keyword.length > 0 && searchItems.length > 0 && fieldType === 'machineNum' &&
                                                        <div className='search-area'>

                                                            <Card className='search-card'>
                                                                <CardBody style={{overflowY:"auto" , maxHeight:"400px"}}>
                                                                    <ul className="list-group list-group-flush">
                                                                        {searchItems.map((item, i) => {
                                                                            return <Item key={i} attr={{ item }} goToItem={this.goToItem} fieldType={'machineNum'} />;
                                                                        })}
                                                                    </ul>
                                                                </CardBody>
                                                            </Card>
                                                        </div>}
                                                    {keyword.length > 0 && searchItems.length == 0 && fieldType === 'machineNum' &&
                                                        <div className='search-area'>
                                                            <Card className='search-card'>
                                                                <CardBody>
                                                                    <ul className="list-group list-group-flush">
                                                                        <li className="list-group-item pointer" >
                                                                            <Row>
                                                                                <Col sm='12' className='no-padd-lr'>
                                                                                    <span>No record found</span>
                                                                                </Col>
                                                                            </Row>
                                                                        </li>
                                                                    </ul>
                                                                </CardBody>
                                                            </Card>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>}
                                        {this.state.searchBy === 'numberPlate' && <div className="col-sm-3">
                                            <div >Number Plate <span className='required'>*</span>
                                                <Field name="numberPlate" id='numberPlate' component={renderTextField} className='form-control' type="text" validate={[required]} onChange={this.getEquipmentList} />
                                                {keyword.length > 0 && searchItems.length > 0 && fieldType === 'numberPlate' &&
                                                    <div className='search-area'>
                                                        <Card className='search-card'>
                                                            <CardBody style={{overflowY:"auto" , maxHeight:"400px"}}>
                                                                <ul className="list-group list-group-flush">
                                                                    {searchItems.map((item, i) => {
                                                                        return <Item key={i} attr={{ item }} goToItem={this.goToItem} fieldType={'numberPlate'} />;
                                                                    })}
                                                                </ul>
                                                            </CardBody>
                                                        </Card>
                                                    </div>}
                                                {keyword.length > 0 && searchItems.length == 0 && fieldType === 'numberPlate' &&
                                                    <div className='search-area'>
                                                        <Card className='search-card'>
                                                            <CardBody>
                                                                <ul className="list-group list-group-flush">
                                                                    <li className="list-group-item pointer" >
                                                                        <Row>
                                                                            <Col sm='12' className='no-padd-lr'>
                                                                                <span>No record found</span>
                                                                            </Col>
                                                                        </Row>
                                                                    </li>
                                                                </ul>
                                                            </CardBody>
                                                        </Card>
                                                    </div>
                                                }
                                            </div>
                                        </div>}

                                        <div className="col-sm-4" style={{ paddingTop: "20px" }}>
                                            <ButtonField
                                                type="button"
                                                name="viewLocation"
                                                id="viewLocation"
                                                className={(this.state.selectedModel || this.state.selectedMachine) || this.state.selectedNumber ? 'btn-primary btn-view-location' : 'btn-danger btn-view-location disabled'}
                                                buttonText={translate('viewLocation')}
                                                clickEvent={true}
                                                handleClick={this.viewLocation.bind(this)}
                                            />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            {this.state.markers.length>0 && <Row className='mapArea'>
                                <Col>
                                    <MapComponent
                                        t="spLocation"
                                        mapTypeId={this.state.mapTypeId}
                                        manipulateState={this.manipulateState.bind(this)}
                                        ref={this.child}
                                       //bound={bound}
                                        markers={this.state.markers}
                                        drawingMode={this.state.drawingMode}
                                        center={this.state.center}
                                        drawingControl={this.state.drawingControl}
                                        coords={this.state.geofenceCordinate}
                                    />
                                </Col>
                            </Row>}
                            <Row>
                                <Col className="tractor-status">
                                    <ul style={{ display: "flex", paddingTop: "15px" }} >
                                        <li><img src={active} /><span>{translate('activeTractor')}</span></li>
                                        <li ><img src={offline} /><span>{translate('offlineTractor')}</span></li>
                                        <li ><img src={activeError} /><span>{translate('activeErrorTractor')}</span></li>
                                        <li /* style={{ paddingRight: "15px" }} */><img src={offlineError} /><span>{translate('offlineErrorTractor')}</span></li>
                                    </ul>
                                </Col>
                            </Row>

                        </form>
                    </Fragment>
                </section>
            </section >

        );
    }
    getEquipmentList(e) {
        let fieldType = e.target.id
        let models = [], machines = [], numberPlates = [];
        let { value } = ReactDOM.findDOMNode(document.getElementById(e.target.id)), { equipmentList } = this.props, regex = new RegExp(value.trim(), 'i');
        if(isEmpty(value)){
            if(fieldType ==='model'){
                this.setState({ selectedModel: '' });
            }else if(fieldType ==='machineNum'){
                this.setState({selectedMachine: ''});
            }else if(fieldType ==='numberPlate'){
                this.setState({selectedNumber: ''});
            }
        }
        let filtered = equipmentList.filter(e => {
            if (e.model != null && !models.includes(e.model.toString()) && fieldType === 'model') {
                if (regex.test(e.model.toString())) {
                    models.push(e.model.toString());
                    return e;
                }
            } else if (e.machineNum != null && !machines.includes(e.machineNum.toString()) && fieldType === 'machineNum') {
                if (regex.test(e.machineNum)) {
                    machines.push(e.machineNum.toString())
                    return e;
                }
            } else if (e.numberPlate != null && !numberPlates.includes(e.numberPlate.toString()) && fieldType === 'numberPlate') {
                if (regex.test(e.numberPlate)) {
                    numberPlates.push(e.numberPlate.toString())
                    return e;
                }
            }
        });
        this.setState({ searchItems: filtered, keyword: value, fieldType: fieldType });
    }
    componentWillReceiveProps(nextprops) {
        if (nextprops.equipmentList != this.props.equipmentList) {
            this.viewLocation(nextprops.equipmentList, true);

        }

    }

    componentDidMount() {
        let data = this.props.equipmentList;
        this.viewLocation(data, true);
    }

    goToItem(attr, fieldType) {
        //setting value
        if (fieldType == 'model') {
            this.setState({ selectedModel: attr });
        }
        else if (fieldType == 'machineNum') {
            this.setState({ selectedMachine: attr });
        }
        else if (fieldType == 'numberPlate') {
            this.setState({ selectedNumber: attr });
        }
        this.props.dispatch(change('SearchForm', fieldType, attr));
        this.setState({ keyword: "", searchItems: [], fieldType: "" });

    }

}
const Item = props => {
    let { attr, goToItem, fieldType } = props;
    var listOption = attr.item[fieldType];
    return (
        <li className="list-group-item pointer" onClick={() => goToItem(listOption, fieldType)} >
            <Row>
                <Col sm='12' className='no-padd-lr'>
                    <span>{listOption}</span> &nbsp;
                </Col>
            </Row>
        </li>
    );
}
function mapStateToProps(state) {

    return { bound: state.locationInformation.boundData || undefined }
}
let formComponent = reduxForm({ form: "SearchForm" })(EquipmentLocationInfoComponent);

const mapDispatchToProps = { getBoundData, setToastr, setLoader };
export default connect(mapStateToProps, mapDispatchToProps)(formComponent)
