import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Moment from 'react-moment';
import { getRegisteredMaps } from "../../../api/admin/custom-map";
import { setLoader } from "../../../actions/Application";
/** importing sub-components */
import Pagination from "../../shared/Pagination";
/** importnig constants */
import { LIMIT } from "../../../constants/Constants";
import SearchFormComponent from "./SearchFormComponent";
import ReactDOM from "react-dom";
import {change,reduxForm} from "redux-form";

let form = "RegisteredMapForm";
class RegisteredMapComponent extends Component{
    constructor(props){
        super(props);

        this.state = {
            maps: [],
            activePage: 1,
            total: 0,
            qs: {},
            searchItems: [],
            keyword: "",
            fieldType: ""
        }
        this.getEquipmentList=this.getEquipmentList.bind(this);
    }

    getRegisteredMapData = (req) => {
        this.props.setLoader({ loader: {open:true}});

        getRegisteredMaps(req)
        .then(result => {
            this.props.setLoader({ loader: {open:false}});

            this.setState({
                maps: (result.data && result.data.hsitoryMapListObj) || [],
                activePage: this.state.activePage || 1,
                total: (result.data.countResult && result.data.countResult.totalCounts) ? result.data.countResult.totalCounts : this.state.total 
            });
        })
        .catch(err => this.props.setLoader({ loader: {open:false}}));
    }

    onPageChange = (page) => {
        this.setState({ activePage: page || 1 }, () => {
            this.getRegisteredMapData({ page });
        });
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.initializeTab != this.props.initializeTab) {
            this.onPageChange(1);
        }
    }

    onSearch = (qs) => {
        if (!Object.keys(qs).length) return false;

        this.props.setLoader({ loader: { open: true } });
        this.setState({ qs });

        this.getRegisteredMapData({ page: 1, qs });
    }

    clearSearch = () => {
        this.setState({ qs: {} });
        this.getRegisteredMapData({ page: 1 });
    }

    getEquipmentList(e){
            let fieldType = e.target.id
            let { value } = ReactDOM.findDOMNode(document.getElementById(e.target.id)), regex = new RegExp(value.trim(), 'i');
            let filtered = [],models=[], machines=[];
            let filteredMaps = this.state.maps;
            if (fieldType && fieldType.includes('search')) {
                filtered = filteredMaps.filter(e => {
                    if (!models.includes(e.model.toString()) && fieldType === 'searchmodel') {
                        if (regex.test(e.model.toString())) {
                            models.push(e.model.toString());
                            return e;
                        }
                    } else if (!machines.includes(e.machineNum.toString()) && fieldType === 'searchmachineNum') {
                        if (regex.test(e.machineNum.toString())) {
                            machines.push(e.machineNum.toString())
                            return e;
                        }
                    }
                });
            }
            
            this.setState({ searchItems: filtered, keyword: value, fieldType: fieldType });
        }

    goToItem = (attr, fieldType) => {
        //setting value
        fieldType = this.state.fieldType;
        if (fieldType && fieldType.includes('search')) {
            fieldType = fieldType.replace('search', '');
            this.props.dispatch(change('SearchCustomMapForm', fieldType, attr));
        }
        this.setState({ keyword: "", searchItems: [], fieldType: "" });
    }


    render(){
        let { maps, activePage, total,keyword,searchItems,fieldType } = this.state;
        return(
            <Fragment>
                <form name={form} id={form} autoComplete='off'>
                <SearchFormComponent onSearch={this.onSearch} qs={this.state.qs} clearSearch={this.clearSearch} getEquipmentList={this.getEquipmentList} searchItems={searchItems} goToItem={this.goToItem} keyword={keyword} fieldType={fieldType}/>
                {maps && <table className="tabledashboard table table-striped table-advance table-hover m-top-30">
                    <thead>
                        <tr>
                            <th>Model</th>
                            <th>Machine Number</th>
                            <th>Map1</th>
                            <th>Map2</th>
                            <th>Sent Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {maps.length>0 && maps.map((item, i) => (
                            <tr key={item.model+i}>
                                <td>{item.model || "NA"}</td>
                                <td>{item.machineNum || "NA"}</td>
                                <td>{item.map1 || "NA"}</td>
                                <td>{item.map2 || "NA"}</td>
                                <td><Moment format="DD MMM, YYYY hh:mm A">{item.mapSentDate}</Moment></td>
                            </tr>
                        ))}

                        {!maps.length && <tr><td align="center" colSpan="6">No Data Found</td></tr>}
                        
                    </tbody>
                </table>}

                <Pagination 
                    limit={LIMIT}
                    total={total}
                    activePage={activePage}
                    onChange={this.onPageChange}
                    data={maps}
                />
                </form>
            </Fragment>
        );
    }
}
let formComponent = reduxForm({ form })(RegisteredMapComponent);
export default connect(null, {setLoader})(formComponent);