import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import session from "../../services/session";

/* A Top Tabing Bar component */
class TopTabingBar extends Component {
    constructor(props) {
        super(props)
        this.state = {
            active: '',
        };

    }
    componentWillMount() {
        this.setState({
            active : this.props.active
        })
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.active !== nextProps.active) {
           this.setState({
                active : nextProps.active
            })
        }
    }

    render() {
        let routeList = (session && session.get("spprev","cookie")) || [], { pathname } = this.props.location;
        return (
            <div>
               
                {routeList.map(route => {
                    if(!route.menu) return false;

                    return (
                        <Link key={route.uuid} to={route.uuid}>
                            <div className={(route.uuid === pathname) ? 'active' : ''}>
                                {route.functionName}
                            </div>
                        </Link>
                    );
                })}
                

                {/* <Link to='/service-provider/equipment-operating-info'>
                    <div className={(this.state.active === 2) ? 'active' : ''}>
                        EQUIPMENTS OPERATING INFO
                    </div>
                </Link>

                <Link to='/service-provider/profile'>
                    <div className={(this.state.active === 3) ? 'active' : ''}>
                        USER DETAILS
                    </div>
                </Link> */}
                <a className="clearfix"></a>
            </div>
        );
    }
}

export default withRouter(TopTabingBar);
