import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Link } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import moment from 'moment';
import ButtonField from '../../../components/shared/ButtonField';
import InputField from '../../../components/shared/InputField';
import SelectField from '../../../components/shared/SelectField';
import DatePicker from 'react-datepicker';
import { FaCalendarAlt } from 'react-icons/fa'
import Loaders from '../../../messages/loaders/Loaders';
import { Alert } from 'reactstrap';
import 'react-datepicker/dist/react-datepicker.css';
import { setToastr } from "../../../actions/Application";

/* A Add System Maintenance component */
class AddSystemMaintenanceComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: {
                "application": "",
                "title": "",
                "message": "",
                "startDate": moment(),
                "endDate": moment()
            },
            notify: false,
            notifyType: 'success',
            notifyText: 'Hey! Its Equipemnt Page',
            showLoader: true,
            updated: false
        };

        this.onBlur = this.onBlur.bind(this);

    }
    componentWillMount() {
        if (this.props.addSysMaintainData) {
            this.setState({ serverResponce: this.props.addSysMaintainData.SET_ADD_MAINTAIN_DATA, showLoader: this.props.addSysMaintainData.isSending });
        }
    }

    componentWillReceiveProps(nextProps) {
        const { setToastr } = this.props;


        if (this.props.addSysMaintainData !== nextProps.addSysMaintainData) {

            this.setState({
                serverResponce: nextProps.addSysMaintainData.SET_ADD_MAINTAIN_DATA,
                showLoader: nextProps.addSysMaintainData.isSending,
                updated: false
            });

            if (nextProps.addSysMaintainData.SET_ADD_MAINTAIN_DATA) {

                if (typeof nextProps.addSysMaintainData.error === 'boolean' && nextProps.addSysMaintainData.error === false) {


                    document.getElementById('title').value = ""
                    document.getElementById('message').value = ""
                    document.getElementById('application').value = "null"

                    setToastr({ toastr: { message: "New Maintenance has been added.", type: "success" } });


                    this.setState({
                        data: {
                            "startDate": moment(),
                            "endDate": moment()
                        }
                        /* notify: true,
                        notifyType: 'success',
                        notifyText: 'New Maintenance Added' */
                    }, () => this.props.history.push('/service/system-maintenance'));

                } else {
                    if (nextProps.addSysMaintainData.resMsg) setToastr({ toastr: { message: nextProps.addSysMaintainData.resMsg, type: "error" } });
                    /* setToastr({ toastr: { message: (typeof nextProps.addSysMaintainData.error === 'boolean' && nextProps.addSysMaintainData.error === false) ? "New Maintenance has been added." : nextProps.addSysMaintainData.resMsg, type: !nextProps.addSysMaintainData.error ? "success" : "error" }}); */
                }
            }
        }
    }

    onBlur(type, data) {
        data = moment(new Date(data.target.value));

        this.setState({ data: { ...this.state.data, [type]: data } });
    }

    handleChange(type, data) {
        if (data === undefined) {
            data = type.target.value;
            type = type.target.name;
        }
        this.setState(
            {
                data: {
                    ...this.state.data, [type]: data
                }
            });
    }

    handleClick() {
        if (moment(new Date()).isAfter(this.state.data.startDate, "minute")) {
            document.getElementById('startDate').className = "border-danger";
            document.getElementById('dateError').style.display = 'block';
            this.setState({ allowUpdate: false })
        } else if (!this.state.data.endDate.isSameOrAfter(this.state.data.startDate)) {
            document.getElementById('endDate').className = "border-danger"
            document.getElementById('startDate').className = "border-danger"
            document.getElementById('dateError').style.display = 'block';
        } else {
            document.getElementById('endDate').className = ""
            document.getElementById('startDate').className = ""
            document.getElementById('dateError').style.display = 'none';
            if (this.state.data.application === "") {
                document.getElementById('application').className = "form-control border-danger";
                document.getElementById('appError').style.display = 'block';
            } else if (this.state.data.title === "") {
                document.getElementById('application').className = "form-control"
                document.getElementById('appError').style.display = 'none';
                document.getElementById('title').className = "form-control border-danger"
                document.getElementById('titleError').style.display = 'block';
            } else if (this.state.data.message === "") {
                document.getElementById('title').className = "form-control"
                document.getElementById('message').className = "fullwidth border-danger";
                document.getElementById('messageError').style.display = 'block';
                document.getElementById('titleError').style.display = 'none';
            } else {
                document.getElementById('message').className = "fullwidth"

                let data = {
                    "application": this.state.data.application,
                    "title": this.state.data.title,
                    "message": this.state.data.message,
                    "startDate": this.state.data.startDate.format('D MMM YYYY HH:mm:00'),
                    "endDate": this.state.data.endDate.format('D MMM YYYY HH:mm:00')
                }

                this.props.addMaintenance(data);

                this.setState({ updated: true });

            }
        }
    }

    onDismiss() {
        this.setState({ notify: false });
    }

    startDate() {
        this.startcomponent.setOpen(true);
    }

    endDate() {
        this.endcomponent.setOpen(true);
    }

    render() {
        const { translate } = this.props;
        return (
            <div className="AddsysMaintaintcontainer">
                {this.state.showLoader && <Loaders
                    loadingText={`Please Wait...`}
                    modalOpen={true}
                    className={`loading-contents`}
                    color={'#08a5e1'}
                />
                }
                {this.state.notify && <Alert color={this.state.notifyType} isOpen={this.state.notify} toggle={this.onDismiss.bind(this)}> {this.state.notifyText}</Alert>}
                <Col lg="10">
                    <Row>
                        <Col sm="5" className="eqdetailbox">
                            <div className="detailcover">
                                <div className="float-left title">{translate('startDate')}<span className='required'>*</span></div>
                                <div className="float-left">
                                    <DatePicker
                                        selected={this.state.data.startDate}
                                        minDate={new Date()}
                                        timeIntervals={15}
                                        autoComplete='off'
                                        onChange={this.handleChange.bind(this, 'startDate')}

                                        ref={(r) => {
                                            this.startcomponent = r;
                                        }}
                                        dateFormat="D MMM YYYY HH:mm:00"
                                        showTimeSelect
                                        timeFormat="HH:mm"
                                        id="startDate"
                                    />
                                </div>
                                <FaCalendarAlt className="calLogo" onClick={() => this.startDate()} />
                                <div className="clearfix"></div>
                            </div>
                            <div className="error" id="dateError"> Please select valid start or end date</div>
                        </Col>
                        <Col sm="5" className="eqdetailbox">
                            <div className="detailcover pl-0">
                                <div className="float-left title">{translate('endDate')}<span className='required'>*</span></div>
                                <div className="float-left">
                                    <DatePicker
                                        selected={this.state.data.endDate}
                                        minDate={new Date()}
                                        autoComplete='off'
                                        timeIntervals={15}
                                        onChange={this.handleChange.bind(this, 'endDate')}
                                        ref={(r) => {
                                            this.endcomponent = r;
                                        }}
                                        dateFormat="D MMM YYYY HH:mm:00"
                                        showTimeSelect
                                        timeFormat="HH:mm"
                                        id="endDate"
                                    />
                                </div>
                                <FaCalendarAlt className="calLogo" onClick={() => this.endDate()} />
                                <div className="clearfix"></div>
                            </div>
                        </Col>
                        <Col lg="12" className="eqdetailbox">
                            <div className="detailcover">
                                <div className="float-left title">{translate('language')}</div>
                                <div className="float-left">
                                    <SelectField
                                        divClass="selectwrapper"
                                        /* handleChange={ ()=>{ '' } } */
                                        options={[]}
                                        type="select"
                                        name="machineSaleId"
                                        className={(false) ? 'border-danger' : ''}
                                        defaultOption={"English"}
                                    />
                                </div>
                            </div>
                        </Col>
                        <Col lg="12" className="eqdetailbox">
                            <div className="detailcover">
                                <div className="float-left title">{translate('application')}<span className='required'>*</span></div>
                                <div className="float-left"><SelectField
                                    divClass="selectwrapper"
                                    handleChange={this.handleChange.bind(this)}
                                    options={[
                                        <option key={0} value="SMARTASSIST SERVICE">SMARTASSIST SERVICE</option>,
                                        <option key={1} value="SMARTASSIST CUSTOMER">SMARTASSIST CUSTOMER</option>
                                    ]}
                                    type="select"
                                    name="application"
                                    id="application"
                                    className={(false) ? 'border-danger' : ''}
                                    defaultOption={"---Select Application---"}
                                />
                                    <div className="error" id="appError"> Please select your application type</div>
                                </div>
                            </div>
                        </Col>
                        <Col lg="12" className="eqdetailbox">
                            <div className="detailcover">
                                <div className="float-left title">{translate('title')}<span className='required'>*</span></div>
                                <div className="float-left width70">
                                    <InputField max={50} name="title" id="title" handleChange={this.handleChange.bind(this)} />
                                    <div className="error" id="titleError"> Please enter your title</div>
                                </div>
                            </div>

                        </Col>
                        <Col lg="12" className="eqdetailbox">
                            <div className="detailcover">
                                <div className="float-left title">{translate('description')}<span className='required'>*</span></div>
                                <div className="float-left width70">
                                    <textarea name="message" id="message" onChange={this.handleChange.bind(this)} className="fullwidth" row={20} style={{ padding: '0.65rem 0.8rem'}}></textarea>
                                    <div className="error" id="messageError"> Please enter your message</div>
                                </div>
                            </div>

                        </Col>
                        <Col lg="12" className="eqdetailbox">
                            <div className="detailcover">
                                <div className="float-left  title"></div>
                                <div className="float-left">
                                    <Link to="system-maintenance">
                                        <ButtonField
                                            className="btn-danger"
                                            buttonText="Cancel"
                                        /> &nbsp;
                                    </Link>

                                    <ButtonField
                                        className="btn-success"
                                        buttonText="Add"
                                        handleClick={this.handleClick.bind(this)}
                                        clickEvent={true}
                                    />

                                </div>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </div>
        );
    }
}

const mapDispatchToProps = { setToastr };

export default connect(null, mapDispatchToProps)(withRouter(AddSystemMaintenanceComponent));
