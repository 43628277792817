import http from "../../services/http";
import { LIMIT } from "../../constants/Constants";
import { __api_getEquipments, __api_getEquipmentOperatingInfo,__api_getCsvDownload,__api_getServicingInfo,__api_getAllMachineDetail,__api_getAllMachinLocation,__api_getworkDate,__api_getYearOrMonth} from "./sp.endpoints";

export const getAllEquipments = (req) => {
    let offset = (req.page && req.page > 1) ? ((req.page - 1) * LIMIT) : 0;
    let machineNum = req.machineNum != null && req.machineNum != undefined ? req.machineNum : false;
    let model = req.model != null && req.model != undefined ? req.model : false;
    let numberPlate = req.numberPlate != null && req.numberPlate != undefined  ? req.numberPlate : false;
    return http.Request("GET", [[__api_getEquipments, offset, LIMIT, true,machineNum,model,numberPlate].join("/"), req.qs].join("?"), req.data);
};

//export const getCSVDownload = () => http.Request("GET",__api_getCsvDownload);
export const getCSVDownload =data  => {
   return http.Request("POST",__api_getCsvDownload,data);
}

export const getServicingInfo = (req) => {
    let offset = (req.page && req.page > 1) ? ((req.page - 1) * LIMIT) : 0;
    return http.Request("GET", [[__api_getServicingInfo, offset, LIMIT,true].join("/"), req.qs].join("?"), req.data);
};
export const getEquipmentDetails = data => {
    return http.Request("POST", __api_getEquipmentOperatingInfo, data);   
}

export const getAllMachineDetail = () => http.Request("GET",__api_getAllMachineDetail);

export const getAllMachineLocation =() => http.Request("GET",__api_getAllMachinLocation);

export const getWorkDoneDetails =data => {
    return http.Request("POST",__api_getworkDate,data);
}

export const getYearOrMonth = (req) => http.Request("GET", [__api_getYearOrMonth + "/" + req.machineSaleId,req.qs].join("?"));

