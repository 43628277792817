import React, { Component, Fragment } from "react";
import {reduxForm ,change} from "redux-form";
import {connect} from "react-redux";
import { setLoader, setToastr, setConfirmBox } from "../../../actions/Application";
import {getFota, deleteFota} from "../../../api/admin/fota";
import {FaTrashAlt} from 'react-icons/fa'
import Pagination from "../../shared/Pagination";
import SearchFormComponent from "../custom-map/SearchFormComponent";
import Moment from "react-moment";

/** importnig constants */
import { LIMIT } from "../../../constants/Constants";

let form = "updateFotaForm";

class ListFotaComponent extends Component{

    constructor(props){
        super(props);

        this.state = {
            maps: [],
            activePage: 1,
            total: 0,
            qs: {},
            searchItems: [],
        }
    }

    componentWillMount(){
        this.getFotaData({page:1});   
    }

    componentWillReceiveProps(newProps){
        if(newProps.ack){
            this.getFotaData({page: this.state.activePage, qs:this.state.qs});
        }
    }

    confirmnDelete = (user) => {
        this.props.setConfirmBox({ modal: { open: true, data: user, title: "Delete Record", content: `Do you really want to delete this record?`, done: this.deleteRecord }});
    }

    deleteRecord = (item) =>{
        /** show loader */
        this.props.setLoader({loader:{open: true}});

        return new Promise((resolve, reject) => {
            deleteFota(item.listId)
                .then(result => {
                    /** resolve promise */
                    /** close loader */
                    this.props.setLoader({ loader: { open: false } });
                    /** dispatch action to reload data */
                    //this.getFotaData({page: this.state.activePage, qs:this.state.qs});
                    this.props.dispatch(change('SearchCustomMapForm', 'model', ''));
                    this.props.dispatch(change('SearchCustomMapForm', 'machineNum', ''));
                    this.setState({qs:{}}); 
                    this.getFotaData({page:1});
                    resolve({ toastr: { message: "Record has been deleted.", type: "success" } });
                })
                .catch(error => {
                    /** close loader */
                    this.props.setLoader({ loader: { open: false } });
                    setToastr({ toastr: { message: "This request couldn't be completed. Try again later.", type: "error" } });
                    /** reject promise */
                    reject(false);
                })
        });
    }

    getFotaData = (req) => {
        this.props.setLoader({ loader: {open:true}});
        getFota(req)
        .then(result => {
            this.props.setLoader({ loader: {open:false}});
            this.setState({
                maps: (result.data && result.data.listFotaRecords) || [],
                activePage: this.state.activePage || 1,
                total: (result.data.countResult && result.data.countResult.totalCounts) ? result.data.countResult.totalCounts : this.state.total 
            });
        })
        .catch(err => this.props.setLoader({ loader: {open:false}}));
    }

    onPageChange = (page) => {
        this.setState({activePage: page || 1}, () => {
            this.getFotaData({page, qs:this.state.qs});
        });  
    }

    onSearch = (qs) => {
        if(!Object.keys(qs).length) return false;

        this.props.setLoader({ loader: {open: true}});
        this.setState({ qs });

        this.getFotaData({page:1, qs });
    }

    clearSearch = () => {
        this.props.clearSearch();
        this.setState({qs:{}}); 
        this.getFotaData({page:1});
    }

    render(){
        const {maps, activePage, total} = this.state;
        return(
            <Fragment>
                <h4>Search Equipment</h4>
                <SearchFormComponent onSearch={this.onSearch} qs={this.state.qs} clearSearch={this.clearSearch} getEquipmentList={this.props.getEquipmentList} searchItems={this.props.searchItems} goToItem={this.props.goToItem} keyword={this.props.keyword} fieldType={this.props.fieldType}/>

                <table className="tabledashboard table table-striped table-advance table-hover m-top-30">
                    <thead>
                        <tr>
                            <th>Model</th>
                            <th>Machine Number</th>
                            <th>Version</th>
                            <th>Kernel Version</th>
                            <th>Date</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Array.isArray(maps) && maps.length>0 && maps.map((item,i) => (
                            <tr key={item.model+i+1}>
                                <td>{item.model || "NA"}</td>
                                <td>{item.machineNum || "NA"}</td>
                                <td>{item.swVer || "NA"}</td>
                                <td>{item.kernelVer || "NA"}</td>
                                <td><Moment format="DD MMM YYYY HH:mm:ss">{item.date}</Moment></td>
                                <td> 
                                    <FaTrashAlt onClick={() => this.confirmnDelete(item)}/>
                                </td>
                            </tr>
                        ))}

                        {!maps.length && <tr><td align="center" colSpan="6">No Data Found</td></tr>}
                        
                    </tbody>
                </table>

                <Pagination 
                    limit={LIMIT}
                    total={total}
                    activePage={activePage}
                    onChange={this.onPageChange}
                    data={maps || []}
                />
            </Fragment>
        );
    }
}


let formComponent = reduxForm({ form })(ListFotaComponent);
let mapDispatchToProps = { setLoader, setToastr, setConfirmBox };

export default connect(null, mapDispatchToProps)(formComponent);