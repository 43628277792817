import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { setToastr, setLoader } from "../../../actions/Application";
import { Field, reduxForm, change } from "redux-form";
import 'react-datepicker/dist/react-datepicker.css';
import { saveHourMeterOffsetValue } from "../../../api/admin/hourmeter-offset-management";
import { getHourMeterGap, getHourMeterGapHistory } from "../../../api/admin/hourmeter-offset-management";
import { renderTextField } from "../../shared/renderField";
import { Row, Col, Card, CardBody } from 'reactstrap';
import { required,lessThanSixDigit } from "../../shared/Validation";
import { isEmpty } from "lodash";
import ReactDOM from "react-dom";
import Moment from "react-moment";
import { getAllEquipments } from '../../../api/service-provider/equipments';


const form = "HourmeterOffsetDetailForm";


class HourmeterOffsetDetail extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: null,
            machineSaleId: undefined,
            searchItems: [],
            fieldType: "",
            selectedButton: 1,
            selectedModel: "",
            selectedMachineNum: "",
            selectedOffset: "",
            calculatedValue: 0,
            fieldType: "",
            getEquipmentSearch: true,
            userInput : null,
            model: "",
            machineNum: "",
            historyData: [],
            initialSearch: "",
            showSearch: false,
            searchClicked: false,
            equipments:[]
        };
        this.handleButtonClick = this.handleButtonClick.bind(this);
        this.getEquipmentList = this.getEquipmentList.bind(this);
        this.handleNumberChange = this.handleNumberChange.bind(this);
        this.saveHourMeterOffsetValue = this.saveHourMeterOffsetValue.bind(this);
        this.goToItem = this.goToItem.bind(this);
        this.searchEquipment = this.searchEquipment.bind(this);
    }

    componentDidMount() {
        let queryParams = window.location.search.substring(1).split('&');
        for (var i = 0; i < queryParams.length; i++) {
            let parameters = queryParams[i].split('=');
            this.setState({ [parameters[0]]: parameters[1] });
        }
        this.getHourMeterGapInfo({ page: 1 });
    }

    componentDidUpdate(preProps, prevState) {
        //console.table(prevState);
        if (!this.state.showSearch && (prevState.model != this.state.model || prevState.machineNum != this.state.machineNum)) {
            let { dispatch } = this.props;
            dispatch(change(form, 'model1', this.state.model));
            dispatch(change(form, 'machineNum1', this.state.machineNum));
            this.getHourMeterGapInfo();
            this.getHourMeterGapHistoryInfo();
        }
    }

    getHourMeterGapHistoryInfo() {
        let model = this.state.model;
        let machineNum = this.state.machineNum;

        this.props.setLoader({ loader: { open: true } });
        getHourMeterGapHistory({ model: model, machineNum: machineNum })
            .then(result => {
                if (result.data) {
                    this.setState({ historyData: result.data });
                }
                //this.props.setLoader({ loader: { open: false } });

            })
            .catch(error => {
                this.props.setToastr({ toastr: { type: "error", message: error || "Something went wrong." } });
                this.props.setLoader({ loader: { open: false } });
            })
    }

    getHourMeterGapInfo(page) {
        let model = this.state && this.state.model;
        let machineNum = this.state && this.state.machineNum;

        this.props.setLoader({ loader: { open: true } });
        //getHourMeterGap(machineNum,model)
        let queryParams = [];
        if (model != '') {
            queryParams.push(`modelId=${model}`);
        }
        if (machineNum != '') {
            queryParams.push(`machineNum=${machineNum}`);
        }
        getHourMeterGap({ page: page || 1, qs: queryParams.join('&') })
            .then(result => {
                if (page) {
                    this.setState({ data: result.data });
                }
                else {
                    this.setState({ initialSearch: result.data });
                    let { dispatch } = this.props;
                    dispatch(change(form, 'model1', result.data.hourMeterGapWithOffSet.length > 0 ? result.data.hourMeterGapWithOffSet[0].model : ''));
                    dispatch(change(form, 'machineNum1', result.data.hourMeterGapWithOffSet.length > 0 ? result.data.hourMeterGapWithOffSet[0].machineNum : ''));
                    dispatch(change(form, 'hourmeterOffset', result.data.hourMeterGapWithOffSet.length > 0 ? result.data.hourMeterGapWithOffSet[0].hourmeterOffset : ''));
                    //dispatch(change(form, 'calculatedValue', result.data.hourMeterGapWithOffSet.length > 0 ? result.data.hourMeterGapWithOffSet[0].hourmeterOffset : '0'));
                    dispatch(change(form, 'calculatedValue', result.data.hourMeterGapWithOffSet.length > 0 ? (result.data.hourMeterGapWithOffSet[0].hourmeterOffset + 'hr') : '0'));
                    this.setState({calculatedValue : result.data.hourMeterGapWithOffSet.length > 0 ? (result.data.hourMeterGapWithOffSet[0].hourmeterOffset) : 0})
                    if (result.data.hourMeterGapWithOffSet.length == 0) {
                        let filtered = this.state.data.hourMeterGapWithOffSet.filter(a => a.model == model && a.machineNum == machineNum);
                        if(filtered.length>0){
                            dispatch(change(form, 'model1', model));
                            dispatch(change(form, 'machineNum1', machineNum));
                            dispatch(change(form, 'hourmeterOffset', 0));
                            //dispatch(change(form, 'calculatedValue', result.data.hourMeterGapWithOffSet.length > 0 ? result.data.hourMeterGapWithOffSet[0].hourmeterOffset : '0'));
                            dispatch(change(form, 'calculatedValue', '0'));

                        }
                        else{
                            this.props.setToastr({ toastr: { message: "Enter correct details", type: "error" } });
                            this.props.setLoader({ loader: { open: false } });
                        }
                        
                    }
                }

                this.props.setLoader({ loader: { open: false } });

            })
            .catch(error => {
                this.props.setToastr({ toastr: { type: "error", message: error || "Something went wrong." } });
                this.props.setLoader({ loader: { open: false } });
            })
    }
    searchEquipment() {
        if (this.state.model == "" || this.state.machineNum == "") {
            this.props.setToastr({ toastr: { message: "Enter both model and machine number to search", type: "error" } });
            this.props.setLoader({ loader: { open: false } });
        }
        if (this.state.model && this.state.machineNum) {
            this.setState({ searchClicked: true });
            this.getHourMeterGapInfo();
            this.getHourMeterGapHistoryInfo();
        }

    }

    goToItem(attr, fieldType) {
        //setting value
        this.props.dispatch(change('HourmeterOffsetDetailForm', fieldType, attr));
        this.setState({ searchItems: [], fieldType: "", [fieldType]: attr });
    }

    getEquipmentList(e) {
        let equipments = [];
        const data = { ...this.state.data };
        let fieldType = e.target.id
        let models = [], machines = [];
        this.setState({ [fieldType]: '' })
        let { value } = ReactDOM.findDOMNode(document.getElementById(e.target.id)), regex = new RegExp(value.trim(), 'i');
        if (value && value.length <= 2) return false;
        getAllEquipments({ page: 1, machineNum: true, model: true, numberPlate: true, qs: `searchField=${value}` })
            .then(result => {
                if (result.data) {
                    this.setState({ equipments: result.data.ownerEquipmentDetailList })
                    data.hourMeterGapWithOffSet = result.data.ownerEquipmentDetailList;
                    this.setState({ data });
                    let equipmentList = this.state.equipments;
                    if (isEmpty(value)) {
                        if (fieldType === 'model') {
                            this.setState({ selectedModel: '' });
                        } else if (fieldType === 'machineNum') {
                            this.setState({ selectedMachine: '' });
                        }
                    }
                    let filtered = equipmentList && equipmentList.filter(e => {
                        if (e.model != null && !models.includes(e.model.toString().trim()) && fieldType === 'model') {
                            if (regex.test(e.model.toString().trim())) {
                                models.push(e.model.toString().trim());
                                return e;
                            }
                        } else if (e.machineNum != null && !machines.includes(e.machineNum.toString()) && fieldType === 'machineNum') {
                            if (regex.test(e.machineNum)) {
                                machines.push(e.machineNum.toString())
                                return e;
                            }
                        }
                    });
                    this.setState({ searchItems: filtered, keyword: value, fieldType: fieldType });
                }
                else {
                    this.props.setToastr({ toastr: { message: "Something went wrong", type: "error" } });
                }
            })

            .catch(error => {
                this.props.setToastr({ toastr: { message: error, type: "error" } });
            })
    }

    handleButtonClick(event, selectedButtonId) {
        event.preventDefault();
        if(this.state.initialSearch.hourMeterGapWithOffSet==null){
            return;
        }
        let { dispatch } = this.props;
        this.setState({ selectedButton: selectedButtonId });
        let userInput = this.state.userInput ? parseFloat(this.state.userInput) : 0;
        let offset = parseFloat(this.state.initialSearch.hourMeterGapWithOffSet[0].hourmeterOffset);
        let calculatedValue = (selectedButtonId === 1) ? (offset + userInput) : (offset - userInput);
        dispatch(change(form, 'calculatedValue', calculatedValue.toFixed(1)+'hr'));
        this.setState({ calculatedValue: calculatedValue });
    }

    handleNumberChange(event) {
        let { dispatch } = this.props;
        let userInput = parseFloat(event.target.value) || 0;
        this.setState({ userInput: userInput });
        let offset = this.state.initialSearch.hourMeterGapWithOffSet[0]!=null ? parseFloat(this.state.initialSearch.hourMeterGapWithOffSet[0].hourmeterOffset) : 0;
        let calculatedValue = this.state.selectedButton === 1 ? offset + userInput : offset - userInput;
        dispatch(change(form, 'calculatedValue', calculatedValue.toFixed(1)+'hr'));
        this.setState({ calculatedValue: calculatedValue });
    }

    cancelUpdate() {
        let { dispatch } = this.props;
        dispatch(change(form, 'difference', ''));
        dispatch(change(form, 'calculatedValue', this.state.initialSearch.hourMeterGapWithOffSet.length > 0 ? this.state.initialSearch.hourMeterGapWithOffSet[0].hourmeterOffset : '0'));
        this.setState({ calculatedValue:  this.state.initialSearch.hourMeterGapWithOffSet.length > 0 ? this.state.initialSearch.hourMeterGapWithOffSet[0].hourmeterOffset : '0' });
        this.setState({ userInput: null });
    }

    saveHourMeterOffsetValue() {
        let model = this.state.model;
        let machineNum = this.state.machineNum;
        let filtered = this.state.data.hourMeterGapWithOffSet.filter(a => a.model == model && a.machineNum == machineNum);
        let machineSaleId = filtered[0].machineSaleId;
        let { dispatch } = this.props;
        let offset = this.state.initialSearch.hourMeterGapWithOffSet.length>0 ? parseFloat(this.state.initialSearch.hourMeterGapWithOffSet[0].hourmeterOffset) : 0;
        let calculatedValue = parseFloat(this.state.calculatedValue);
        
        if(offset == calculatedValue){
            this.props.setToastr({ toastr: { message: "Please Enter Hourmeter difference", type: "error" } });
            return;
        }

        let machineDetails = {
            hourmeterOffSet: this.state.selectedButton === 1 ? this.state.userInput : (this.state.userInput)*-1,
            machineSaleId: machineSaleId,
        }
        this.props.setLoader({ loader: { open: true } });
        saveHourMeterOffsetValue(machineDetails)
            .then(result => {
                if (result.data) {
                    this.getHourMeterGapHistoryInfo();
                    this.props.setToastr({ toastr: { message: "Hourmeter offset updated successfully", type: "success" } });
                    this.props.setLoader({ loader: { open: false } });
                    dispatch(change(form, 'difference', ''));
                    this.setState({userInput : 0})
                    this.getHourMeterGapInfo();
                } else {
                    this.props.setToastr({ toastr: { message: "Cannot update hourmeter offset value", type: "error" } });
                    this.props.setLoader({ loader: { open: false } });
                }
            })
            .catch(error => {
                this.props.setToastr({ toastr: { message: "Cannot update hourmeter offset value", type: "error" } });
                this.props.setLoader({ loader: { open: false } });
            })
    }

    render() {
        let { handleSubmit, data, dispatch, submitting } = this.props;
        let { fieldType, searchItems, keyword, showSearch, offset, searchClicked } = this.state;

        let showDetails = true;
        if (showSearch == 'true' && !searchClicked) {
            showDetails = false;
        }
        return (
            <Fragment>
                <form onSubmit={handleSubmit(this.saveHourMeterOffsetValue)} name="HourmeterOffsetDetailForm" id="HourmeterOffsetDetailForm" autoComplete='off'  >
                    {showSearch && <Row className="members align-items-center form-srch">
                        <Col sm="12">
                            <div className="row profile-asset">
                                <div className="col-sm-6">
                                    <div className="row">
                                        <div className="col-sm-6">Model <span className='required'>*</span>
                                            <Field name="model" id="model" component={renderTextField} className='form-control' type="text" validate={[required]} onChange={this.getEquipmentList} />
                                            {keyword && searchItems && keyword.length > 0 && searchItems.length > 0 && fieldType === 'model' &&
                                                <div className='search-area'>
                                                    <Card className='search-card'>
                                                        <CardBody style={{overflowY:"auto" , maxHeight:"200px"}}>
                                                            <ul className="list-group list-group-flush">
                                                                {searchItems.map((item, i) => {
                                                                    return <Item key={i} attr={{ item }} goToItem={this.goToItem} fieldType={'model'} />;
                                                                })}
                                                            </ul>
                                                        </CardBody>
                                                    </Card>
                                                </div>}
                                            {keyword && searchItems && keyword.length > 0 && searchItems.length == 0 && fieldType === 'model' &&
                                                <div className='search-area'>
                                                    <Card className='search-card'>
                                                        <CardBody>
                                                            <ul className="list-group list-group-flush">
                                                                <li className="list-group-item pointer" >
                                                                    <Row>
                                                                        <Col sm='12' className='no-padd-lr'>
                                                                            <span>No record found</span>
                                                                        </Col>
                                                                    </Row>
                                                                </li>
                                                            </ul>
                                                        </CardBody>
                                                    </Card>
                                                </div>
                                            }
                                        </div>

                                        <div className="col-sm-6">Machine Number <span className='required'>*</span>
                                            <Field name="machineNum" id='machineNum' component={renderTextField} className='form-control' type="text" validate={[required]} onChange={this.getEquipmentList} />
                                            {keyword && searchItems && keyword.length > 0 && searchItems.length > 0 && fieldType === 'machineNum' &&
                                                <div className='search-area'>
                                                    <Card className='search-card'>
                                                        <CardBody style={{overflowY:"auto" , maxHeight:"200px"}}>
                                                            <ul className="list-group list-group-flush">
                                                                {searchItems.map((item, i) => {
                                                                    return <Item key={i} attr={{ item }} goToItem={this.goToItem} fieldType={'machineNum'} />;
                                                                })}
                                                            </ul>
                                                        </CardBody>
                                                    </Card>
                                                </div>
                                            }
                                            {keyword && searchItems && keyword.length > 0 && searchItems.length == 0 && fieldType === 'machineNum' &&
                                                <div className='search-area'>
                                                    <Card className='search-card'>
                                                        <CardBody>
                                                            <ul className="list-group list-group-flush">
                                                                <li className="list-group-item pointer" >
                                                                    <Row>
                                                                        <Col sm='12' className='no-padd-lr'>
                                                                            <span>No record found</span>
                                                                        </Col>
                                                                    </Row>
                                                                </li>
                                                            </ul>
                                                        </CardBody>
                                                    </Card>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <button type="button" className="btn btn-primary srch-btn" onClick={this.searchEquipment} disabled={submitting}>Search</button>
                                </div>
                            </div>
                        </Col>

                    </Row>}

                    {showDetails && <Row className="members align-items-center ">
                        <Col sm="12">
                            <div className="row profile-asset">
                                <div className="col-sm-2 "> Model
                                    <Field id="model1" name="model1" component={renderTextField} className='form-control' disabled type="text" />
                                </div>
                                <div className="col-sm-2"> Machine Number
                                    <Field id="machineNum1" name="machineNum1" component={renderTextField} className='form-control' disabled type="text" />
                                </div>
                                <div className="col-sm-2"> Offset value(Hrs)
                                    <Field id="hourmeterOffset" name="hourmeterOffset" component={renderTextField} className='form-control' disabled type="text" value={offset} />
                                </div>
                                <div md='6' sm='6' xs='12'>
                                    <button className={(this.state.selectedButton === 1) ? 'selected' : 'unselected'} style={{ width: '35px', marginTop: '22px', padding: '5px' }} onClick={(e) => { this.handleButtonClick(e, 1); }}>+</button>
                                    <button className={(this.state.selectedButton === -1) ? 'selected' : 'unselected'} style={{ width: '35px', padding: '5px' }} onClick={(e) => { this.handleButtonClick(e, -1); }}>-</button>
                                </div>
                                <div className="col-sm-2">Difference in hourmeter
                                    <Field name="difference" id="difference" placeholder="Add difference in hourmeter" component={renderTextField} className='form-control'  type="number" validate={[lessThanSixDigit]} onChange={this.handleNumberChange}/>
                                    {/*<Field name="difference" id="difference" component={renderTextField} className='form-control' type="text" validate={[onlyNumbers, max5]} onChange={this.handleNumberChange} />*/}
                                </div>
                                <div className="operator col-sm-1">
                                    <span>=</span>
                                </div>
                                <div className="col-sm-2">Total Offset value
                                    <Field name="calculatedValue" component={renderTextField} className='calculated' type="text" readOnly />
                                </div>
                            </div>

                        </Col>
                    </Row>}
                    {showDetails && <Row className="members align-items-center " style={{ float: 'right', marginBottom: '15px' }}>
                        <div className="save">
                            <button type="button" className="btn btn-danger srch-btn" /* disabled={submitting} */ onClick={() => this.cancelUpdate()}>Cancel</button>
                        </div>

                        <div className="save">
                            <button type="submit" className="btn btn-primary srch-btn" /* disabled={submitting} */>Save</button>
                        </div>

                    </Row>}
                </form>

                <div >
                </div>

                {showDetails && <span className='sub-heading'>Change History Information</span>}
                {showDetails && <table className="tabledashboard table table-striped table-advance table-hover m-top-30 eqService">
                    {this.state.historyData && this.state.historyData.length > 0 &&
                        < thead >
                            <tr>
                                {/* <th>Sr. No</th> */}
                                <th>Updated On</th>
                                <th>Updated By</th>
                                <th>Offset Value</th>
                            </tr>
                        </thead>}
                    {this.state.historyData != undefined &&
                        <tbody>
                            {this.state.historyData && this.state.historyData.length > 0 && this.state.historyData.map((item, i) => (
                                <tr key={item.model + i + 1}>
                                    <td>{item.serviceDate ? (<Moment format="DD MMM YYYY HH:mm:ss">
                                        {item.serviceDate}
                                    </Moment>) : " "}</td>
                                    <td>{item.updateBy}</td>
                                    <td>{item.hourmeterOffset}</td>
                                </tr>
                            ))}
                            {!this.state.historyData.length && <tr><td align="center" colSpan="12">No History Found</td></tr>}
                        </tbody>}
                </table>}

            </Fragment>)
    }
}

const Item = props => {
    let { attr, goToItem, fieldType } = props;
    var listOption = attr.item[fieldType];
    return (
        <li className="list-group-item pointer" onClick={() => goToItem(listOption, fieldType)} >
            <Row>
                <Col sm='12' className='no-padd-lr'>
                    <span>{listOption}</span> &nbsp;
                </Col>
            </Row>
        </li>
    );
}
function mapStateToProps(state) {
    return;
    //let saveData = (state.sp && state.sp.data && state.sp.data.SET_EQUIPMENT_SERVICE_INFO) ? state.sp.data.SET_EQUIPMENT_SERVICE_INFO.data : false;
    //let equipmentList = (state.sp && state.sp.eqpList && state.sp.eqpList.SET_ALL_EQUIPMENTS)
    //return { saveData: saveData, equipmentList: equipmentList }
}
{/*const mapDispatchToProps = { setToastr, setLoader };
let HourmeterOffsetDetailForm = (reduxForm({ form: form })(withRouter(HourmeterOffsetDetail)));

export default connect(null, mapDispatchToProps)(HourmeterOffsetDetail);*/}

let formComponent = reduxForm({ form: "HourmeterOffsetDetailForm" })(HourmeterOffsetDetail);
let mapDispatchToProps = { setLoader, setToastr };

export default connect(mapStateToProps, mapDispatchToProps)(formComponent);