import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Button } from "reactstrap";
import { withRouter } from "react-router-dom";
import { FaTrash, FaPencilAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import { FaPlusCircle } from "react-icons/fa";
/** importing sub-components */
import Pagination from "../../shared/Pagination";
import _find from "lodash/find";
import session from "../../../services/session";

/** importing APIs */
// import { CRUDDataControl } from "../../../api/admin/data-control";
/** importnig constants */
import { LIMIT } from "../../../constants/Constants";
/** importing actions */
import { setConfirmBox, setLoader, setToastr } from "../../../actions/Application";
import { SET_ROLES } from "../../../types";
import { getRole_list } from "../../../actions/admin/getRoles";
import { deleteRole } from "../../../api/admin/roles";

let noDelete = ["NO_ROLE_ASSIGNED", "DEFAULT"];

class RoleComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            total: 0,
            activePage: 1,
            loading: true,
            roles: {},
            routeList: (session && session.get("prev", "cookie")) || []
        }

        /** funciton binding */
        this.changePager = this.changePager.bind(this);
        this.deleteRow = this.deleteRow.bind(this);
    }

    componentDidMount() {
        this.props.setLoader({ loader: { open: true } });
        this.props.getRole_list({ page: 1 });
    }

    componentWillReceiveProps(newporps) {
        if (newporps && newporps.roleList) {
            this.props.setLoader({ loader: { open: false } });
            this.setState({ roles: newporps.roleList })
        }
    }

    changePager(page = 0) {
        this.props.setLoader({ loader: { open: true } });
        this.setState({ loading: true, activePage: page || 1 });
        this.props.getRole_list({ page });
    }

    deleteDataConfirmation(row) {
        this.props.setConfirmBox({ modal: { open: true, data: { roleCode: row.roleCode }, title: "Delete Role", content: `Do you really want to delete ${row.name} role?`, done: this.deleteRow } });
    }

    deleteRow(roleCode) {
        return new Promise((resolve, reject) => {
            deleteRole(roleCode)
                .then(result => {
                    this.props.setLoader({ loader: { open: false } });
                    this.props.getRole_list({ page: 1 });
                    resolve({ toastr: { message: 'Role has been deleted successfully.', type: "success" } });
                })
                .catch(error => {
                    /** reject promise */
                    reject("This Role can't be deleted.");
                })
        });
    }

    upsertData(child, parent, key) {
        let p = document.getElementById("parentCompany" + key).value;
        if (!child || !p) return false;
    }

    render() {
        let { activePage, roles } = this.state;
        let routeList = (session && session.get("prev", "cookie")) || [];
        let modules = {
            role: "/service/roles",
            addRole: "/service/roles/add",
            updateRole: "/service/roles/edit/:roleid"
        };

        let hasNewRole = _find(routeList, { uuid: modules.role }),
            hasUpdateRole = _find(routeList, { uuid: modules.role });


        return (
            <Fragment>

                <div className="link-role">

                    {hasNewRole && <Link className="btn btn-primary float-right" to="roles/add">
                        <FaPlusCircle size='1em' />  Add Roles</Link>}
                    <Link className="btn btn-primary float-right compareRole" to="roles/compareRoles">
                        Compare Roles </Link>

                </div>
                <table className="tabledashboard table table-striped table-advance role-list table-hover m-top-30">
                    <thead>
                        <tr>
                            <th>Role Name</th>
                            <th>Permissions</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {roles && roles.roles && Object.keys(roles.roles).map((key, i) => {

                            const list = roles.roles[key][0];

                            return <tr key={i}>
                                <td> {key.toUpperCase()} </td>
                                {list && ((list.admin && list.admin.length) || (list.serviceProvider && list.serviceProvider.length)) ?
                                    <td width="65%" className=" pointer-default">
                                        {list &&
                                            Object.keys(list).map((node, index) =>

                                                <div key={index} className="space-v">

                                                    {list[node] && list[node].length > 0 && <>

                                                        <div><b>{node && node.replace(/([a-z])([A-Z])/g, '$1 $2')} </b></div>
                                                        {list[node] && list[node].map((roleList, i) =>
                                                            <span key={i} className='role-title'>{roleList}</span>
                                                        )}
                                                    </>}


                                                </div>
                                            )

                                        }

                                    </td>

                                    : <td className="pointer-default">No Permissions assign</td>
                                }

                                <td width="15%">
                                    <div className="space-v">


                                        {noDelete.indexOf(key) < 0 && <Button onClick={() => this.deleteDataConfirmation({ roleCode: list.roleCode, name: key })}> <FaTrash /> </Button>} &nbsp;


                                    {noDelete.indexOf(key) < 0 && <Button onClick={() => hasUpdateRole ? this.props.history.push(`/service/roles/edit/${list.roleCode}`) : "#"}> <FaPencilAlt /> </Button>}
                                    </div>
                                </td>

                            </tr>
                        })}

                        {(roles && roles.roles && !Object.keys(roles.roles).length || !Object.keys(roles).length) && <tr><td colSpan='3'><center>No Role Found</center></td></tr>}

                    </tbody>
                </table>

                {roles && roles.roles && <Pagination
                    limit={LIMIT}
                    total={roles.countResult.totalCounts}
                    activePage={activePage}
                    onChange={this.changePager}
                    data={Object.keys(roles.roles)}
                />}


            </Fragment>
        );
    }
}


const mapDispatchToProps = { setConfirmBox, setLoader, setToastr, getRole_list, SET_ROLES }

const mapStateToProps = state => {
    return ({
        roleList: state.RoleList
    });
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RoleComponent));