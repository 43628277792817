import { takeLatest, call, put } from 'redux-saga/effects';
import api from '../../api/index';
import { SET_ADMIN_LOGGED_IN_TOKEN, GET_ADMIN_LOGGED_IN, SENDING_REQUEST, INVALID_LOGIN, USER_FETCH_DATA_FAIL } from '../../types';
import CryptoJS from 'crypto-js';
import { hashKey } from '../../constants/Constants';
import cookie from 'react-cookies';
import session from "../../services/session";

/**
 * Call the api and dispatch data to the reducer
 * @param { Object } action Contained object data to be send to API call
 */
export function* loginAdmin(action) {

  const data = action.data;
  try {
    yield put(
      {
        type: SENDING_REQUEST,
        customerData: '',
        resMsg: '',
        isSending: true,
        isLoggedIn: false,
        error: false
      }
    )
    const response = yield call(api.admin.user.login, data);

    if (response.statusCode === 200 && response.data && response.data.token && response.data.functions && response.data.functions.length) {
   
      let params = { 'token': response.data.token };
        session.set("adminname", data.username, "cookie");
        session.set('ADMINTOKEN', params, "cookie");
        session.set('prev', response.data.functions,'cookie');
        let date = new Date();
        date.setTime(date.getTime() + (30 * 60 * 1000));
        cookie.save('admin_activity', 1, { path: '/', expires: date });
        localStorage.setItem('admin_authenticated', CryptoJS.AES.encrypt('Authenticated_admin', hashKey));

        yield put(
          {
            type: SET_ADMIN_LOGGED_IN_TOKEN,
            token: response,
            resMsg: '',
            isSending: false,
            isLoggedIn: true,
            error: false,
          }
        );
       
    } else {
      yield put(
        {
          type: INVALID_LOGIN,
          customerData: '',
          resMsg: "You don't have sufficient permissions to login into dashboard.",
          isSending: false,
          isLoggedIn: false,
          error: true,
        }
      );
    }

    /*switch (response.status) {
      case 200 :
        
        break;
      default:
            
        break;
    }*/


  } catch (e) {
    yield put({
      type: USER_FETCH_DATA_FAIL,
      payload: {
        error: (e && e.message) || 'Some error occurred', isLoggedIn: false, errorCheck: true, resMsg: (e && e.message) || 'Invalid Username or Password', isSending: false
      }
    });
  }
}

/**
 * map the dispatched action to the above function
 */
export default function* watchLoginAdmin() {
  yield takeLatest(GET_ADMIN_LOGGED_IN, loginAdmin)
}
