import { takeLatest, call, put } from 'redux-saga/effects';
import { getManageServiceCompanyEqList} from '../../api/admin/manageServiceCompany';
import { getCustomMapEqList,getSavedCustomMapEqList} from '../../api/admin/custom-map';
import { getFotaEqList,getAllFotaEqList} from '../../api/admin/fota';
import { SET_TOASTR, SET_LOADER,GET_MANAGE_SERVICE_EQUIPMENT_LIST,GET_ALL_FOTA_EQUIPMENT_LIST,SET_ALL_FOTA_EQUIPMENT_LIST,SET_MANAGE_SERVICE_EQUIPMENT_LIST ,SET_FOTA_EQUIPMENT_LIST,GET_FOTA_EQUIPMENT_LIST,SET_CUSTOM_MAP_EQUIPMENT_LIST,GET_CUSTOM_MAP_EQUIPMENT_LIST,GET_SAVED_CUSTOM_MAP_EQUIPMENT_LIST,SET_SAVED_CUSTOM_MAP_EQUIPMENT_LIST} from '../../types';



export function* getManageServiceEquipmentList(action) {
    const data = action.data
    try {
        const eqList = yield call(getManageServiceCompanyEqList, action.data);
        yield put({ type: SET_MANAGE_SERVICE_EQUIPMENT_LIST, data: eqList });
    }
    catch (e) {
        yield put({
            type: SET_TOASTR,
            data: {
                toastr: {
                    message: "We could't find search result for you.", 
                    type:"info"
                }
            }
        });

        yield put({
            type: SET_LOADER,
            data: {
                loader: {
                    open: false
                }
            }
        });
    }
}
export function* getSavedCustomEquipmentList(action) {
    const data = action.data
    try {
        const eqList = yield call(getSavedCustomMapEqList, action.data);
        yield put({ type: SET_SAVED_CUSTOM_MAP_EQUIPMENT_LIST, data: eqList });
    }
    catch (e) {
        yield put({
            type: SET_TOASTR,
            data: {
                toastr: {
                    message: "We could't find search result for you.", 
                    type:"info"
                }
            }
        });

        yield put({
            type: SET_LOADER,
            data: {
                loader: {
                    open: false
                }
            }
        });
    }
}
export function* getCustomEquipmentList(action) {
    const data = action.data
    try {
        const eqList = yield call(getCustomMapEqList, action.data);
        yield put({ type: SET_CUSTOM_MAP_EQUIPMENT_LIST, data: eqList });
    }
    catch (e) {
        yield put({
            type: SET_TOASTR,
            data: {
                toastr: {
                    message: "We could't find search result for you.", 
                    type:"info"
                }
            }
        });

        yield put({
            type: SET_LOADER,
            data: {
                loader: {
                    open: false
                }
            }
        });
    }
}
export function* getSavedFotaEquipmentList(action) {
    const data = action.data
    try {
        const eqList = yield call(getAllFotaEqList, action.data);
        yield put({ type: SET_ALL_FOTA_EQUIPMENT_LIST, data: eqList });
    }
    catch (e) {
        yield put({
            type: SET_TOASTR,
            data: {
                toastr: {
                    message: "We could't find search result for you.", 
                    type:"info"
                }
            }
        });

        yield put({
            type: SET_LOADER,
            data: {
                loader: {
                    open: false
                }
            }
        });
    }
}
export function* getFotaEquipmentList(action) {
    const data = action.data
    try {
        const eqList = yield call(getFotaEqList, action.data);
        yield put({ type: SET_FOTA_EQUIPMENT_LIST, data: eqList });
    }
    catch (e) {
        yield put({
            type: SET_TOASTR,
            data: {
                toastr: {
                    message: "We could't find search result for you.", 
                    type:"info"
                }
            }
        });

        yield put({
            type: SET_LOADER,
            data: {
                loader: {
                    open: false
                }
            }
        });
    }
}
/**
 * map the dispatched action to the above function
 */
export default function* watchEquipmentList() {
    yield takeLatest(GET_MANAGE_SERVICE_EQUIPMENT_LIST, getManageServiceEquipmentList)
    yield takeLatest(GET_CUSTOM_MAP_EQUIPMENT_LIST, getCustomEquipmentList)
    yield takeLatest(GET_SAVED_CUSTOM_MAP_EQUIPMENT_LIST, getSavedCustomEquipmentList)
    yield takeLatest(GET_FOTA_EQUIPMENT_LIST, getFotaEquipmentList)
    yield takeLatest(GET_ALL_FOTA_EQUIPMENT_LIST, getSavedFotaEquipmentList)
}
