import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Form, FormGroup, Col, Row, CustomInput, Input } from "reactstrap";
import _find from "lodash/find";
import { getModuleFeatures } from "../../../api/admin/roles";
/** importing APIs */
// import { CRUDDataControl } from "../../../api/admin/data-control";
/** importing actions */
import { setConfirmBox, setLoader, setToastr } from "../../../actions/Application";
import { SET_ROLES_LIST } from "../../../types";
import { getRoleList } from "../../../actions/admin/getRoles";

const splitCaseStr = (str, styling = '$1 $2') => {
    return str.replace(/([a-z])([A-Z])/g, styling);
}
class CompareRoleComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            roles: {},
            roleName: {},
            leftRoleName: '',
            rightRoleName: '',
            modules: {},
            access: [],
        }

        /** funciton binding */
        this.onRoleNameSelect = this.onRoleNameSelect.bind(this)
        this.getFeatures = this.getFeatures.bind(this)
        this.generateModuleMap = this.generateModuleMap.bind(this);
    }
    getFeatures() {
        getModuleFeatures().then(result => {
            this.setState({
                access: result.data.access || [],
                modules: result.data.modules || {}
            });
        }).catch(err => this.props.setLoader({ loader: { open: false } }))
    }
    generateModuleMap(permissions) {
        let { modules } = this.state;
        let section = [];

        for (let i in modules) {
            section.push(<Table key={i} module={i} functions={modules[i]} permissions={permissions} />);
        }
        return section.length ? section : "";
    }

    onRoleNameSelect(e) {
        var id = e.target.id;
        if (id === 'leftRoleName') {
            this.setState({ 'leftRoleName': e.target.value })
        } else {
            this.setState({ 'rightRoleName': e.target.value })
        }
    }
    render() {
        let { roleName, roles, modules, leftRoleName, rightRoleName } = this.state;
        let leftRoleSelectedPermssions = roles[leftRoleName];
        let rightRoleSelectedPermssions = roles[rightRoleName];
        return (
            <div>
                <div className="container"></div>
                <div className="row">
                    <div className="col-5">
                        <Input type="select" name="leftRoleName" id='leftRoleName' className="no-padded" onChange={this.onRoleNameSelect} >
                            <option value=''>{'Select Role Name'}</option>
                            {roleName.length && roleName.map((opt, i) => {
                                return (
                                    <option key={i} value={opt}>{opt.toUpperCase()}</option>
                                );
                            })}
                        </Input>
                    </div>
                    <div className="col-1">
                        V/S
                    </div>
                    <div className="col-5">
                        <Input type="select" name="rightRoleName" id='rightRoleName' className="no-padded" onChange={this.onRoleNameSelect} >
                            <option value=''>{'Select Role Name'}</option>
                            {roleName.length && roleName.map((opt, i) => {
                                return (
                                    <option key={i} value={opt}>{opt.toUpperCase()}</option>
                                );
                            })}
                        </Input>
                    </div>
                </div>
                <div className="row compareRoleRow">
                    <div className="col-4">
                        {leftRoleName && this.generateModuleMap(leftRoleSelectedPermssions[0])}
                    </div>
                    <div className="col-2">

                    </div>
                    <div className="col-4" >
                        {rightRoleName && this.generateModuleMap(rightRoleSelectedPermssions[0])}
                    </div></div>
            </div>
        );
    }
    componentDidMount() {
        this.props.setLoader({ loader: { open: true } });
        this.props.getRoleList();
        this.getFeatures();
    }
    componentWillReceiveProps(newporps) {
        if (newporps && newporps.roleList) {
            this.props.setLoader({ loader: { open: false } });
            this.setState({ roles: newporps.roleList.roles, roleName: Object.keys(newporps.roleList.roles) })
        }
    }

}

const Table = ({ module, functions, permissions }) => {
    let selectedPermission = permissions[module];
    return (
        <>
            <p className='sub-heading m-top-30'>{splitCaseStr(module)}</p>
            <table className="tabledashboard table table-striped table-advance">
                <tbody>
                    {functions.map((item, i) => {

                        if (!item.menu) return false;

                        return <tr key={i} className="pointer-default" style={{ height:'5px' }}>
                            <td style={{ width:'20%' }}>
                                <FormGroup className="error-field">
                                    <div className="checkbox-group">
                                        <label>
                                            <input type="checkbox" disabled={true} name={item.function} id={item.function} checked={selectedPermission && selectedPermission.indexOf(item.function) > -1} />
                                            <span className='checkmark'></span></label>
                                    </div>
                                </FormGroup>
                            </td>
                            <td >{item.function}</td>

                        </tr>;
                    })}
                </tbody>
            </table>
        </>
    );
}

const mapDispatchToProps = { setConfirmBox, setLoader, setToastr, getRoleList, SET_ROLES_LIST }

const mapStateToProps = state => {
    return ({
        roleList: state.RoleList
    });
}


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CompareRoleComponent));