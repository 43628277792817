import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { withRouter } from 'react-router-dom';
import { Row, Col, FormGroup, Label } from 'reactstrap';
import { connect } from 'react-redux';
import InputField from '../../shared/InputField';
import SearchReportForm from '../../../forms/SearchReportForm';
import { getAlertSetting, saveAlertSetting, testNotification } from "../../../api/alertSetting";
import { displayAlertSettingData, saveAlertData, setSarObj } from "../../../actions/AlertSetting";
import { setToastr, setLoader } from "../../../actions/Application";
import MapComponent from '../../shared/MapComponent';
import ButtonField from '../../shared/ButtonField';
import moment from "moment";
import { translate } from '../../../lang';
import { UserActivity } from "../../../api/header.js";


/*global google*/
class AlertSettingComponent extends Component {

	constructor(props) {
		super(props);
		this.state = {
			numberPlateOption: {},
			data: {},
			fromDate: null,
			toDate: null,
			getMachineData: false,
			alertData: [],
			errorFlag: false,
			theftFlag: false,
			batteryAlertFlag: false,
			towingAlertFlag: false,
			geofenceCordinate: [],
			operationStartTime: null,
			operationEndTime: null,
			markers: [],
			markrs: [],
			drawingMode: null,
			drawingControl: true,
			center: { lat: 23.63936, lng: 68.14712 },
			readOnly: true,
			vertices: null,
			isSetTime: false,
			showLoader: false,
			explicit: true,
			radius: 0,
			group: null,
			hiddenArea: false,
			fromPage:'',
            errorAPI: false,
		}

		this.notify = this.notify.bind(this);
	}

	componentWillReceiveProps(nextProps) {
		if (this.props !== nextProps) {
			this.setState({ showLoader: false })
		}

		if (nextProps.sarData && nextProps.sarData.lat && nextProps.sarData.lng) {
			let sar = nextProps.sarData;
			this.setState({
				markrs: (sar && sar.lat && sar.lng) ? [{ lat: parseFloat(sar.lat), lng: parseFloat(sar.lng) }] : this.state.markrs
			})
		} else {
			this.setState({
				markrs: []
			});
		}

		if (this.props !== nextProps && nextProps.alertData) {
			let { geofence } = nextProps.alertData;
			geofence = geofence[0];

			this.setState({
				alertData: geofence,
				geofenceCordinate: geofence && geofence.geofenceCordinate && geofence.geofenceCordinate.trim() != 'null' && JSON.parse(geofence.geofenceCordinate).coords,
				radius: geofence && geofence.geofenceCordinate && geofence.geofenceCordinate.trim() != 'null' && JSON.parse(geofence.geofenceCordinate).radius,
				operationStartTime: geofence && geofence.operationStartTime,
				operationEndTime: geofence && geofence.operationEndTime,
				errorFlag: geofence && geofence.errorFlag,
				theftFlag: geofence && geofence.theftFlag,
				batteryAlertFlag: geofence && geofence.batteryAlertFlag,
				towingAlertFlag: geofence && geofence.towingAlertFlag,
				operationStartTimeHour: !isNaN(this.getHours(geofence.operationStartTime)) ? this.getHours(geofence.operationStartTime) : "00",
				operationEndTimeHour: !isNaN(this.getHours(geofence.operationEndTime)) ? this.getHours(geofence.operationEndTime) : "00",
				operationStartTimeMinute: !isNaN(this.getMinutes(geofence.operationStartTime)) ? this.getMinutes(geofence.operationStartTime) : "00",
				operationEndTimeMinute: !isNaN(this.getMinutes(geofence.operationEndTime)) ? this.getMinutes(geofence.operationEndTime) : "00",
				operationStartTimeSuffix: this.getTimeSuffix(geofence.operationStartTime),
				operationEndTimeSuffix: this.getTimeSuffix(geofence.operationEndTime),
				showLoader: false
			});

		}
		
		if (this.props.apiError !== nextProps.apiError) {
			if (nextProps.apiError !== false) {
				this.setState({
					errorAPI: true,
					showLoader: false
				})
				if (this.props.profile.userActivityFlag == true) {
					const { location } = this.props;
					const toUrl = location.pathname;
					const fromUrl = (this.props.location && this.props.location.state && this.props.location.state.prevPath) || 'No path';
					this.trackUserActivity('Failed',(`Failed navigation from ${fromUrl} to ${toUrl}`));
				}
			}
		}
	}


	selectChange(type, data, explicit = false) {
		this.setState({ explicit })
		if (data === undefined) {
			data = type.target.value;
			type = type.target.name;
		}

		if (explicit) {
			if (document.getElementsByTagName('select')[0].getAttribute('name') === "machineSaleId") {
				data = document.getElementsByTagName('select')[0].value
				this.setState({
					data: {
						...this.state.data, ['machineSaleId']: data
					}
				})
			}
		}
		this.setState({ isSetTime: false })

		if (!data) return false;
		this.props.setLoader({ loader: { open: true } });

		if (data === "null") {
			this.setState({ getMachineData: false });
			this.props.setLoader({ loader: { open: false } });
		} else {
			let equipmentsData = JSON.parse(localStorage.getItem('equipmentData')).equipmentData;
			let equipment = equipmentsData.filter(equipment => equipment.machineSaleId == data);
			let hiddenArea = moment(new Date()).isAfter(equipment[0].contractExpiry);
			this.setState({
				group: equipment[0].status,
				hiddenArea: hiddenArea
			});
			if (hiddenArea) {
				this.props.setLoader({ loader: { open: false } });
			} else {
				getAlertSetting(data)
				.then(result => {
					if (result.data.geofence && result.data.geofence[0]) {
						let alertData = result.data;

						this.props.displayAlertSettingData(alertData);
						if (result.data.sar && result.data.sar.latitude && result.data.sar.longitude) {
							this.props.setSarObj({
								lat: result.data.sar.latitude,
								lng: result.data.sar.longitude
							});
						} else {
							this.props.setSarObj({
								lat: undefined,
								lng: undefined
							});
						}

						this.setState({
							getMachineData: true,
							data: {
								...this.state.data, [type]: data
							}
						}, () => {
							if (alertData.geofence[0] && alertData.geofence[0].operationStartTime !== null && alertData.geofence[0].operationStartTime !== null) {
								document.getElementById('operating-time').checked = true;
								this.setState({ isSetTime: true })
							}
						})
					} else {
						if (result.data.sar && result.data.sar.latitude && result.data.sar.longitude) {
							this.props.setSarObj({
								lat: result.data.sar.latitude,
								lng: result.data.sar.longitude
							});
						}

						this.setState({
							getMachineData: true,
							data: {
								...this.state.data, [type]: data
							},
							showLoader: false
						})
					}

					this.props.setLoader({ loader: { open: false } });
				})
				.catch(error => {
					this.props.setLoader({ loader: { open: false } });
				});
			}
		}
	}

	getNumberPlate() {
		let equipmentData = JSON.parse(localStorage.getItem('equipmentData'));
		if (equipmentData) {
			//Number Plate Option
			let numberPlate = equipmentData.equipmentData.map(function (item, index) {
				if (item.status == 'TE' && item.numberPlate != null)
					return <option key={index} value={item.machineSaleId}> {(item.numberPlate ? item.numberPlate.toUpperCase() : " ")} </option>;
			});
			//Model Machine Number
			let modelMachine = equipmentData.equipmentData.map(function (item, index) {
				return <option key={index} value={item.machineSaleId}> {item.model}/{item.machineNum} </option>;
			});
			return [numberPlate, modelMachine, equipmentData];
		}
		return [];

	}

	componentDidMount() {
		let { machineSaleId } = this.props.match.params;
		if (machineSaleId) {
			let f = document.getElementById("u2");
			if (f && f.dataset['url'] && f.dataset['url'] === '/customer/equipment/equipmentDetail/:machineSaleId') {
				f.dataset['url'] = `/customer/equipment/equipmentDetail/${machineSaleId}`;
			}
		}

		this.setState({
			numberPlateOption: this.getNumberPlate(),
		})
		if (this.props.alertSettingVal && this.props.alertSettingVal !== undefined && this.state.explicit) {
			this.selectChange('', '', true);
		}
		let equipmentData = JSON.parse(localStorage.getItem('equipmentData'));
		if (equipmentData) {
			if(this.props.profile.userActivityFlag == true){
				const {location} = this.props;
				const toUrl = location.pathname; 
				const fromUrl = (this.props.location && this.props.location.state && this.props.location.state.prevPath) || 'No path';
				this.trackUserActivity('Success',(`Successfully navigated from ${fromUrl} to ${toUrl}`));
			}
		}
	}

	getHours(dStr) {
		dStr = moment(dStr, 'hh:mm A').hours();
		if (dStr > 12) {
			dStr = dStr - 12;
		}
		return dStr;
	}
	getMinutes(dStr) {
		dStr = moment(dStr, 'hh:mm A').minutes();
		return dStr === 0 ? "00" : dStr;
	}
	getTimeSuffix(dStr, AM, PM) {
		dStr = moment(dStr, 'hh:mm A').hours();
		if (dStr >= 12) {
			PM = 'PM';
			return PM;
		}
		else {
			AM = 'AM';
			return AM;
		}
	}

	getCoordinates = (vertices) => {
		let coords = [], xy, lat, lng;
		for (let i = 0; i < vertices.getLength(); i++) {
			xy = vertices.getAt(i);
			lat = xy.lat();
			lng = xy.lng();
			coords.push({ 'lat': lat, 'lng': lng });
		}
		return coords;
	}

	notify() {
		let { id } = this.props.profile, { errorFlag, theftFlag, batteryAlertFlag, towingAlertFlag } = this.state;


		if (!errorFlag && !theftFlag && !batteryAlertFlag && !towingAlertFlag) {
			this.props.setToastr({ toastr: { message: translate('eitherCheckboxChecked'), type: "info" } });
			return false;
		}

		this.props.setLoader({ loader: { open: true } });

		testNotification({ id, errorFlag, theftFlag, batteryAlertFlag, towingAlertFlag }).then(r => {
			if (r.data && r.data != "" && r.data.split(",").length === 2) {
				this.props.setToastr({ toastr: { message: translate('testNotificationSentBoth'), type: "success" } });
			} else if (r.data && r.data === 'email') {
				this.props.setToastr({ toastr: { message: translate('testNotificationSentEmail'), type: "success" } });
			} else if (r.data && r.data === 'sms') {
				this.props.setToastr({ toastr: { message: translate('testNotificationSentSms'), type: "success" } });
			} else {
				this.props.setToastr({ toastr: { message: translate('testNotificationError'), type: "info" } });
			}
			this.props.setLoader({ loader: { open: false } });
		}).catch(e => {
			this.props.setToastr({ toastr: { message: e.toString(), type: "error" } });
			this.props.setLoader({ loader: { open: false } });
		})
	}

	concatTime(hour, minute, suffix, key, time) {
		if (key === 'operationStartTime') {
			hour = this.state.operationStartTimeHour;
			minute = this.state.operationStartTimeMinute;
			suffix = this.state.operationStartTimeSuffix;
			time = `${hour}:${minute} ${suffix}`;
			return time;
		}
		else if (key === 'operationEndTime') {
			hour = this.state.operationEndTimeHour;
			minute = this.state.operationEndTimeMinute;
			suffix = this.state.operationEndTimeSuffix;
			time = `${hour}:${minute} ${suffix}`;
			return time;
		}
	}
	saveSetting(machineSaleId, data, geofenceCordinate, operationStartTime, operationEndTime, errorFlag, theftFlag, batteryAlertFlag, towingAlertFlag) {
		machineSaleId = this.state.data.machineSaleId;
		let error, msg = false;
		let isbefore = moment(`20 Jan 2019 ${this.state.operationStartTime}`).isAfter(moment(`20 Jan 2019 ${this.state.operationEndTime}`));
		if (this.state.isSetTime) {

			if (this.state.operationStartTimeHour === "" || isNaN(this.state.operationStartTimeHour) || this.state.operationStartTimeHour === undefined) {
				error = true;
				document.getElementById('operationStartTimeHour').className = " border-danger"
				msg = translate('fillStartTimeHour')
			} else if (this.state.operationStartTimeMinute === "" || isNaN(this.state.operationStartTimeMinute) || this.state.operationStartTimeMinute === undefined) {
				error = true;
				document.getElementById('operationStartTimeMinute').className = " border-danger"
				document.getElementById('operationStartTimeHour').className = ""
				msg = translate('fillStartTimeMinute')
			} else if (this.state.operationEndTimeHour === "" || isNaN(this.state.operationEndTimeHour) || this.state.operationEndTimeHour === undefined) {
				error = true;
				document.getElementById('operationStartTimeMinute').className = ""
				document.getElementById('operationEndTimeHour').className = " border-danger"
				msg = translate('fillEndTimeHour')
			} else if (this.state.operationEndTimeMinute === "" || isNaN(this.state.operationEndTimeMinute) || this.state.operationEndTimeMinute === undefined) {
				error = true;
				document.getElementById('operationEndTimeHour').className = ""
				document.getElementById('operationEndTimeMinute').className = " border-danger"
				msg = translate('fillEndTimeMinute')
			} else if (this.state.operationStartTime === this.state.operationEndTime) {
				error = true;
				msg = translate('startEndTimeNotSame')
			} else {
				if (isbefore) {
					this.props.setToastr({ toastr: { message: translate('startTimeCantLessThanEndTime'), type: "info" } });
					return true;
				} else {
					document.getElementById('operationEndTimeMinute').className = ""
					document.getElementById('operationStartTimeHour').className = ""
					document.getElementById('operationStartTimeMinute').className = ""
					document.getElementById('operationEndTimeHour').className = ""
				}
			}
		}

		if (error) {
			this.props.setToastr({ toastr: { message: msg, type: "info" } });
			return true;
		}

		geofenceCordinate = this.state.geofenceCordinate;
		operationStartTime = this.state.operationStartTime;
		operationEndTime = this.state.operationEndTime;
		errorFlag = this.state.errorFlag;
		theftFlag = this.state.theftFlag;
		batteryAlertFlag = this.state.batteryAlertFlag;
		towingAlertFlag = this.state.towingAlertFlag;
		data = {
			geofenceCordinate: JSON.stringify(
				{
					coords: geofenceCordinate,
					radius: (geofenceCordinate !== null && geofenceCordinate.length) > 1 ? 0 : this.state.radius
				}
			), operationStartTime, operationEndTime, errorFlag, theftFlag, batteryAlertFlag, towingAlertFlag
		}


		this.props.setLoader({ loader: { open: true } });

		saveAlertSetting(machineSaleId, data)
			.then(result => {
				this.props.setToastr({ toastr: { message: translate('alertSettingUpdated'), type: "success" } });
				this.props.setLoader({ loader: { open: false } });
			})
			.catch(error => {
				this.props.setToastr({ toastr: { message: translate('errorOccured'), type: "error" } });
				this.props.setLoader({ loader: { open: false } });
			})
	}

	catchVertices(data, polygon, center, radius = null) {
		if (polygon.type === 'polygon') {
			this.setState({
				geofenceCordinate: this.getCoordinates(data),
				center: this.getCoordinates(data)[0]
			})
		}
		else if (polygon.type === 'rectangle') {
			this.setState({
				geofenceCordinate: data,
				center: center
			})
		}
		else if (polygon.type === 'circle') {
			this.setState({
				geofenceCordinate: data,
				center: center,
				radius
			})
		}
	}

	handleChange(event) {
		let startTimeHour = ReactDOM.findDOMNode(document.getElementById("operationStartTimeHour")).value,
			startTimeMin = ReactDOM.findDOMNode(document.getElementById("operationStartTimeMinute")).value,
			startTimeampm = ReactDOM.findDOMNode(document.getElementById("operationStartTimeSuffix")).value,
			endTimeHour = ReactDOM.findDOMNode(document.getElementById("operationEndTimeHour")).value,
			endTimeMin = ReactDOM.findDOMNode(document.getElementById("operationEndTimeMinute")).value,
			endTimeampm = ReactDOM.findDOMNode(document.getElementById("operationEndTimeSuffix")).value;

		startTimeHour = (startTimeHour > 12) ? 12 : (+startTimeHour === 0 ? 1 : startTimeHour);
		startTimeMin = (startTimeMin > 59) ? 59 : (+startTimeMin === 0 ? "00" : startTimeMin);
		endTimeHour = (endTimeHour > 12) ? 12 : (+endTimeHour === 0 ? 1 : endTimeHour);
		endTimeMin = (endTimeMin > 59) ? 59 : (+endTimeMin === 0 ? "00" : endTimeMin);

		let operationStartTime = startTimeHour + ":" + startTimeMin + " " + startTimeampm,
			operationEndTime = endTimeHour + ":" + endTimeMin + " " + endTimeampm;
		this.setState({ operationStartTime, operationEndTime });

		if ((event.target.name === 'operationStartTimeHour' || event.target.name === 'operationEndTimeHour')) {
			if (event.target.value <= 12) {
				this.setState({ [event.target.name]: (+event.target.value === 0) ? 1 : +Math.abs(event.target.value) })
			}
		} else if ((event.target.name === 'operationStartTimeMinute' || event.target.name === 'operationEndTimeMinute')) {
			if (event.target.value <= 59) {
				this.setState({ [event.target.name]: +Math.abs(event.target.value) })
			}
		} else {
			this.setState({ [event.target.name]: event.target.value })
		}

	}


	handleChange2(event, time) {
		event.preventDefault();
		if (event.target.name === 'operationStartTimeHour' || event.target.name === 'operationStartTimeMinute' || event.target.name === 'operationStartTimeSuffix') {

			this.setState({
				operationStartTime: this.concatTime(this.state.operationStartTimeHour, this.state.operationStartTimeMinute, this.operationStartTimeSuffix, 'operationStartTime')
			})
		}
		else if (event.target.name === 'operationEndTimeHour' || event.target.name === 'operationEndTimeMinute' || event.target.name === 'operationEndTimeSuffix') {

			this.setState({
				operationEndTime: this.concatTime(this.state.operationEndTimeHour, this.state.operationEndTimeMinute, this.operationEndTimeSuffix, 'operationEndTime')
			})
		}
		this.setState({ [event.target.name]: event.target.value })
	}

	clickCheckBox(e) {
		const item = e.target.name;
		this.setState({ [item]: e.target.checked })
	}

	setOperatingTime = (e) => {
		if (e.target.checked) {
			this.setState({
				isSetTime: e.target.checked
			})
		}
		else {
			this.setState({
				isSetTime: false,
				operationStartTime: null,
				operationEndTime: null,
				operationStartTimeHour: 1,
				operationEndTimeHour: 1,
				operationStartTimeMinute: 0,
				operationEndTimeMinute: 0,
				operationStartTimeSuffix: 'AM',
				operationEndTimeSuffix: 'AM'
			})
		}
	}

	resetForm() {
		let machineSaleId = (this.props.match && this.props.match.params && this.props.match.params.machineSaleId) || undefined;

		if (machineSaleId) {
			this.props.history.push(`/customer/equipment/equipmentDetail/${machineSaleId}`);
		} else {
			document.getElementById('operating-time').checked = false;
			this.setState({
				errorFlag: false,
				theftFlag: false,
				batteryAlertFlag: false,
				towingAlertFlag: false,
				geofenceCordinate: null,
				operationStartTime: null,
				operationEndTime: null,
				operationStartTimeHour: 1,
				operationEndTimeHour: 1,
				operationStartTimeMinute: 0,
				operationEndTimeMinute: 0,
				operationStartTimeSuffix: 'AM',
				operationEndTimeSuffix: 'AM',
				isSetTime: false
			})
		}
	}

	trackUserActivity(pageActivity,remarks) {
        const {location} = this.props;
        const fromUrl = (this.props.location && this.props.location.state && this.props.location.state.prevPath) || 'No path';
        const toUrlPath = location.pathname.split('/customer/')[1] || '';
        let fromUrlPath = fromUrl ? fromUrl.split('/customer/')[1]:'';
        const pathNames = {
            "manage-daily-page": "Manage Daily Work",
            "equipment": "My Equipments",
            "location-information": "Location Information",
            "alert-setting": "Alert Setting",
            "reports": "Manage Reports",
            "service-history": "Equipment Service History",
            "area-measurement": "Area Measurement",
            "members" : "My Members",
            "member_management": "Member Management",
            "my-account": "My Account"
            // Add other mappings here as needed
        };
        const convertToTitleCase = (str) => {
            if(!str){
                return '';
            }
            
            return str
                .replace(/-/g, ' ') // Replace hyphens with spaces
                .replace(/\b\w/g, char => char.toUpperCase()); // Capitalize first letter of each word
        };

        let defaultFromPage = fromUrl === '/customer' ? 'Login' : '';
        let defaultToPage = location.pathname === '/customer' ? 'Login' : '';
        let fromUrlName = pathNames[fromUrlPath] || convertToTitleCase(fromUrlPath) || defaultFromPage;
        let toUrlName = pathNames[toUrlPath] || convertToTitleCase(toUrlPath) || defaultToPage;

        let userActivityData = {
            userID: this.props.profile.id,
            userName: this.props.profile.firstName,
            sourceType: 'web',
            fromPage: fromUrlName,
            toPage: toUrlName,
            pageActivity: pageActivity,
            remarks: remarks
        }
        UserActivity(userActivityData)
            .then(result => {
                if (result.statusCode == 200) {
                    this.setState({fromPage:fromUrl});
                }
            })
            .catch(error => {
                console.error('Error in user activity API', error);
            })
    }

	render() {
		const { alertSettingVal } = this.props, { machineSaleId } = this.props.match.params;

		let alertSettingValue = (this.state.explicit) ? alertSettingVal : undefined;

		let mins = new Array(60), hrs = [12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
		mins.fill(0);

		return (
			<section className="c-alert-setting">
				<section className="conatiner">
					<Row className="c-alert-setting-search-equiment">
						<Col xs="12" lg="12">
							<SearchReportForm
								numberPlateOption={this.getNumberPlate()}
								handleChange={this.selectChange.bind(this)}
								fromDate={this.state.fromDate}
								alertSettingVal={alertSettingValue}
								toDate={this.state.toDate}
								machineSaleId={machineSaleId}
								translate={translate}
							/>
						</Col>
						{this.state.getMachineData && !this.state.hiddenArea ?
							<div className="setting">
								<Col lg="12">
									<FormGroup>
										<Label for="operating-time">{translate('operatingTime')}</Label>
									</FormGroup>
									<FormGroup>
										<div className="checkbox-group">
											<Label for="operating-time">
												<input type="checkbox" checked={this.state.isSetTime} name="operating-time" id="operating-time" onChange={this.setOperatingTime.bind(this)} />
												<span className='checkmark'></span>
											</Label>
										</div>
									</FormGroup>
									{this.state.isSetTime ?
										<section>
											<FormGroup className="col-sm-12 col-xs-12">
												<Label for="operationStartTimeHour">{translate('startTime')}</Label>

												{/* <InputField type="number" className='dtfld' min={0} max={23} step="1" id="operationStartTimeHour" name="operationStartTimeHour" value={this.state.operationStartTimeHour} handleChange={this.handleChange.bind(this)} /> */}

												<select id="operationStartTimeHour" name="operationStartTimeHour" onChange={this.handleChange.bind(this)} value={this.state.operationStartTimeHour}>
													{hrs.map((k, i) => <option key={i} value={k}>{k}</option>)}
												</select>

												<span>:</span>

												{/* <InputField type="number" className='dtfld' min="0" max="23" step="1" id="operationStartTimeMinute" name="operationStartTimeMinute" value={this.state.operationStartTimeMinute} handleChange={this.handleChange.bind(this)} /> */}

												<select id="operationStartTimeMinute" name="operationStartTimeMinute" onChange={this.handleChange.bind(this)} value={this.state.operationStartTimeMinute}>
													{mins.map((k, i) => <option key={i} value={i}>{i.toString().length === 1 ? "0" + i : i}</option>)}
												</select>

												<select id="operationStartTimeSuffix" name="operationStartTimeSuffix" onChange={this.handleChange.bind(this)} className='ampm' value={this.state.operationStartTimeSuffix}>
													<option value="AM">AM</option>
													<option value="PM">PM</option>
												</select>

												<InputField type="hidden" id="operationStartTime" name="operationStartTime" value={this.state.operationStartTime !== null ? this.state.operationStartTime : ''} />
											</FormGroup>
											<FormGroup className='col-sm-12 col-xs-12'>
												<Label for="operationEndTimeHour">
													{translate('endTime')}
												</Label>

												{/* <InputField type="number" className='dtfld' min="1" max="23" step="1" id="operationEndTimeHour" name="operationEndTimeHour" value={this.state.operationEndTimeHour} handleChange={this.handleChange.bind(this)} /> */}

												<select id="operationEndTimeHour" name="operationEndTimeHour" onChange={this.handleChange.bind(this)} value={this.state.operationEndTimeHour}>
													{hrs.map((k, i) => <option key={i} value={k}>{k}</option>)}
												</select>


												<span>:</span>

												<select id="operationEndTimeMinute" name="operationEndTimeMinute" onChange={this.handleChange.bind(this)} value={this.state.operationEndTimeMinute}>
													{mins.map((k, i) => <option key={i} value={i}>{i.toString().length === 1 ? "0" + i : i}</option>)}
												</select>

												{/* <InputField type="number" className='dtfld' min="0" max="23" step="1" id="operationEndTimeMinute" name="operationEndTimeMinute" value={this.state.operationEndTimeMinute} handleChange={this.handleChange.bind(this)} /> */}

												<select id="operationEndTimeSuffix" name="operationEndTimeSuffix" className='ampm' defaultValue={this.getTimeSuffix(this.state.operationEndTime)} onChange={this.handleChange.bind(this)}>
													<option value="AM">AM</option>
													<option value="PM">PM</option>
												</select>
												<InputField type="hidden" id="operationEndTime" name="operationEndTime" value={this.state.operationEndTime !== null ? this.state.operationEndTime : ''} />
											</FormGroup>
										</section>
										: null
									}
								</Col>
								<Col lg="12">
									<FormGroup>
										<Label>{translate('notifyMe')}</Label>
									</FormGroup>
									<FormGroup className="error-field">
										<div className="checkbox-group">
											<Label for="errorFlag">{translate('technicalSystemError')}
												<input type="checkbox" onChange={this.clickCheckBox.bind(this)} name="errorFlag" id="errorFlag" checked={this.state.errorFlag} />
												<span className='checkmark'></span>
											</Label>
										</div>
									</FormGroup>
									<FormGroup className="error-field margin">
										<div className="checkbox-group">
											<Label for="theftFlag">{translate('theft')}
												<input type="checkbox" onChange={this.clickCheckBox.bind(this)} name="theftFlag" id="theftFlag" checked={this.state.theftFlag} />
												<span className='checkmark'></span>
											</Label>
										</div>
									</FormGroup>
									{this.state.group &&
										this.state.group != 'CH' &&
										<FormGroup className="error-field margin">
											<div className="checkbox-group">
												<Label for="batteryAlertFlag">{translate('noPositionInformation')}
													<input type="checkbox" onChange={this.clickCheckBox.bind(this)} name="batteryAlertFlag" id="batteryAlertFlag" checked={this.state.batteryAlertFlag} />
													<span className='checkmark'></span>
												</Label>
											</div>
										</FormGroup>}
									{this.state.group &&
										this.state.group == 'TE' &&
										<FormGroup className="error-field margin">

											<div className="checkbox-group">
												<Label for="towingAlertFlag">{translate('machineTow')}
													<input type="checkbox" onChange={this.clickCheckBox.bind(this)} name="towingAlertFlag" id="towingAlertFlag" checked={this.state.towingAlertFlag} />
													<span className='checkmark'></span>
												</Label>
											</div>
										</FormGroup>}
								</Col>
								<Col xs="12" className="geofencing">
									<h3 className="header-text xs-hidden">{translate('geofencing')}</h3>
									<MapComponent
										t='alert'
										handleVertices={this.catchVertices.bind(this)}
										markers={this.state.markers}
										markrs={this.state.markrs}
										drawingMode={this.state.drawingMode} center={this.state.center} radius={this.state.radius} drawingControl={this.state.drawingControl} coords={this.state.geofenceCordinate} />
								</Col>
								<Col xs="12" className="setting-cta">
									<ButtonField
										type="button"
										name="testNotification"
										id="testNotification"
										className="btn-primary btn-test-notification"
										buttonText={translate('testNotification')}
										clickEvent={true}
										handleClick={() => this.notify()}
									/>

									<ButtonField
										type="button"
										name="clear"
										id="clear"
										className="btn-danger btn-clear"
										buttonText={translate('cancel')}
										clickEvent={true}
										handleClick={this.resetForm.bind(this)}
									/>
									<ButtonField
										type="button"
										name="saveSetting"
										id="saveSetting"
										className="btn-primary btn-save-setting"
										buttonText={translate('save')}
										clickEvent={true}
										handleClick={this.saveSetting.bind(this)}
									/>
								</Col>
							</div> : null
						}
						{this.state.hiddenArea && <div>
							<Col xs="12" className="errorMessage" align='center'>{translate('This_Page_cannot_be_displayed_as_contract_has_expired_for_selected_machine_Kindly_contact_your_service_provider')}.
							</Col></div>}
					</Row>
				</section>
			</section>
		)
	}
}
const mapStatToProps = state => {
	return ({
		alertData: state.alertSetting.displayAlertSettingData || undefined,
		sarData: state.alertSetting.displayAlertSarData || undefined,
		profile: state.profile,
		apiError : state.equipment.FETCH_DATA_FAIL || false,
	})
}
const mapDispatchToProps = { displayAlertSettingData, saveAlertData, setToastr, setLoader, setSarObj }
export default connect(mapStatToProps, mapDispatchToProps)(withRouter(AlertSettingComponent))
