import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { Row, Col } from "reactstrap";
import { FaPlusCircle } from "react-icons/fa";
import { Field, reduxForm } from "redux-form";
import { required, email, max10, min10, min3, max6, min6, onlyNumbers } from "../../shared/Validation";
import { renderTextField, FileInput, renderSelectField } from "../../shared/renderField";
import { addMember, validateMember } from "../../../api/members";
import { setToastr, setLoader } from "../../../actions/Application";
import { getMembers } from "../../../actions/Member";
import { updateProfile } from "../../../api/profile";

const asyncValidate = (values /*, dispatch */) => {
    if(!values.loginId || values.loginId.trim() === "") return false;
    return validateMember(values.loginId).then(result => {
        if (typeof result.data === 'boolean' && result.data === true ) throw { loginId: this.props.translate('memberIdTaken') }
    })
}

class AddMemberComponent extends Component{
    constructor(props){
        super(props);
        /**  */
        this.state = {
            type: "add",
            formset: false,
            selectedMember: undefined
        }
        /** function binding */
        this.addNewMember = this.addNewMember.bind(this);
    }

    componentDidMount(){
        let { memberId } = this.props.match.params;
        
        /** update state */
        if (memberId) {
            /** load members data */
            this.props.getMembers();
            /** udpate  state to edit*/
            this.setState({ type: "edit" });
        }
    }

    componentWillReceiveProps(newProps){    
        let { memberId } = this.props.match.params;
        let { initialize } = this.props;
        let { formset } = this.state;

        if (memberId && newProps.members && newProps.members.length && !formset){

            let selectedMember = this.getSelectedMember(newProps.members, memberId);

            if (selectedMember){
                /** if we got the member data then initialize into form */
                initialize(selectedMember);
                this.setState({ formset: true, selectedMember });
                
            }else{
                /** set type to add */
                this.setState({ type: "add" });
                this.props.history.push('/customer/members/add');
            }
        }

        if(newProps.isOwner && newProps.isOwner === 'false'){
            this.props.history.push('/customer/members');
        }
    }

    getSelectedMember(members, memberId){
        return members.filter(member => member.id===parseInt(memberId))[0];
    }

    getFullName(member){   
        return [member.firstName, member.middleName, member.lastName].join(" ");
    }

    addNewMember(values){
        let formdata = {...values, languageCode: "en", timezone:"Asia/Kolkata", ownerFlag: false}, { type } = this.state;
        delete formdata.country;

        /** on loader */
        this.props.setLoader({ loader: { open: true } });
      
        if (type === 'edit'){
            updateProfile(formdata)
            .then(result => {
                /** show success notificaiton */
                this.props.setToastr({ toastr: { message: (formdata.firstName + " " + formdata.lastName)+""+this.props.translate('updateSuccess'), type: "success" } });
                /** off loader */
                this.props.setLoader({loader:{open: false}});
                /** take user to back page */
                this.props.history.push('/customer/members');
            })
            .catch(error => {
                /** off loader */
                this.props.setLoader({loader:{open: false}});

                this.props.setToastr({ toastr: { message: error || this.props.translate('encounteredProblem'), type: "error" } });
            });
        }else{
            /** add user otherwise */
            addMember(formdata)
            .then(result => {
                /** show success notificaiton */
                setTimeout(() => {
                    this.props.setToastr({ toastr: { message: this.props.translate('newMemberAddedSuccess'), type: "success" } });
                }, 0);
                /** off loader */
                this.props.setLoader({loader:{open: false}});
                /** take user to back page */
                this.props.history.push('/customer/members');
            })
            .catch(error => {
                /** off loader */
                this.props.setLoader({loader:{open: false}});
                this.props.setToastr({ toastr: { message: error || this.props.translate('encounteredProblem') , type: "error" } });
            });
        }
        
    }

    render(){
        const { handleSubmit, submitting, states ,translate} = this.props;
        const { type, selectedMember } = this.state;

        return(
            <Fragment>
                <form onSubmit={handleSubmit(this.addNewMember)} autoComplete='off' className='form-horizontal'>
                <Row className="members align-items-center">    
                    <Col md='8'>
                        <div className="row profile-asset">
                            <div className="col-sm-12">{translate('userID')} <span className='required'>*</span>
                                <Field name="loginId" component={renderTextField} className='form-control' type="text" validate={[required]} disabled={type === 'edit' ? true : false} readOnly={type === 'edit' ? true : false} />
                            </div>
                        </div>

                        <div className="row profile-asset">
                            <div className="col-sm-6">{translate('firstName')}  <span className='required'>*</span>
                                <Field name="firstName" component={renderTextField} className='form-control' type="text" validate={[required]} maxLength={20} />
                            </div>
                            <div className="col-sm-6">{translate('lastName')}<span className='required'>*</span>
                                <Field name="lastName" component={renderTextField} className='form-control' type="text" validate={[required]} maxLength={20}/>
                            </div>
                        </div>


                        <div className="row profile-asset">
                            <div className="col-sm-6">{translate('mobileNumber')} <span className='required'>*</span>
                                <Field name="phone" component={renderTextField} className='form-control' type="text" validate={[required, min10, max10, onlyNumbers]} />
                            </div>

                            <div className="col-sm-6">{translate('alternateMobileNumber')}<span className='required'>*</span>
                        <Field name="alternatePhone" component={renderTextField} className='form-control' type="text" validate={[required, min10, max10, onlyNumbers]} />
                            </div>
                        </div>


                        <div className="row profile-asset">
                            <div className="col-sm-12">{translate('emailAddress')} 
                                <Field name="email" component={renderTextField} className='form-control' type="text" validate={[email]} maxLength={50}/>
                            </div>
                        </div>

                        <div className="row profile-asset">
                            <div className="col-sm-12">{translate('streetAddress')} <span className='required'>*</span>
                                <Field name="address" component={renderTextField} className='form-control' type="text" validate={[required]} maxLength={150}/>
                            </div>
                        </div>

                        <div className="row profile-asset">
                            <div className="col-sm-9">{translate('city')} <span className='required'>*</span>
                                <Field name="city" component={renderTextField} className='form-control' type="text" validate={[required]} maxLength={20}/>
                            </div>

                            <div className="col-sm-3">{translate('pin')} <span className='required'>*</span>
                                <Field name="zipCode" component={renderTextField} className='form-control' type="text" validate={[required, min6, max6, onlyNumbers]} />
                            </div>
                        </div>

                        <div className="row profile-asset">
                            <div className="col-sm-12">{translate('state')} <span className='required'>*</span>
                                <Field name="stateCode" component={renderSelectField} className='form-control no-padded' validate={[required]}>
                                    <option value="">{translate('selectState')}</option>
                                    {states.map(state => <option key={state.stateCode} value={state.stateCode}>{state.state}</option>)}
                                </Field>
                            </div>
                        </div>

                        <div className="row profile-asset">
                            <div className="col-sm-12">{translate('country')} <span className='required'>*</span>
                                <Field name="country" component={renderSelectField} className='form-control no-padded'>
                                    <option value="99">{translate('india')}</option>
                                </Field>
                            </div>
                        </div>

                        <div className="row profile-asset">
                            <div className="col-sm-12">
                                <button className="btn btn-primary" disabled={ submitting }>{translate('add')}</button> &nbsp;

                                <button type='button' className="btn btn-danger" onClick={() => this.props.history.goBack()}>{translate('cancel')}</button> 
                            </div>
                        </div>

                    </Col>

                    <Col md='4' className='d-flex justify-content-center'>
                        <Col sm='9' className='d-flex member-image-upload-area justify-content-center'>
                            <div className='image-uploader text-center'>
                                {selectedMember && selectedMember.photo ? <img src={selectedMember.photo} className='rounded-circle' alt="profilepic"/> : <FaPlusCircle size="10em" />}
                                <Field name="photo" component={FileInput} className='form-control' type="file" accept='image/*'/>
                                <p className='text-center'>{translate('memberPhoto')}</p>
                            </div>
                        </Col>
                    </Col> 
                </Row>
                </form>
            </Fragment>
        );
    }
}

const addMemberForm = reduxForm({
    form: "addEditMemberForm",
    asyncValidate,
    asyncBlurFields: ['loginId']
})(withRouter(AddMemberComponent));

const mapStateToProps = state => {
    return({ members: state.members.users || [], isOwner: (state.profile && state.profile.ownerFlag) ? "true" : "false" });
}

const mapDispatchToProps = {
    setToastr, getMembers, setLoader
}

export default connect(mapStateToProps, mapDispatchToProps)(addMemberForm);