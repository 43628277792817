import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import moment from "moment";
import { withRouter } from 'react-router-dom';
import http from "../../../services/http";
import { __api_getDailyWork } from "../../../api/endpoints";

/** importings actions */
import { getEquipmentList } from "../../../actions/Equipment";
import { setEquipmentDailywork } from "../../../actions/Dailywork";
import { setToastr,setLoader } from "../../../actions/Application";

/** Importing Sub Components */
import SearchEquipmentComponent from "./elements/SearchEquipmentComponent";
import EquipmentDetailsComponent from "./elements/EquipmentDetailsComponent";
import Chart from "./elements/ChartComponent";
import Report from "./elements/ReportComponent";
import MachineReport from "./elements/MachineReportComponent";
import { getDailyWorkYearOrMonth } from "../../../api/dailywork";
import { UserActivity } from "../../../api/header.js";

class DailyworkComponent extends Component {

    constructor(props){
        super(props);

        /** Setting State */
        this.state = {
            selectedEquipment: {},
            selectedDate: moment(),
            years: [],
            months: [],
            openToDate: undefined,
            data: {
                year: "",
                month: '',
            },
            fromPage:'',
            errorAPI: false,
        };

        /** Function Binding */
        this.getSelectedEquipmentAttr = this.getSelectedEquipmentAttr.bind(this);
        this.getSelectedEquipmentDetails = this.getSelectedEquipmentDetails.bind(this);
        this.dataOnOtherDate = this.dataOnOtherDate.bind(this);
        this.updateCalendar = this.updateCalendar.bind(this);
    }

    async getSelectedEquipmentAttr(selectedEquipment = undefined){
        if (selectedEquipment) {
            await this.setState({ 
                selectedEquipment: this.getSelectedEquipmentDetails(selectedEquipment),
                selectedDate: moment()
            });

            /** get daily work data */
            this.getDailyWorkData();
            await this.getYearOrMonth();
        }
    }

    componentWillReceiveProps(newProps) {
        if (this.props.equipments != newProps.equipments ) {
            if(this.props.profile.userActivityFlag == true){
                const {location} = this.props;
                const toUrl = location.pathname; 
                const prevPath = (this.props.location && this.props.location.state && this.props.location.state.prevPath) || 'No path';
                this.trackUserActivity('Success',(`Successfully navigated from ${prevPath} to ${toUrl}`));
            }
        }
        if (this.props.apiError !== newProps.apiError) {
            if (newProps.apiError !== false) {
                this.setState({
                    errorAPI: true,
                    showLoader: false
                })
                if (this.props.profile.userActivityFlag == true) {
                    const { location } = this.props;
                    const toUrl = location.pathname;
                    const prevPath = (this.props.location && this.props.location.state && this.props.location.state.prevPath) || 'No path';
                    this.trackUserActivity('Failed',(`Failed navigation from ${prevPath} to ${toUrl}`));
                }
            }
        }
    }

    updateCalendar(selectedDate =undefined ){
        this.dataOnOtherDate(selectedDate.openToDate);
        this.setState({openToDate:moment(selectedDate.openToDate)})
    }


    getYearOrMonth(year) {
        let { selectedEquipment } = this.state;
        let machineSaleId = selectedEquipment.machineSaleId;
        if(!machineSaleId){
            return;
        }
        this.props.setLoader({ loader: { open: true } });
        let req = {
            "machineSaleId": machineSaleId
        }
        if (year) {
            req["qs"] = `year=${year}`;
        }
        getDailyWorkYearOrMonth(req)
            .then(result => {
                if (result.data && result.data.length > 0) {
                    let yearMonth = result.data;
                        let extractedYears = yearMonth.map(i => i.year);
                        this.setState({ years: extractedYears });
                }
                else{
                        this.setState({ years: [] });
                    
                }
                this.props.setLoader({ loader: { open: false } });
            })
            .catch(error => {
                this.props.setToastr({ toastr: { type: "error", message: error || "Something went wrong." } });
                this.props.setLoader({ loader: { open: false } });
            })
    }

    getSelectedEquipmentDetails(selectedEquipment) {
        /** if no selection, don't allow */
        if (!selectedEquipment.value) return false;

        let { equipments } = this.props;    
        
        /** search equipment from list based on model and plate */
        return equipments.filter(equipment => {
            return selectedEquipment.searchBy === 'model' ? (equipment[selectedEquipment.searchBy] === selectedEquipment.value.split("/")[0] && equipment["machineNum"] === selectedEquipment.value.split("/")[1]) : (equipment[selectedEquipment.searchBy] === selectedEquipment.value);
            })[0] || undefined;
    }

    async getDailyWorkData(date=null){
        let { selectedEquipment } = this.state;
        let {translate} = this.props;
        this.props.setLoader({ loader: { open: true } });
        if(!selectedEquipment || !selectedEquipment.machineSaleId) {
            this.props.setLoader({ loader: { open: false } });
            return false
        };
        try {
            let equipmentWorkDetails = await http.Request("POST", __api_getDailyWork, {
                machineSaleId: selectedEquipment.machineSaleId,
                inDate: moment(date || this.state.selectedDate || undefined).format("YYYY-MM-DD")
            });
            
            
            /** dispatch an action for share data with child components */
            this.props.setEquipmentDailywork(equipmentWorkDetails.data);
            this.props.setLoader({ loader: { open: false } });
            
        } catch (error) {
            this.props.setToastr({toastr: {message:translate('opeartionCantPerform'), type:"error"}});
            this.props.setLoader({ loader: { open: false } });
        }
    }

    dataOnOtherDate(date = null){
        this.setState({ selectedDate : date });
        this.getDailyWorkData(date);
    }

    componentWillUnmount(){
        this.setState({selectedEquipment: undefined});
    }

    trackUserActivity(pageActivity,remarks) {
        const {location} = this.props;
        const fromUrl = (this.props.location && this.props.location.state && this.props.location.state.prevPath) || 'No path';
        const toUrlPath = location.pathname.split('/customer/')[1] || '';
        let fromUrlPath = fromUrl ? fromUrl.split('/customer/')[1]:'';
        const pathNames = {
            "manage-daily-page": "Manage Daily Work",
            "equipment": "My Equipments",
            "location-information": "Location Information",
            "alert-setting": "Alert Setting",
            "reports": "Manage Reports",
            "service-history": "Equipment Service History",
            "area-measurement": "Area Measurement",
            "members" : "My Members",
            "member_management": "Member Management",
            "my-account": "My Account"
            // Add other mappings here as needed
        };
        const convertToTitleCase = (str) => {
            if(!str){
                return '';
            }
            
            return str
                .replace(/-/g, ' ') // Replace hyphens with spaces
                .replace(/\b\w/g, char => char.toUpperCase()); // Capitalize first letter of each word
        };

        let defaultFromPage = fromUrl === '/customer' ? 'Login' : '';
        let defaultToPage = location.pathname === '/customer' ? 'Login' : '';
        let fromUrlName = pathNames[fromUrlPath] || convertToTitleCase(fromUrlPath) || defaultFromPage;
        let toUrlName = pathNames[toUrlPath] || convertToTitleCase(toUrlPath) || defaultToPage;

        let userActivityData = {
            userID: this.props.profile.id,
            userName: this.props.profile.firstName,
            sourceType: 'web',
            fromPage: fromUrlName,
            toPage: toUrlName,
            pageActivity: pageActivity,
            remarks: remarks
        }
        UserActivity(userActivityData)
            .then(result => {
                if (result.statusCode == 200) {
                    this.setState({fromPage:fromUrl});
                }
            })
            .catch(error => {
                console.error('Error in user activity API', error);
            })
    }

    render() {
        let { equipments, translate } = this.props;
        let { selectedEquipment,selectedDate,years } = this.state;
        let machineSaleId= selectedEquipment.machineSaleId
        const isInitial = false;
        return (
                <Fragment>
                    {/* Search Equipment Form Component */}
                    <SearchEquipmentComponent translate={translate} equipments={equipments} onEquipmentSelect={this.getSelectedEquipmentAttr} selectedEquipment={selectedEquipment} onYearMonthChange={this.updateCalendar}/>

                    {/* Equipment Component */}
                    <EquipmentDetailsComponent translate={translate} equipment={selectedEquipment} isInitial={isInitial} currentDate={selectedDate} years={years} onYearMonthChange={this.updateCalendar}/>

                     {/* MachineReport Component Here */}
                     {(Object.keys(selectedEquipment).length > 0) && <MachineReport translate={translate} isEquipment={selectedEquipment?true:false}/>}

                    {/* Map & Chart Component Here */}
                    <Chart translate={translate} equipment={selectedEquipment} dataOnOtherDate={this.dataOnOtherDate} openToDate={this.state.openToDate} years={this.state.years}/>

                    {/* Report Component Here */}
                    {(Object.keys(selectedEquipment).length > 0) && <Report translate={translate} isEquipment={selectedEquipment?true:false}/>}
                    
                </Fragment>
            );
        }
    }
    
    
    const mapStateToProps = state => {
        return({
            equipments: (state.equipment && state.equipment.data && state.equipment.data.SET_EQUIPMENT_DATA) ? state.equipment.data.SET_EQUIPMENT_DATA : [],
            profile: state.profile,
            apiError : state.equipment.FETCH_DATA_FAIL || false,
        });
    }

    const mapDispatchToProps = {
        getEquipmentList,
        setEquipmentDailywork,
        setToastr,
        setLoader
    }

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DailyworkComponent));
