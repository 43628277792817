import React, { Component, Fragment } from "react";
import ReactDOM from "react-dom";
import { Field, reduxForm, change } from "redux-form";
import { required } from "../../shared/Validation";
import { renderTextField } from "../../shared/renderField";
import { Row, Col, CustomInput, FormGroup, Card, CardBody } from "reactstrap";
import TopTabingBar from "../TopTabingBar";
import { getServicingInfo } from "../../../api/service-provider/equipments";
import { setLoader, setToastr } from "../../../actions/Application";
import { connect } from "react-redux";
import EquipmentServiceDetailComponent from "./EquipmentServiceDetail";
import { updateEquipmentServiceInfo } from '../../../actions/Equipment';
import { getSPMachineDetails } from "../../../actions/service-provider/equipment";

class EquipmentServiceInfoComponent extends Component {

    constructor(props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
        this.getEquipmentList = this.getEquipmentList.bind(this);
        this.saveServiceData = this.saveServiceData.bind(this);
        this.getEquipmentServiceInfo = this.getEquipmentServiceInfo.bind(this);
        this.searchBy = this.searchBy.bind(this);
        this.goToItem = this.goToItem.bind(this);
        this.state = {
            searchBy: 'model',
            getServiceData: false,
            data: null,
            machineInfo: null,
            searchItems: [],
            keyword: "",
            fieldType: ""
        }
    }

    onSubmit(values) {
        this.setState({ machineInfo: values });
        this.getEquipmentServiceInfo({ page: 1 }, values);
    }
    searchBy(value) {
        this.setState({ searchBy: value })

        this.props.dispatch(change('SearchForm', 'numberPlate', ''));
        this.props.dispatch(change('SearchForm', 'model', ''));
        this.props.dispatch(change('SearchForm', 'machineNum', ''));
    }

    saveServiceData(values) {
        this.props.updateEquipmentServiceInfo(values);
        this.props.setLoader({ loader: { open: true } });
    }

    getEquipmentServiceInfo(page, data) {

        this.props.setLoader({ loader: { open: true } });
        getServicingInfo({ page: page || 1, qs: `modelId=${data.model}&machineNum=${data.machineNum}&numberPlate=${data.numberPlate}` })
            .then(result => {
                if (result.data) {
                    if(result.data.model== null || result.data.machineNum== null){
                        this.props.setToastr({ toastr: { type: "error", message:  "Please Enter Correct Details" } });
                        this.props.setLoader({ loader: { open: false } });
                        this.setState({ data: result.data});
                    }else{
                        this.setState({ data: result.data });
                    }
                }
                this.props.setLoader({ loader: { open: false } });
                this.setState(
                    { getServiceData: true }
                )
            })
            .catch(error => {
                this.props.setToastr({ toastr: { type: "error", message: error || "Something went wrong." } });
                this.props.setLoader({ loader: { open: false } });
            })
    }
    render() {
        let { handleSubmit, saveData, setToastr, setLoader } = this.props;
        let { getServiceData, data, machineInfo, fieldType, searchItems, keyword } = this.state;
        return (
            <Fragment>
                <TopTabingBar active={4} />
                <form className="equipmentInfo" name="SearchForm" id="SearchForm" autoComplete='off' onSubmit={handleSubmit(this.onSubmit)}>

                    <Row className="members align-items-center form-srch">
                        <Col sm="12">
                            <FormGroup>
                                <Row>
                                    <Col md="2" sm="3"><span className="highlighted">Select Equipment By</span></Col>
                                    <Col md="10" sm="9" className='d-flex justify-content-start'>
                                        <CustomInput type="radio" id="by_model" name="sby" value="model" label="By Model/Machine Number" data-label="Model/Machine Number" onClick={() => this.searchBy('model')} defaultChecked={this.state.searchBy === 'model' ? true : false} />&nbsp;

                                        <CustomInput type="radio" id="by_number_plate" name="sby" value="numberPlate" label="By Number Plate" data-label="Number Plate" onClick={() => this.searchBy('numberPlate')} defaultChecked={this.state.searchBy === 'numberPlate' ? true : false} />


                                    </Col>
                                </Row>
                            </FormGroup>
                        </Col>
                        <Col sm="12">

                            <div className="row profile-asset">
                                {this.state.searchBy === 'model' && <div className="col-sm-6">
                                    <div className="row">
                                        <div className="col-sm-6">Model <span className='required'>*</span>
                                            <Field name="model" id="model" component={renderTextField} className='form-control' type="text" validate={[required]} onChange={this.getEquipmentList} />
                                            {keyword && searchItems && fieldType === 'model' && <div className='search-area'>

                                                <Card className='search-card'>
                                                    <CardBody>
                                                        <ul className="list-group list-group-flush">
                                                            {searchItems.map((item, i) => {
                                                                return <Item key={i} attr={{ item }} goToItem={this.goToItem} fieldType={'model'} />;
                                                            })}
                                                        </ul>
                                                    </CardBody>
                                                </Card>
                                            </div>}
                                            {keyword.length > 0 && searchItems.length == 0 && fieldType === 'model' &&
                                                <div className='search-area'>
                                                    <Card className='search-card'>
                                                        <CardBody>
                                                            <ul className="list-group list-group-flush">
                                                                <li className="list-group-item pointer" >
                                                                    <Row>
                                                                        <Col sm='12' className='no-padd-lr'>
                                                                            <span>No record found</span>
                                                                        </Col>
                                                                    </Row>
                                                                </li>
                                                            </ul>
                                                        </CardBody>
                                                    </Card>
                                                </div>
                                            }
                                        </div>

                                        <div className="col-sm-6">Machine Number <span className='required'>*</span>
                                            <Field name="machineNum" id='machineNum' component={renderTextField} className='form-control' type="text" validate={[required]} onChange={this.getEquipmentList} />
                                            {keyword && searchItems && fieldType === 'machineNum' && <div className='search-area'>

                                                <Card className='search-card'>
                                                    <CardBody>
                                                        <ul className="list-group list-group-flush">
                                                            {searchItems.map((item, i) => {
                                                                return <Item key={i} attr={{ item }} goToItem={this.goToItem} fieldType={'machineNum'} />;
                                                            })}
                                                        </ul>
                                                    </CardBody>
                                                </Card>
                                            </div>}
                                            {keyword.length > 0 && searchItems.length == 0 && fieldType === 'machineNum' &&
                                            <div className='search-area'>
                                                <Card className='search-card'>
                                                    <CardBody>
                                                        <ul className="list-group list-group-flush">
                                                            <li className="list-group-item pointer" >
                                                                <Row>
                                                                    <Col sm='12' className='no-padd-lr'>
                                                                        <span>No record found</span>
                                                                    </Col>
                                                                </Row>
                                                            </li>
                                                        </ul>
                                                    </CardBody>
                                                </Card>
                                            </div>
                                        }
                                        </div></div>
                                </div>}
                                {this.state.searchBy === 'numberPlate' && <div className="col-sm-3">
                                    <div >Number Plate <span className='required'>*</span>
                                        <Field name="numberPlate" id='numberPlate' component={renderTextField} className='form-control' type="text" validate={[required]} onChange={this.getEquipmentList} />
                                        {keyword && searchItems && fieldType === 'numberPlate' && <div className='search-area'>

                                            <Card className='search-card'>
                                                <CardBody>
                                                    <ul className="list-group list-group-flush">
                                                        {searchItems.map((item, i) => {
                                                            return <Item key={i} attr={{ item }} goToItem={this.goToItem} fieldType={'numberPlate'} />;
                                                        })}
                                                    </ul>
                                                </CardBody>
                                            </Card>
                                        </div>}
                                        {keyword.length > 0 && searchItems.length == 0 && fieldType === 'numberPlate' &&
                                            <div className='search-area'>
                                                <Card className='search-card'>
                                                    <CardBody>
                                                        <ul className="list-group list-group-flush">
                                                            <li className="list-group-item pointer" >
                                                                <Row>
                                                                    <Col sm='12' className='no-padd-lr'>
                                                                        <span>No record found</span>
                                                                    </Col>
                                                                </Row>
                                                            </li>
                                                        </ul>
                                                    </CardBody>
                                                </Card>
                                            </div>
                                        }
                                    </div>
                                </div>}

                                <div className="col-sm-4">
                                    <button type="submit" className="btn btn-primary srch-btn" /* disabled={submitting} */>Search</button>
                                </div>
                            </div>
                        </Col>
                    </Row>

                </form>
                {getServiceData && (this.state.data.model || this.state.data.machineNum) && <EquipmentServiceDetailComponent setLoader={setLoader} setToastr={setToastr} saveData={saveData} data={data} getEquipmentServiceInfo={this.getEquipmentServiceInfo} saveServiceData={this.saveServiceData} machineInfo={machineInfo}>


                </EquipmentServiceDetailComponent>}
            </Fragment>

        );
    }
    getEquipmentList(e) {
        let fieldType = e.target.id
        let models = [], machines = [], numberPlates = [];
        let { value } = ReactDOM.findDOMNode(document.getElementById(e.target.id)), { equipmentList } = this.props, regex = new RegExp(value.trim(), 'i');
        let filtered = equipmentList.filter(e => {
            if (e.model != null && !models.includes(e.model.toString()) && fieldType === 'model') {
                if (regex.test(e.model.toString())) {
                    models.push(e.model.toString());
                    return e;
                }
            } else if (e.machineNum != null && !machines.includes(e.machineNum.toString()) && fieldType === 'machineNum') {
                if (regex.test(e.machineNum)) {
                    machines.push(e.machineNum.toString())
                    return e;
                }
            } else if (e.numberPlate != null && !numberPlates.includes(e.numberPlate.toString()) && fieldType === 'numberPlate') {
                if (regex.test(e.numberPlate)) {
                    numberPlates.push(e.numberPlate.toString())
                    return e;
                }
            }
        });
        this.setState({ searchItems: filtered, keyword: value, fieldType: fieldType });
    }
    componentWillReceiveProps(nextprops) {
        if (nextprops.saveData) {
            this.getEquipmentServiceInfo({ page: 1 }, this.state.machineInfo);
            nextprops.setLoader({ loader: { open: false } });
        }
        if (nextprops.equipmentList) {
            //  this.getAllMachineDetail(nextprops.equipmentList);
        }
    }
    componentDidMount() {
        this.props.getSPMachineDetails();

    }
    goToItem(attr, fieldType) {
        //setting value
        this.props.dispatch(change('SearchForm', fieldType, attr));
        this.setState({ keyword: "", searchItems: [], fieldType: "" });
    }

}
const Item = props => {
    let { attr, goToItem, fieldType } = props;
    var listOption = attr.item[fieldType];
    return (
        <li className="list-group-item pointer" onClick={() => goToItem(listOption, fieldType)} >
            <Row>
                <Col sm='12' className='no-padd-lr'>
                    <span>{listOption}</span> &nbsp;
                </Col>
            </Row>
        </li>
    );
}
function mapStateToProps(state) {
    let saveData = (state.sp && state.sp.data && state.sp.data.SET_EQUIPMENT_SERVICE_INFO) ? state.sp.data.SET_EQUIPMENT_SERVICE_INFO.data : false;
    let equipmentList = (state.sp && state.sp.eqpList && state.sp.eqpList.SET_ALL_EQUIPMENTS)
    return { saveData: saveData, equipmentList: equipmentList }
}
let formComponent = reduxForm({ form: "SearchForm" })(EquipmentServiceInfoComponent);
let mapDispatchToProps = { setLoader, setToastr, updateEquipmentServiceInfo, getSPMachineDetails };

export default connect(mapStateToProps, mapDispatchToProps)(formComponent);