import React from 'react';
import { connect } from 'react-redux';
import { Row, Col } from "reactstrap";
import Image from "../../shared/Image";
import Moment from 'react-moment';
import noImage from "../../../assets/images/noimage.png";

const EquipmentServiceDetail = props => {
    let { equipment,translate } = props;
    /** Render only if we have equipment details */
    if (!equipment || !Object.keys(equipment).length) return false;
    

    return (
        <div className="tracker-details">
            <Row className='no-gutters'>
                <Col sm='12' md='12' lg={5} className="area-bordered">
                    <Row className='no-gutters'>
                        <Col sm='2' className="tracker-img-pos text-center">
                      
                            <Image src={equipment.logo|| noImage } className="img-responsive img-circle" />
                        </Col>
                        <Col sm='10' className="tracker-info-pos">
                            <Row className='no-gutters'>
                                <Col sm='6'>
                                   <span className="highlighted">{translate('model')}</span> {equipment.model || " "}
                                </Col>
                                <Col sm='6'>
                                <span className="highlighted">{translate('machineno')}</span> {equipment.machineNum || " "}
                                </Col>
                                <div className="w-100"></div>
                                {equipment.status == "TE" && <Col sm='6'>
                                     <span className="highlighted">{translate('numberPlate')}</span> {(equipment.numberPlate ? equipment.numberPlate.toUpperCase(): " ")}
                                 </Col>}
                                <Col sm='6'>
                                <span className="highlighted">{translate('type')}</span> {equipment.equipmentType ? equipment.equipmentType.toUpperCase() : " "}
	                            </Col>
                               
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col lg="7" sm='12' md='12' className="area-filled">

                    <Row className='no-gutters'>
                        <Col className="col-sm-12 tracker-perf-pos">
                            <Row className='no-gutters'>
                                <Col sm='6'>
                                    <span className="detail-highlighted">{translate('currentHourMeter')}</span>
                                    <span className="detail-right">{equipment.currentHourMeter ? parseFloat(equipment.currentHourMeter).toFixed(1) : "-"}</span>
                                </Col>
                                <Col sm='6'>
                                    <span className="detail-highlighted">{translate('lastServiceByHour')}</span>
                                    <span className="detail-right">{equipment.lastServiceByHour ? equipment.lastServiceByHour : "-"}</span>
                                </Col>
                                <Col sm='6'>
                                    <span className="detail-highlighted">{translate('nextServiceByHour')}</span>
                                    <span className="detail-right">{equipment.nextServiceDateByHour ? equipment.nextServiceDateByHour : "-"}</span>
                                </Col>
                                <Col sm='6'>
                                    <span className="detail-highlighted">{translate('lastServiceByDate')}</span>
                                    <span className="detail-right">{equipment.lastServiceDate ? <Moment format="DD MMM YYYY">{equipment.lastServiceDate}</Moment> : "-"}</span>
                                </Col>
                                <Col sm='6'>
                               {equipment.status =='TE' && <div><span className="detail-highlighted">{translate('nextServiceByDays')}</span>
                               <span className="detail-right">{equipment.nextServiceDateByDays ? equipment.nextServiceDateByDays : "-"}</span>
     </div>} </Col>

                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
};



export default EquipmentServiceDetail;