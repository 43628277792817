import { takeLatest, call, put } from 'redux-saga/effects';
import { getAllMachineLocation } from '../../api/service-provider/equipments';
import { SET_LOADER, SET_TOASTR, SET_ALL_EQUIPMENTS_LOCATION, GET_ALL_EQUIPMENTS_LOCATION } from '../../types';




export function* getSPMachineLocation(action) {

    try {
        const equipments = yield call(getAllMachineLocation, action.data);
        
        yield put({ type: SET_ALL_EQUIPMENTS_LOCATION, data: equipments.data||[]});
    }
    catch (e) {
        yield put({
            type: SET_TOASTR,
            data: {
                toastr: {
                    message: "We could't find search result for you.", 
                    type:"info"
                }
            }
        });

        yield put({
            type: SET_LOADER,
            data: {
                loader: {
                    open: false
                }
            }
        });
    }
}
/**
 * map the dispatched action to the above function (Returning with pagination)
 */
export default function* watchSPMachineLocation() {
    yield takeLatest(GET_ALL_EQUIPMENTS_LOCATION, getSPMachineLocation) // without pagination
}

   
