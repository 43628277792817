import React, { Component } from 'react';
import DataSheet from './datasheet/DataSheet';

/* A Equipment component */
class EquipmentComponent extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        const { translate } = this.props;
        return (
            <div>
                <DataSheet timezone={this.props.timezone} equipment={this.props.dashdata} apiError = { this.props.apiError } markAsRead = {this.props.markAsRead} translate = { translate } />
            </div>
        );
    }
}

export default EquipmentComponent;
