import React, { Component } from 'react';
import { Col} from 'reactstrap'
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import {FaUserCog,FaExclamationTriangle} from 'react-icons/fa'

class MaintenanceBar extends Component {
    
    constructor(props) {
        super(props);
        this.state={
            maintainSysData : [],
        };
    }

	componentWillReceiveProps(nextProps){
		if(this.props.maintainSysData !== nextProps.maintainSysData){
			if(nextProps.maintainSysData.SET_ALERT_NOTIFICATION_DATA){
				this.setState({
					maintainSysData : nextProps.maintainSysData.SET_ALERT_NOTIFICATION_DATA.systemMaintenance
				});
			}
		}
	}

    render() {
        const isService = (this.props.location.pathname.split('/')[1] === 'service');
		const isServProv  = (this.props.location.pathname.split('/')[1] === 'service-provider');
		let data = [];
		if(this.state.maintainSysData && this.state.maintainSysData.length > 0){
			data = this.state.maintainSysData.filter((val)=>{
				if(isService){
					return (val.application && val.application.trim() === "SMARTASSIST SERVICE")
				}
				if(isServProv){
					return (val.application && val.application.trim() === "SMARTASSIST SERVICE")
				}
				return (val.application && val.application.trim() === "SMARTASSIST CUSTOMER")
			})
			 
		}
		if(data.length > 0){
			return (
				<Col sm="12" md={{ size: 6, offset: 3 }}>
					<div className="float-left iconarea">
						<FaUserCog className="maintenancebaruser"/>
						<FaExclamationTriangle className="maintenancebaruser-ex"/>
					</div>
					<div className="textarea">
						<div>There will be a scheduled system maintenance from {moment(data[0].startDate).format('D MMM YYYY HH:mm:ss')} - {moment(data[0].endDate).format('D MMM YYYY HH:mm:ss')}.</div>
						<div>We apologize for any inconvenience this may cause.</div>
					</div>
					<div className="clearfix"/>
				</Col>
			);
		}else{
			return '';
		}
    }
}

export default withRouter(MaintenanceBar);
