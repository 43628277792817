import React, { Component } from 'react';
import { Link } from "react-router-dom";
import ButtonField  from '../../../components/shared/ButtonField';
import DataSheet from './datasheet/DataSheet';
import _find from "lodash/find";
import session from "../../../services/session";
import circle from '../../../assets/images/admin/circle.png';
import completedGreen from '../../../assets/images/admin/completed-green.svg';
import inProgressGren from '../../../assets/images/admin/in-progress.svg';

/* A System Maintenance Component component */
class SystemMaintenanceComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            maintainSysData: [],
            routeList : (session && session.get("prev","cookie")) || []
        };
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.maintainSysData !== nextProps.maintainSysData) {
            this.setState({
                maintainSysData: nextProps.maintainSysData
            })
        }
    }

    handlePageChange(data) {
        this.props.handlePageChange(
            {
                startIndex: data.page,
                totalEventsFlag: data.totalEventsFlag,
                sortingParams: (data.sortingParams === undefined) ? null : data.sortingParams
            }
        );
}

handleSubmit(data){
    this.props.handleSubmit(data);
}

render() {
    const  { translate } = this.props;
    let routeList = (session && session.get("prev","cookie")) || [];

    let modules = {
        addMaintenance: "/service/add-system-maintenance",
        sysMaintenance: "/service/system-maintenance"
    };

    let hasNewMaintenance = _find(routeList, {uuid: modules.sysMaintenance});

    return (
        <div className="eqalertcontainer">
            {hasNewMaintenance && <Link to="add-system-maintenance">
                <ButtonField
                    className = "float-right btn-primary"
                    buttonText = "Schedule Maintenance"
                    />
            </Link>}
            <div style={{height:'40px'}}>
                <img className="completed" src={completedGreen} alt="completed" style={{width: '20px'}}/> Completed
                <img className="inprogress" src={inProgressGren} alt="In Progress" style={{width: '25px', marginLeft:'13px'}}/> In Progress
                <img className="notstart" src={circle} alt="Started" style={{width: '20px', marginLeft:'13px'}} /> Started
            </div>

            <DataSheet
                className = "clearfix pt-3"
                sysMaintenance={ this.props.sysMaintenance }
                handleSubmit={this.handleSubmit.bind(this) }
                handlePageChange={ this.handlePageChange.bind(this) }
                maintainSysData = {this.state.maintainSysData}
                apiError = { this.props.apiError }
                setToastr = { this.props.setToastr }
                translate = { translate }
                />
        </div>
    );
}
}

export default SystemMaintenanceComponent;
