import { GET_DEALER_EQUIPMENT_LIST, GET_DEALERS } from '../../types';

export const getDealerEquipmentList = (data) => {
    return { type: GET_DEALER_EQUIPMENT_LIST, data }
}

export const getDealersList = (data) => {
    return { type: GET_DEALERS, data }
}

