import React, { Component, Fragment } from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import { Row, Col } from "reactstrap";
import { withRouter } from "react-router-dom";
import { Field, reduxForm, change } from "redux-form";
import { FaCalendarAlt } from 'react-icons/fa';
import DatePicker from 'react-datepicker';
import { FileInput } from "../../shared/renderField";
import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from 'moment';
/** importing sub-components */
import HeaderText from "../../shared/HeaderText";
import { renderTextField, renderSelectField, renderCustomField } from "../../shared/renderField";
import { required, max10, min10, min6, max6, email, onlyNumbers, alphaNum_ } from "../../shared/Validation";
/** importing APIs */
import { validateOwnerId } from "../../../api/admin/owner";
import { getMachineInfo, addOwner } from "../../../api/admin/owner";
/** importing actions */
import { setToastr, setLoader, setConfirmBox } from "../../../actions/Application";
import { GeoCodeKey } from "../../../constants/Constants";
import Geocode from "react-geocode";
Geocode.setApiKey(GeoCodeKey);

const form = "newOwnerForm";

const asyncValidate = (values /*, dispatch */) => {
    return validateOwnerId(values.loginId).then(result => {
        if (typeof result.data === 'boolean' && result.data === true) throw { loginId: 'This Owner ID is taken already.' }
    })
}

class NewOwner extends Component {
    constructor(props) {
        super(props);

        /** component state */
        this.state = {
            machineSaleId: undefined,
            preview: false,
            providers: [],
            company: [],
            selectedCompany: [],
            isEmergency: true,

            data: {
                // machineSaleId : '',
                startDate: moment(),
                tractorSaleDate: moment(),
                applicationDate: moment(),
                //toDate: moment().subtract(1, "d"),
                endDate: ''
            },
        };

        /** Function binding */
        this.endDate=this.endDate.bind(this);
        this.startDate=this.startDate.bind(this);
        this.applicationDate=this.applicationDate.bind(this);
        this.tractorSDate=this.tractorSDate.bind(this);
        this.getMachineInfo = this.getMachineInfo.bind(this);
        this.getServiceCompany = this.getServiceCompany.bind(this);
        this.getParentCompany = this.getParentCompany.bind(this);
        this.addNewOwner = this.addNewOwner.bind(this);
        this.setPreviewnDisability = this.setPreviewnDisability.bind(this);
        this.changeDetails = this.changeDetails.bind(this);
        this.previewOwnerDetails = this.previewOwnerDetails.bind(this);
        this.emergency = this.emergency.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.getCityState = this.getCityState.bind(this);
    }

    getMachineInfo() {
        let machineNum = ReactDOM.findDOMNode(document.getElementById("machineNum")).value,
            model = ReactDOM.findDOMNode(document.getElementById("model")).value,
            { dispatch, setToastr, setLoader } = this.props;

        if (machineNum && model) {
            setLoader({ loader: { open: true, loadingText: "Fetching Machine Information" } });
            /** get machine infomation */
            getMachineInfo({ machineNum, model })
                .then(response => {
                    setLoader({ loader: { open: false } });

                    let machineInfo = {
                        equipmentType: (response.data.equipmentDetails && response.data.equipmentDetails.equipmentType) ? response.data.equipmentDetails.equipmentType : "",
                        terminalId: (response.data.equipmentDetails && response.data.equipmentDetails.terminalId) ? response.data.equipmentDetails.terminalId : "",
                        machineSaleId: (response.data.equipmentDetails && response.data.equipmentDetails.machineSaleId) ? response.data.equipmentDetails.machineSaleId : 0,
                        machineNum,
                        model
                    };

                    this.setState({ machineSaleId: (response.data.equipmentDetails && response.data.equipmentDetails.hasOwnProperty("machineSaleId")) ? response.data.equipmentDetails.machineSaleId : undefined });

                    // use `change` method to set specific field value
                    dispatch(change(form, "equipmentType", machineInfo.equipmentType));
                    dispatch(change(form, "terminalId", machineInfo.terminalId));
                    dispatch(change(form, "machineSaleId", machineInfo.machineSaleId));
                    // set providers
                    if (response.data.equipmentExitFlag) {
                        setToastr({ toastr: { message: "This equipment already exists", type: "info" } });
                    } else if (!response.data.equipmentDetails) {
                        setToastr({ toastr: { message: "Invalid Machine Number or Model", type: "info" } });
                    }
                    this.setState({ providers: response.data.serviceProviders || [] });
                })
                .catch(error => {
                    setToastr({ toastr: { message: "Invalid Machine Number or Model", type: "error" } });
                })
        }
    }

    handleChange(type, data) {
        if (data === undefined) {
            data = type.target.value;
            type = type.target.name;
        }

        this.setState(
            {
                data: {
                    ...this.state.data, [type]: data
                }
            });
    }

    startDate(e) {
        e.preventDefault();
        this.startcomponent.setOpen(true);
    }

    endDate(e) {
        e.preventDefault();
        this.endcomponent.setOpen(true);
    }
    tractorSDate(e) {
        e.preventDefault();
        this.tractorsaledatecomponent.setOpen(true);
    }
    applicationDate(e) {
        e.preventDefault();
        this.applicationDatecomponent.setOpen(true);
    }

    getServiceCompany() {

        let serviceProvider = ReactDOM.findDOMNode(document.getElementById("serviceProvider")).value,
            company = [], selectedCompany = [], { dispatch } = this.props;

        if (serviceProvider) {
            getMachineInfo({ serviceProvider })
                .then(response => {
                    company.push({ options: response.data.serviceProviders || [] });
                    /** set selected options */
                    selectedCompany.splice(0, 0, { companyCode: serviceProvider });

                    if (response.data.serviceProviders && response.data.serviceProviders.length === 1) {
                        dispatch(change(form, ["company0"].join(""), response.data.serviceProviders[0].parentCompanyName));

                        selectedCompany.push({ companyCode: response.data.serviceProviders[0].parentCompanyCode });
                    }

                    this.setState({ company, selectedCompany });
                })
        } else {
            dispatch(change(form, "company0", ""));
            this.setState({ company, selectedCompany });
        }
    }

    getParentCompany($event) {
        let serviceProvider = $event.target.value, { company, selectedCompany } = this.state, { tagid } = $event.target.dataset, { dispatch } = this.props;

        if (serviceProvider) {
            getMachineInfo({ serviceProvider })
                .then(response => {
                    if (company[tagid]) {
                        /**set parent company options */
                        company[+tagid + 1] = { options: response.data.serviceProviders || [] };
                        company.splice(+tagid + 2, company.length);
                        /**set selected company value */
                        selectedCompany[+tagid + 1] = { companyCode: serviceProvider };
                        selectedCompany.splice(+tagid + 2, selectedCompany.length);

                        let defaultSelectValue = "";

                        if (response.data.serviceProviders && response.data.serviceProviders.length === 1) {
                            defaultSelectValue = response.data.serviceProviders[0].parentCompanyName;

                            if (defaultSelectValue) selectedCompany.push({ companyCode: response.data.serviceProviders[0].parentCompanyCode });
                        }

                        dispatch(change(form, ["company", +tagid + 1].join(""), defaultSelectValue))
                    }
                    this.setState({ company, selectedCompany });
                })
        } else {
            /** remove all feilds which are child of last selected parent */
            company.splice(+tagid + 1, company.length);
            selectedCompany.splice(+tagid + 1, selectedCompany.length);
            this.setState({ company });
        }
    }

    previewOwnerDetails(values) {
        /** delete unwanted fields */
        const endDate = new Date(this.state.data.endDate);
        const localEndTime = new Date(endDate.getTime() - endDate.getTimezoneOffset() * 60 * 1000);
        let data = {
            equipmentDetails: {
                machineSaleId: values.machineSaleId,
                machineNum: values.machineNum,
                model: values.model,
                numberPlate: values.numberPlate,
                equipmentType: values.equipmentType,
                tractorSaleDate: this.state.data.tractorSaleDate,
                applicationForm: values.applicationForm,
                applicateDate: this.state.data.applicationDate,
                contractNumber: values.contractNumber,
                contractDate: this.state.data.startDate,
                contractPeriod: localEndTime,
                remark: values.remarks,
            },
            companyCodes: this.state.selectedCompany.reverse(),
            customerDetail: {
                firstName: values.firstName,
                lastName: values.lastName,
                ownerFlag: true,
                loginId: values.loginId,
                address: values.address,
                city: values.city,
                zipCode: values.zipCode,
                stateCode: values.stateCode,
                phone: values.phone,
                alternatePhone: values.alternatePhone,
                email: values.email,
                emergencyCallFlag: (values.emergencyCallFlag === 'true') ? true : false,
                emergencyCallNumber: values.emergencyCallNumber,
                languageCode: ReactDOM.findDOMNode(document.getElementById("languageCode")).value,
                timezone: ReactDOM.findDOMNode(document.getElementById("timezone")).value
            }
        }, { preview } = this.state, { setConfirmBox } = this.props;
        if (preview) {
            /** register user here */
            setConfirmBox({ modal: { open: true, data, title: "Save Owner", content: "Do you want to register these details?", done: this.addNewOwner } });
        } else {
            /** ask admin to save user */
            this.setPreviewnDisability(true);
        }
    }

    addNewOwner(data) {
        let { setLoader, setToastr } = this.props, { push } = this.props.history;
        /** show loader */
        setLoader({ loader: { open: true } });
        return new Promise((resolve, reject) => {
            addOwner(data)
                .then(response => {
                    if (response.data == false) {
                        setToastr({ toastr: { message: "Failed to add details. Please try again later.", type: "error" } });
                        setLoader({ loader: { open: false } });
                    } else {
                        setLoader({ loader: { open: false } });
                        this.setState({ providers: [], company: [] });
                        resolve({ toastr: { message: "Owner has been registered successfully.", type: "success" } });
                        push("/service/owners");
                    }
                })
                .catch(error => {

                    setLoader({ loader: { open: false } });
                    setToastr({ toastr: { message: error || "This request couldn't be completed. Try again later.", type: "error" } });
                    reject(error);
                })
        });
    }

    componentWillUnmount() {
        this.setState({ providers: [], company: [] });
    }

    setPreviewnDisability(type) {
        this.setState({ preview: type });
        let filledForm = document.forms[form];

        for (let i = 0; i < filledForm.elements.length; i++) {
            if (filledForm.elements[i].className && filledForm.elements[i].className.indexOf("ignore") < 0) filledForm.elements[i].disabled = type;
        }
    }

    changeDetails(prev = false) {
        if (!prev) {
            this.props.history.goBack();
        } else {
            this.setPreviewnDisability(false);
        }
    }

    emergency($e) {
        this.setState({ isEmergency: $e.target.value === "true" ? true : false });
    }

    getCityState() {
        let { dispatch , setLoader, setToastr } = this.props;
        let zipCode = ReactDOM.findDOMNode(document.getElementById("zipCode")).value;
        /*if(zipCode.length<6){
            ReactDOM.findDOMNode(document.getElementById("city")).value="";
            return;
        }*/
        if (zipCode) {
            setLoader({ loader: { open: true, loadingText: "Fetching City and State" } });
            Geocode.fromAddress(zipCode).then(
                (response) => {
                    setLoader({ loader: { open: false } });
                    const { lat, lng } = response.results[0].geometry.location;
                    this.setState({
                        markrs: [{ lat: lat, lng: lng }]
                    })
                    Geocode.fromLatLng(lat, lng).then(
                        (response) => {
                            //const address = response.results[0].formatted_address;
                            let city, state;
                            for (let i = 0; i < response.results[0].address_components.length; i++) {
                                for (let j = 0; j < response.results[0].address_components[i].types.length; j++) {
                                    switch (response.results[0].address_components[i].types[j]) {
                                        case "locality":
                                            city = response.results[0].address_components[i].long_name;
                                            break;
                                        case "administrative_area_level_1":
                                            state = response.results[0].address_components[i].short_name;
                                            break;
                                    }
                                }
                            }
                            //ReactDOM.findDOMNode(document.getElementById("city")).value=city;
                            //ReactDOM.findDOMNode(document.getElementById("state")).value=state;
                            dispatch(change(form, "city", city));
                            dispatch(change(form, "stateCode", state));
                        },
                        (error) => {
                            setToastr({ toastr: { message: "Invalid Zip Code", type: "error" } });
                            setLoader({ loader: { open: false } });
                        }
                    );
                },
                (error) => {
                    setToastr({ toastr: { message: "Invalid Zip Code", type: "error" } });
                    setLoader({ loader: { open: false } });
                }
            );
        }
    }

    render() {
        let { handleSubmit, language, states, timezone } = this.props, { preview, providers, company, machineSaleId, isEmergency } = this.state;
        return (
            <Fragment>
                <HeaderText headerText={preview ? 'New Registration - Preview' : 'New Registration'}></HeaderText>
                {/* table data here */}
                <form onSubmit={handleSubmit(this.previewOwnerDetails)} autoComplete='off' className='form-horizontal' id={form}>
                    <section className='member-machine-info'>
                        <Row className='no-gutters'>
                            {/* <p className='section-headline float-left'><span className='highlighted'>Sales Equipment Information</span></p> */}

                            {/* Hidden fileds */}
                            <Field component={renderTextField} type='hidden' name='machineSaleId' id='machineSaleId' value={machineSaleId} />

                            <Col md='6' sm='6' xs='12' className='machine-asset'>
                                <Col md='4' className='float-left'>
                                    Model <span className='required'>*</span>
                                </Col>

                                <Col md='8' className='float-left'>
                                    <Field name="model" id="model" autoComplete="off" component={renderTextField} className='form-control' type="text" validate={[required]} onBlur={this.getMachineInfo}/>
                                </Col>
                            </Col>

                            <Col md='6' sm='6' xs='12' className='machine-asset'>
                                <Col md='4' className='float-left'>
                                    SA-R ID <span className='required'>*</span>
                                </Col>

                                <Col md='8' className='float-left'>
                                    <Field name="terminalId" component={renderTextField} className='form-control ignore' type="text" disabled validate={[required]} />
                                </Col>
                            </Col>

                            <Col md='6' sm='6' xs='12' className='machine-asset'>
                                <Col md='4' className='float-left'>
                                    Machine Number <span className='required'>*</span>
                                </Col>

                                <Col md='8' className='float-left'>
                                    <Field name="machineNum" id="machineNum" component={renderTextField} className='form-control' type="text" validate={[required]} onBlur={this.getMachineInfo} />
                                </Col>
                            </Col>

                            <Col md='6' sm='6' xs='12' className='machine-asset'>
                                <Col md='4' className='float-left'>
                                    Equipment Type <span className='required'>*</span>
                                </Col>

                                <Col md='8' className='float-left'>
                                    <Field name="equipmentType" component={renderTextField} className='form-control ignore' type="text" disabled validate={[required]} />
                                </Col>
                            </Col>

                            <Col md='6' sm='6' xs='12' className='machine-asset'>
                                <Col md='4' className='float-left'>
                                    Number Plate
                                </Col>

                                <Col md='8' className='float-left'>
                                    <Field name="numberPlate" component={renderTextField} className='form-control' type="text" validate={[alphaNum_]} />
                                </Col>
                            </Col>

                            <Col md='6' sm='6' xs='12' className='machine-asset'>
                                <Col md='4' className='float-left'>
                                    Service Provider <span className='required'>*</span>
                                </Col>

                                <Col md='8' className='float-left'>
                                    <Field name="serviceProvider" id="serviceProvider" component={renderSelectField} validate={[required]} onChange={this.getServiceCompany} style={{ padding: '0.65rem 0.8rem'}}>
                                        <option value="">Select Provider</option>
                                        {providers && providers.map((provider, index) => <option key={index} value={provider.childCompanyCode}>{provider.childCompanyName}</option>)}
                                    </Field>
                                </Col>
                            </Col>

                            {company.map((field, index) => {
                                return (
                                    <Col key={index} md='6' sm='6' xs='12' className='machine-asset'>
                                        {field.options && field.options.length >= 1 && <Col md='4' className='float-left'>
                                            {index === 0 ? 'Service Company' : ''}  {!index && <span className='required'>*</span>}
                                        </Col>}

                                        {field.options && field.options.length >= 1 && <Col md='8' className='float-left'>
                                            {field.options && field.options.length > 1 ? <Field name={'company' + index} id={'company' + index} data-tagid={index} component={renderSelectField} validate={[required]} onChange={this.getParentCompany}>
                                                <option value="">Select Company</option>

                                                {field.options.map((option, i) => {
                                                    return <option key={i} value={option.parentCompanyCode}>{option.parentCompanyName}</option>;
                                                })}
                                            </Field> :
                                                <Fragment>
                                                    {field.options && field.options.length === 1
                                                        ? <Field name={"company" + index} id={"company" + index} component={renderTextField} className='form-control ignore' disabled type="text" validate={[required]} readOnly />
                                                        : null}
                                                </Fragment>
                                            }
                                        </Col>}
                                    </Col>
                                );
                            })}
                        </Row>

                    </section>


                    {/* Contract information*/}
                    {/*<section className='member-personal-info margin-up'>*/}
                    <section className='member-contract-info margin-up'>
                        <Row className='no-gutters'>

                            <Col md='6' sm='6' xs='12' className='machine-asset'>
                                <Col md='4' className='float-left'>
                                    Application Date <span className='required'>*</span>
                                </Col>

                                <Col md='8' className='d-flex justify-content-start align-items-center float-left'>
                                    <div className="float-left mr-5 position-relative" >
                                        <DatePicker
                                            selected={this.state.data.applicationDate}
                                            maxDate={moment()}
                                            ref={(r) => {
                                                this.applicationDatecomponent = r;
                                            }}
                                            autoComplete='off'
                                            onChange={this.handleChange.bind(this, 'applicationDate')}
                                            dateFormat="DD MMM YYYY"
                                            id="applicationDate"
                                            className="form-control pointer-event"
                                        />
                                        <button className="calLogoContract" onClick={this.applicationDate}><FaCalendarAlt /></button>
                                    </div>
                                </Col>
                            </Col>

                            <Col md='6' sm='6' xs='12' className='machine-asset'>
                                <Col md='4' className='float-left'>
                                    Contract Number <span className='required'>*</span>
                                </Col>

                                <Col md='8' className='float-left'>
                                    <Field name="contractNumber" id="contractNumber" component={renderTextField} className='form-control' type="text" validate={[required]} />
                                </Col>
                            </Col>

                            <Col md='6' sm='6' xs='12' className='machine-asset'>
                                <Col md='4' className='float-left'>
                                    Contract Period<span className='required'>*</span>
                                </Col>
                                <Col md='8' className='d-flex justify-content-start align-items-center float-left'>
                                {/*<div className='float-left col-md-8'>*/}
                                    <div className="float-left mr-4 position-relative">
                                        <DatePicker 
                                            selected={this.state.data.startDate}
                                            maxDate={moment()}
                                            ref={(r) => {
                                                this.startcomponent = r;
                                            }}
                                            autoComplete='off'
                                            onChange={this.handleChange.bind(this, 'startDate')}
                                            dateFormat="DD MMM YYYY"
                                            id="startDate"
                                            required="true"
                                            className="form-control pointer-event" 
                                        />
                                        <button className="calLogoContract" onClick={this.startDate}><FaCalendarAlt /></button>
                                    </div>
                                    <div className="float-left mr-1 position-relative" style={{left: '6px'}}>
                                        <DatePicker 
                                            selected={this.state.data.endDate}
                                            minDate={this.state.data.startDate}
                                            //maxDate={moment()}
                                            ref={(r) => {
                                                this.endcomponent = r;
                                            }}
                                            autoComplete='off'
                                            onChange={this.handleChange.bind(this, 'endDate')}
                                            dateFormat="DD MMM YYYY"
                                            id="endDate"
                                            required="true"
                                            className="form-control pointer-event"
                                        />
                                        <button className="calLogoContract" onClick={this.endDate}><FaCalendarAlt /></button>
                                    </div>
                                {/*</div>*/}
                                </Col>
                            </Col>

                            <Col md='6' sm='6' xs='12' className='machine-asset'>
                                <Col md='4' className='float-left'>
                                    Application form
                                </Col>

                                <Col md='8' className='float-left'>

                                    {/* <div className="float-left col-sm-8 px-0">
                                        <Field name="file" component={FileInput} className='form-control' type="file" validate={[required]} onChange={() => this.setState({ mapRecords: [] })} />
                                    </div> */}

                                    <div className="float-left ">
                                        <Field name="applicationForm" id="applicationForm" component={FileInput} className='form-control' type="file" accept='image/*' />
                                        {/*<button  className="btn btn-primary" onClick={()}>Upload</button>*/}
                                    </div>

                                </Col>
                            </Col>
                            <Col md='6' sm='6' xs='12' className='machine-asset'>
                                <Col md='4' className='float-left' >
                                    Remarks
                                </Col>

                                <Col md='8' className='float-left'>
                                    <Field name="remarks" id="remarks" component={renderTextField} className='form-control' type="text" />
                                </Col>
                            </Col>

                        </Row>

                    </section>

                    <section className='member-personal-info margin-up contract'>
                        <Row className='no-gutters'>
                            {/* <p className='section-headline float-left'><span className='highlighted'>Sales Equipment Information</span></p> */}

                            <Col md='6' sm='6' xs='12' className='machine-asset'>
                                <Col md='4' className='float-left'>
                                    User ID <span className='required'>*</span>
                                </Col>

                                <Col md='8' className='float-left'>
                                    <Field name="loginId" component={renderTextField} className='form-control' type="text" validate={[required]} />
                                </Col>
                            </Col>

                            <Col md='6' sm='6' xs='12' className='machine-asset'>
                                <Col md='4' className='float-left'>
                                    First Name <span className='required'>*</span>
                                </Col>

                                <Col md='8' className='float-left'>
                                    <Field name="firstName" component={renderTextField} className='form-control' type="text" validate={[required]} />
                                </Col>
                            </Col>

                            <Col md='6' sm='6' xs='12' className='machine-asset'>
                                <Col md='4' className='float-left'>
                                    Last Name <span className='required'>*</span>
                                </Col>

                                <Col md='8' className='float-left'>
                                    <Field name="lastName" component={renderTextField} className='form-control' type="text" validate={[required]} />
                                </Col>
                            </Col>

                            <Col md='6' sm='6' xs='12' className='machine-asset'>
                                <Col md='4' className='float-left'>
                                    Address <span className='required'>*</span>
                                </Col>

                                <Col md='8' className='float-left'>
                                    <Field name="address" component={renderTextField} className='form-control' type="text" validate={[required]} />
                                </Col>
                            </Col>

                            <Col md='6' sm='6' xs='12' className='machine-asset'>
                                <Col md='4' className='float-left'>
                                    Pin Code <span className='required'>*</span>
                                </Col>

                                <Col md='8' className='float-left'>
                                    <Field name="zipCode" id="zipCode" component={renderTextField} className='form-control' type="text" validate={[required, min6, max6, onlyNumbers]} onBlur={this.getCityState}/>
                                </Col>
                            </Col>

                            <Col md='6' sm='6' xs='12' className='machine-asset'>
                                <Col md='4' className='float-left'>
                                    City <span className='required'>*</span>
                                </Col>

                                <Col md='8' className='float-left'>
                                    <Field name="city" id="city" component={renderTextField} className='form-control' type="text" validate={[required]} />
                                </Col>
                            </Col>

                            <Col md='6' sm='6' xs='12' className='machine-asset'>
                                <Col md='4' className='float-left'>
                                    State <span className='required'>*</span>
                                </Col>

                                <Col md='8' className='float-left'>
                                    <Field name="stateCode" id="state" component={renderSelectField} validate={[required]} style={{ padding: '0.65rem 0.8rem'}}>
                                        <option value="">Select State</option>
                                        {states && states.map(state => <option key={state.stateCode} value={state.stateCode}>{state.state}</option>)}
                                    </Field>
                                </Col>
                            </Col>

                            <Col md='6' sm='6' xs='12' className='machine-asset'>
                                <Col md='4' className='float-left'>
                                    Country <span className='required'>*</span>
                                </Col>

                                <Col md='8' className='float-left'>
                                    <Field name="country" component={renderSelectField} style={{ padding: '0.65rem 0.8rem'}}>
                                        <option value="99" selected>India</option>
                                    </Field>
                                </Col>
                            </Col>

                            <Col md='6' sm='6' xs='12' className='machine-asset'>
                                <Col md='4' className='float-left'>
                                    Mobile Number <span className='required'>*</span>
                                </Col>

                                <Col md='8' className='float-left'>
                                    <Field name="phone" component={renderTextField} className='form-control' type="text" validate={[required, min10, max10, onlyNumbers]} />
                                </Col>
                            </Col>

                            <Col md='6' sm='6' xs='12' className='machine-asset'>
                                <Col md='4' className='float-left'>
                                    Alternate Mobile Number<span className='required'>*</span>
                                </Col>

                                <Col md='8' className='float-left'>
                                    <Field name="alternatePhone" component={renderTextField} className='form-control' type="text" validate={[required, min10, max10, onlyNumbers]} />
                                </Col>
                            </Col>


                            <Col md='6' sm='6' xs='12' className='machine-asset'>
                                <Col md='4' className='float-left'>
                                    Email Address
                                </Col>

                                <Col md='8' className='float-left'>
                                    <Field name="email" component={renderTextField} className='form-control' type="text" validate={[email]} />
                                </Col>
                            </Col>
                            <Col md='6' sm='6' xs='12' className='machine-asset'>
                                <Col md='4' className='float-left'>
                                    Machine Sale Date <span className='required'>*</span>
                                </Col>

                                <Col md='8' className='d-flex justify-content-start align-items-center float-left'>
                                    {/* <Field  name="tractorSaleDate"  className='form-control' component={renderDatepicker} validate={[required,maxCurrentDate]}></Field>*/}
                                    <div className="float-left mr-5 position-relative">
                                        <DatePicker
                                            selected={this.state.data.tractorSaleDate}
                                            maxDate={moment()}
                                            ref={(r) => {
                                                this.tractorsaledatecomponent = r;
                                            }}
                                            autoComplete='off'
                                            onChange={this.handleChange.bind(this, 'tractorSaleDate')}
                                            dateFormat="DD MMM YYYY"
                                            id="tractorSaleDate"
                                            className="form-control pointer-event"
                                        />
                                        <button className="calLogoContract" onClick={this.tractorSDate}><FaCalendarAlt /></button>
                                    </div>
                                </Col>
                                {/*<button className="calLogoContract" onClick={() => this.endDate()}><FaCalendarAlt /></button>*/}

                            </Col>
                            <Col md='6' sm='6' xs='12' className='machine-asset'>
                                <Col md='4' className='float-left'>
                                    Emergency Contact
                                </Col>

                                <Col md='8' className='d-flex justify-content-start align-items-center float-left'>
                                    <Field name="emergencyCallFalg" id='emergencyCallFalg_yes' component={renderCustomField} className='form-control custom-reset' type="radio" value={"true"} checked={isEmergency} onClick={this.emergency} /> Yes &nbsp;

                                    <Field name="emergencyCallFalg" id='emergencyCallFalg_no' component={renderCustomField} className='form-control custom-reset' type="radio" value={"false"} onClick={this.emergency} /> No
                                </Col>
                            </Col>

                            <Col md='6' sm='6' xs='12' className='machine-asset'>
                                <Col md='4' className='float-left'>
                                    Default Language <span className='required'>*</span>
                                </Col>

                                <Col md='8' className='float-left'>
                                    <Field name="languageCode" id="languageCode" component={renderSelectField} style={{ padding: '0.65rem 0.8rem'}}>
                                        <option key ="en" value="en">English</option>
                                        {language && language.map(lang => <option key={lang.languageCode} value={lang.languageCode}>{lang.language}</option>)}
                                    </Field>
                                </Col>
                            </Col>

                            {isEmergency && <Col md='6' sm='6' xs='12' className='machine-asset'>
                                <Col md='4' className='float-left'>
                                    Emergency Contact No. <span className='required'>*</span>
                                </Col>

                                <Col md='8' className='float-left'>
                                    <Field name="emergencyCallNumber" component={renderTextField} className='form-control' type="text" validate={[min10, max10, onlyNumbers, required]} />
                                </Col>
                            </Col>}

                            <Col md='6' sm='6' xs='12' className='machine-asset'>
                                <Col md='4' className='float-left'>
                                    Default Timezone <span className='required'>*</span>
                                </Col>
                                
                                <Col md='8' className='float-left'>
                                    <Field id="timezone" name="timezone" component={renderSelectField} style={{ padding: '0.65rem 0.8rem'}}>
                                        <option key="Asia/Kolkata" value="Asia/Kolkata" selected>Asia/Kolkata</option>
                                        {timezone.map(tz => <option key={tz.timezone} value={tz.timezone}>{tz.timezone}</option>)}
                                    </Field>
                                </Col>
                            </Col>

                            <Col className='machine-button' md='12'>
                                <Col md='4' className='float-left'>
                                    {preview ? <button type="submit" className="btn btn-primary ignore">Register</button> : <button type="submit" className="btn btn-primary ignore">Preview</button>}
                                </Col>

                                <Col md='8' className='float-left'>
                                    <button type="button" className="btn btn-danger float-right ignore" onClick={() => this.changeDetails(preview)}>Cancel</button>
                                </Col>
                            </Col>
                        </Row>

                    </section>
                </form>
            </Fragment>

        );
    }
}

const mapDispatchToProps = {
    setToastr, setLoader, setConfirmBox
}

export default connect(null, mapDispatchToProps)(reduxForm({ form: form, asyncValidate, asyncBlurFields: ['loginId'] })(withRouter(NewOwner)));