import React, { Component } from 'react';
import WorkReportComponent  from './ReportTabComponent/WorkReportComponent';
import UtilizationReportComponent  from './ReportTabComponent/UtilizationReportComponent';
import { Col, Row, Nav, NavItem, NavLink, TabContent, TabPane} from 'reactstrap';
import {FaClock, FaChartArea } from 'react-icons/fa'
import classnames from 'classnames';
import { withRouter } from 'react-router-dom';
import { setToastr,setLoader } from "../../../actions/Application";
import { connect } from "react-redux";
import { UserActivity } from "../../../api/header.js";

/* A Reports component */
class ReportsComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: {
            },
            activeTab: '1',
            equipment : '',
            equipmentData:[],
            loader : false,
            fromPage:'',
            errorAPI: false,
        };
        this.toggle = this.toggle.bind(this);
    }

    componentWillMount(){
        let equipmentData = JSON.parse(localStorage.getItem('equipmentData'));
        this.getNumberPlate(equipmentData);
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }
 
    getNumberPlate(equipmentData) {
        let data = [];
        if(equipmentData){
            //Number Plate Option
            let numberPlate = equipmentData.equipmentData.map(function (item, index) {
                if(item.status=='TE' && item.numberPlate != null)
                return <option key={index} value={item.machineSaleId}> {(item.numberPlate ? item.numberPlate.toUpperCase() : " ")} </option>;
            });
            //Model Machine Number
            let modelMachine = equipmentData.equipmentData.map(function (item, index) {
                return <option key={index} value={item.machineSaleId}> {item.model}/{item.machineNum} </option>;
            });
            data =  [numberPlate,modelMachine,equipmentData];
        }
        this.setState({ equipmentData : data})        
    }

    componentWillReceiveProps(nextProps) {
        if (this.props !== nextProps) {
            if(nextProps.equipment.data){
                this.setState({loader:nextProps.equipment.data.isSending})
            }
            if(nextProps.equipment.data.SET_EQUIPMENT_DATA){
                this.getNumberPlate({ equipmentData : nextProps.equipment.data.SET_EQUIPMENT_DATA});
            }
            if(this.props.equipment.data.SET_EQUIPMENT_DATA !== nextProps.equipment.data.SET_EQUIPMENT_DATA){
                if(this.props.profile.userActivityFlag == true){
                    const {location} = this.props;
                    const toUrl = location.pathname; 
                    const fromUrl = (this.props.location && this.props.location.state && this.props.location.state.prevPath) || 'No path';
                    this.trackUserActivity('Success',(`Successfully navigated from ${fromUrl} to ${toUrl}`));
                }
            }
            if (this.props.apiError !== nextProps.apiError) {
                if (nextProps.apiError !== false) {
                    this.setState({
                        errorAPI: true,
                        showLoader: false
                    })
                    if (this.props.profile.userActivityFlag == true) {
                        const { location } = this.props;
                        const toUrl = location.pathname;
                        const fromUrl = (this.props.location && this.props.location.state && this.props.location.state.prevPath) || 'No path';
                        this.trackUserActivity('Failed',(`Failed navigation from ${fromUrl} to ${toUrl}`));
                    }
                }
            }
        }
    }

    handleChange(data,type) {
        this.props.handleChange(data,type);
    }

    trackUserActivity(pageActivity,remarks) {
        const {location} = this.props;
        const fromUrl = (this.props.location && this.props.location.state && this.props.location.state.prevPath) || 'No path';
        const toUrlPath = location.pathname.split('/customer/')[1] || '';
        let fromUrlPath = fromUrl ? fromUrl.split('/customer/')[1]:'';
        const pathNames = {
            "manage-daily-page": "Manage Daily Work",
            "equipment": "My Equipments",
            "location-information": "Location Information",
            "alert-setting": "Alert Setting",
            "reports": "Manage Reports",
            "service-history": "Equipment Service History",
            "area-measurement": "Area Measurement",
            "members" : "My Members",
            "member_management": "Member Management",
            "my-account": "My Account"
            // Add other mappings here as needed
        };
        const convertToTitleCase = (str) => {
            if(!str){
                return '';
            }
            
            return str
                .replace(/-/g, ' ') // Replace hyphens with spaces
                .replace(/\b\w/g, char => char.toUpperCase()); // Capitalize first letter of each word
        };

        let defaultFromPage = fromUrl === '/customer' ? 'Login' : '';
        let defaultToPage = location.pathname === '/customer' ? 'Login' : '';
        let fromUrlName = pathNames[fromUrlPath] || convertToTitleCase(fromUrlPath) || defaultFromPage;
        let toUrlName = pathNames[toUrlPath] || convertToTitleCase(toUrlPath) || defaultToPage;

        let userActivityData = {
            userID: this.props.profile.id,
            userName: this.props.profile.firstName,
            sourceType: 'web',
            fromPage: fromUrlName,
            toPage: toUrlName,
            pageActivity: pageActivity,
            remarks: remarks
        }
        UserActivity(userActivityData)
            .then(result => {
                if (result.statusCode == 200) {
                    this.setState({fromPage:fromUrl});
                }
            })
            .catch(error => {
                console.error('Error in user activity API', error);
            })
    }

    render() {
        const { translate } = this.props;
        return (
            <div className="colorwhite reports">
                <Row className="toprow">
                    <Col  lg="12">
                        <Nav tabs>
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: this.state.activeTab === '1' }) }
                                    onClick={() => { this.toggle('1'); } }
                                    >
                                    <div>
                                        <FaChartArea/>
                                        <div className="tabheading">{translate('workReport') }</div>
                                        <div>{translate('daysMonthly') }</div>
                                    </div>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: this.state.activeTab === '2' }) }
                                    onClick={() => { this.toggle('2'); } }
                                    >
                                    <div>
                                        <FaClock/>
                                        <div className="tabheading">{translate('utilizationReport') }</div>
                                        <div>{translate('daysMonthly') }</div>
                                    </div>
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <TabContent activeTab={this.state.activeTab}>
                            <TabPane tabId="1">
                                <Row>
                                    <Col lg={12}>
                                        { this.state.activeTab === '1' && 
                                        <WorkReportComponent 
                                            handleChange={ this.handleChange.bind(this) } 
                                            langtranslate={ translate }
                                            showLoader = { this.state.loader }
                                            numberPlateOption = { this.state.equipmentData }
                                                {...this.props}
                                                translate={translate}
                                        />
                                        }
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tabId="2">
                                <Row>
                                    <Col lg={12}>
                                        { this.state.activeTab === '2' && 
                                        <UtilizationReportComponent
                                            handleChange={ this.handleChange.bind(this) } 
                                            langtranslate={ translate }
                                            showLoader = { this.state.loader }
                                            numberPlateOption = { this.state.equipmentData }
                                            equipments = { this.state.equipmentData[2] }
                                            { ...this.props }
                                            
                                        />
                                        }
                                    </Col>
                                </Row>
                            </TabPane>
                        </TabContent>
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return({
        profile: state.profile,
        apiError : state.equipment.FETCH_DATA_FAIL || false,
    });
}

const mapDispatchToProps = {
    setToastr,
    setLoader
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ReportsComponent));
//export default ReportsComponent;
