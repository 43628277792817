import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import InputField from '../components/shared/InputField';
import ButtonField from '../components/shared/ButtonField';
import Validator from 'validator';
import InlineError from '../messages/InlineErrors/Inline';
import profileImage from '../assets/images/logo.jpg';
import { Alert, Form, FormGroup, Card, CardBody, Label } from 'reactstrap';


/** An dump login component */
class ResetPasswordForm extends Component {
	constructor(props) {
        super(props);
        this.state = {
			data: {
				username: '',
				password: '',
				cpassword: '',
			},
			loading: false,
			showPopUp: false,
			errors: {},
			serverError: '',
			expired: '',
			wrongDetail: false
		};

	}
    /**
     * Get and validate the user inputs pass to the parent components
     * @param  { Object } e Contains the user data
     */
	handleSubmit(e) {
		e.preventDefault();

		let {token} = this.props.match.params, {cpassword, ...data} = this.state.data;
		
		const errors = this.validate(this.state.data);
		this.setState({ errors });
		if (Object.keys(errors).length === 0) {
			this.setState({ showLoader: true });
			this.props.resetPassword(token, data)
		}
	}

	/**
	 * Validate the user inputs
	 * @param  { Object } data User inputs
	 */
	validate(data) {
		const errors = {};
		let {translate} = this.props;
		if (Validator.isEmpty(data.password)) {
			errors.password = translate('passwordCanNotEmpty');
			this.setState({ isValidPassword: true })
		} else {
			if(data.password.length <= 5){
				errors.password = translate('passwordShouldHave6AlphaNumeric');
				this.setState({ isValidPassword: true })
			}else{
				this.setState({ isValidPassword: false })
			}
		}

		if(!data.username){
			this.setState({ username: true })
			errors.username 	= translate('memberIdCanNotEmpty');
		}

		if (!data.cpassword) {
			this.setState({ isValidcPassword: true })
			errors.cpassword =translate('confirmPasswordCanNotEmpty'); ;
		}else{
			if(data.password !== data.cpassword){
				errors.cpassword =translate('confirmPasswordDoesNotMatch'); 	
			}
		}

		return errors;
	}
	/**
	 * Set the state of user input on change
	 * @param  { Object } e Contains the user data
	 */
	handleChange(e) {
		e.preventDefault();
		this.setState({
			data: {
	    		...this.state.data, [e.target.name]: e.target.value
	    	} 
		});
	}

	componentWillReceiveProps(newProps){
		if(newProps.error){
			this.setState({ expired: newProps.error });
		}

		if(newProps.success){
			let fields = document.getElementsByClassName("form-control");
			for(let f in fields){
				if(fields[f] && fields[f].value) fields[f].value = '';
			}
		}
	}

	render() {
		const { expired } = this.state, {success,translate} = this.props;

		return (
			<div className="view loginView">
				<div className="box-login">
					<Card className="mb-3 form-card">
						<div className="topheader"><img src={ profileImage } alt="avatar"/></div>
						<CardBody>
						
							{expired && <Alert color="danger"><InlineError text={expired}/></Alert>} 
							{success && <Alert color="success">{success}</Alert>} 
							
							<Form className="resetpwforms" onSubmit={this.handleSubmit.bind(this) }>
								<FormGroup>
									<Label>{translate('userID')}</Label>
									<InputField
										name="username"
										type="text"
										id="username"
										handleChange={ this.handleChange.bind(this) }
										/>
									{ this.state.username && <InlineError text={ this.state.errors.username || '' }/> }
								</FormGroup>
								<FormGroup>
									<Label>{translate('newPassword')}</Label>
									<InputField
										name="password"
										type="password"
										id="password"
										handleChange={ this.handleChange.bind(this) }
										/>
									{ this.state.isValidPassword && <InlineError text={ this.state.errors.password || '' }/> }
								</FormGroup>
								<FormGroup>
									<Label>{translate('confirmPassword')}</Label>
									<InputField
										name="cpassword"
										id="cpassword"
										type="password"
										handleChange={ this.handleChange.bind(this) }
										formGroupClass={ `mb-0` }
										/>
									{ this.state.isValidcPassword && <InlineError text={ this.state.errors.cpassword || '' }/> }
								</FormGroup>
								
								<FormGroup className="text-right">
									<ButtonField
										type="submit"
										name = "resetbtn"
										id = "resetbtn"
										className = "btn-danger myloginbtn"
										buttonText = {translate('resetPassword') }
										handleClick = {this.handleSubmit.bind(this) }
										/>
								</FormGroup>

								
							</Form>

							<div className="text-right">
								<Link to="/" className="text-red">{translate('backToLogin')  }</Link>
							</div>
						</CardBody>
					</Card>
				</div>
			</div>
		);
	}
}

export default withRouter(ResetPasswordForm);