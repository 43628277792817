import React from 'react';
import { renderTextField  } from "../../shared/renderField";
import { Field, reduxForm, reset } from "redux-form";
import ItemSelection from "./ItemSelection";
import { Row, Col ,Card,CardBody} from "reactstrap";
let form = "SearchCustomMapForm";


const SearchFormComponent = ({ handleSubmit, submitting, onSearch, qs, clearSearch, dispatch,getEquipmentList,searchItems,goToItem,keyword,fieldType }) => {
    return (
        <form name={form} id={form} autoComplete='off' onSubmit={handleSubmit(onSearch)}>
            <Row className="members align-items-center form-fota">
                <Col sm="12">
                    <div className="row profile-asset">
                        <div className="col-sm-4">Model
                            <Field name="model" id='searchmodel' component={renderTextField} className='form-control' type="text" onChange={getEquipmentList} placeholder="Enter Model No."/>
                            {keyword.length > 0 && searchItems.length > 0 && fieldType == 'searchmodel' &&
                                <div className='search-area'>
                                    <Card className='search-card'>
                                        <CardBody style={{overflowY:"auto" , maxHeight:"300px"}}>
                                            <ul className="list-group list-group-flush">
                                                {searchItems.map((item, i) => {
                                                    return <ItemSelection key={i} attr={{ item }} goToItem={goToItem} fieldType={'model'} />;
                                                })}
                                            </ul>
                                        </CardBody>
                                    </Card>
                                </div>}
                            {keyword.length > 0 && searchItems.length == 0 && fieldType === 'searchmodel' &&
                                <div className='search-area'>
                                    <Card className='search-card'>
                                        <CardBody>
                                            <ul className="list-group list-group-flush">
                                                <li className="list-group-item pointer" >
                                                    <Row>
                                                        <Col sm='12' className='no-padd-lr'>
                                                            <span>No record found</span>
                                                        </Col>
                                                    </Row>
                                                </li>
                                            </ul>
                                        </CardBody>
                                    </Card>
                                </div>
                            }
                        </div>

                        <div className="col-sm-4">Machine Number
                            <Field name="machineNum" id='searchmachineNum' component={renderTextField} className='form-control' type="text" onChange={getEquipmentList} placeholder="Enter Machine No."/>
                            {keyword.length > 0 && searchItems.length > 0 && fieldType == 'searchmachineNum' &&

                                <div className='search-area'>
                                    <Card className='search-card'>
                                        <CardBody style={{overflowY:"auto" , maxHeight:"300px"}}>
                                            <ul className="list-group list-group-flush">
                                                {searchItems.map((item, i) => {
                                                    return <ItemSelection key={i} attr={{ item }} goToItem={goToItem} fieldType={'machineNum'} />;
                                                })}
                                            </ul>
                                        </CardBody>
                                    </Card>
                                </div>}
                            {keyword.length > 0 && searchItems.length == 0 && fieldType === 'searchmachineNum' &&
                                <div className='search-area'>
                                    <Card className='search-card'>
                                        <CardBody>
                                            <ul className="list-group list-group-flush">
                                                <li className="list-group-item pointer" >
                                                    <Row>
                                                        <Col sm='12' className='no-padd-lr'>
                                                            <span>No record found</span>
                                                        </Col>
                                                    </Row>
                                                </li>
                                            </ul>
                                        </CardBody>
                                    </Card>
                                </div>
                            }
                        </div>

                        <div className="col-sm-4">
                            <button type="submit" className="btn btn-success srch-btn" disabled={submitting}>Search</button>&nbsp;

                            {qs && Object.keys(qs).length > 0 && <button type="button" className="btn btn-danger srch-btn" onClick={() => {
                                dispatch(reset(form));
                                clearSearch();
                            }} disabled={submitting}>Clear Search</button>}
                        </div>

                    </div>
                </Col>
            </Row>
        </form>
    );
}

export default reduxForm({ form })(SearchFormComponent);