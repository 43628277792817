import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import _find from 'lodash/find';
import alertIcon from '../../../assets/images/my-equipment-page-icons/Asset6xxhdpi.png';
import { getEquipmentEventsData } from '../../../actions/Equipment';
import { bellToggle } from "../../../actions/Application";
import session from "../../../services/session";

/* A Alert Icon Button component */
class AlertIconButton extends Component {
    constructor(props) {
        super(props)
        this.state = {
            equipmentAlertData : [],
            alertNotibox: false
        };
    }

     componentDidMount() {
        let routeList = (session && session.get("prev", "cookie")) || []
        let hasEquipmentAlerts = _find(routeList, { uuid: '/service/equipment-alerts' });
        let isService= hasEquipmentAlerts?true:false;
       this.props.getEquipmentEventsData(isService)
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps !== this.props) {
            this.setState({
                equipmentAlertData : nextProps.equipmentAlertData
            })
        }
    }

    render() {
        const { showBell } = this.props;

        return (
            <Fragment>
                {showBell && <div className="alertbox rounded-circle" onClick = {this.openAlertNotiBox.bind(this) }>
                    <img src={ alertIcon } alt="Bell Icon"/>
                    <span className="alertbad">
                        {this.state.equipmentAlertData.SET_EQUIPMENT_EVENTS_LIST_DATA && this.state.equipmentAlertData.SET_EQUIPMENT_EVENTS_LIST_DATA.length ? (this.state.equipmentAlertData.SET_EQUIPMENT_EVENTS_LIST_DATA.length > 99 ) ? "99+" : this.state.equipmentAlertData.SET_EQUIPMENT_EVENTS_LIST_DATA.length : 0}
                    </span>
                    {
                        this.state.alertNotibox &&
                        <div className="alertboxdata">
                            <div className="scrollBox">{ this.getNotificationAlert() }</div>
                        </div>
                    }
                </div>}
            </Fragment>
        );
    }

    openAlertNotiBox() {
        this.setState({ alertNotibox: !this.state.alertNotibox })
    }

    getNotificationAlert() {
        let alertData = "";
        if (this.state.equipmentAlertData.SET_EQUIPMENT_EVENTS_LIST_DATA && this.state.equipmentAlertData.SET_EQUIPMENT_EVENTS_LIST_DATA.length > 0) {
            alertData = this.state.equipmentAlertData.SET_EQUIPMENT_EVENTS_LIST_DATA.map((value, index) => {
                let type = (value.eventType === "Technical Error") ? "ERROR" : value.eventType;
                let eleClass = (value.customerStatusFlag !== true) ? "unread" : "";
                return (
                    <div key={index} className={`listLineeq ${eleClass} text-left`}>
                        <div className="float-left">
                            <span className={`alerttype ${type ? type.toUpperCase() : ""}`}>{ type ? type.toUpperCase() : "" } </span> <span>{value.numberPlate}</span>
                        </div>
                        <div className="float-left w-16rem">
                            <div className="float-left">Machine No.{value.machineNum}</div>
                            <div className="float-right ml-3 timesml">{moment(value.eventDate).tz("Asia/Kolkata").format('D MMM YYYY HH:mm:ss') }</div>
                            <div className="clearfix"></div>
                        </div>
                        <div className="clearfix"></div>
                        <div className="text-secondary">{value.eventDescription}</div>
                        <div className="clearfix"></div>
                    </div>
                )
            });
        } else {
            alertData = <div className="text-center">No recent alerts</div>;
        }
        return alertData;
    }

}


/**
 * Get the data from store and provide as props to the
 * current component
 * @param  { Object } state Global states
 * @return { Object }       return the required data from store.
 */
function mapStateToProps(state) {
    return {
        showBell: state.app.showBell || false,
        equipmentAlertData : state.equipment.data,
        apiError : state.equipment.FETCH_DATA_FAIL || false
      };
}

/** @type { Object } Connect the action to be dispatched */
const mapDispatchToProps = {
    getEquipmentEventsData, bellToggle
}


export default connect(mapStateToProps, mapDispatchToProps)(AlertIconButton);
