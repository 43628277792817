import React, { Component, Fragment } from "react";
import AddFotaComponent from "./AddFotaComponent";
import { setLoader, setToastr } from "../../../actions/Application";
import {connect} from "react-redux";

class FotaComponent extends Component{

    constructor(props){
        super(props);
        this.state = {
            ack: false,
        }
    }

    getAckWhenAdded = bool => this.setState({ack: bool}, () => this.setState({ack: false}));
    render(){
        return(
            <Fragment>
                <section className='reports repo'>
                        <AddFotaComponent getAck={this.getAckWhenAdded}/>
                </section>
            </Fragment>
        );
    }
}

const mapDispatchToProps = {
    setLoader, setToastr
}

export default connect(null, mapDispatchToProps)(FotaComponent);