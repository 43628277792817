import React, { Component, Fragment } from "react";
import ReactDOM from "react-dom";
import { connect } from 'react-redux';
import { Row, Col } from "reactstrap";
import { withRouter } from "react-router-dom";
import moment from 'moment';
import { required } from "../../shared/Validation";
import { Field, reduxForm, change } from "redux-form";
import { renderTextField, renderSelectField } from "../../shared/renderField";
import { FaCalendarAlt } from 'react-icons/fa';
import { setToastr, setLoader, setConfirmBox } from "../../../actions/Application";
import DatePicker from 'react-datepicker';
import { getOwnerEquipments } from "../../../actions/admin/Owner";
import { getOwnerDetail, ownerDetailsUpdate } from "../../../api/admin/owner";
import ButtonField from '../../../components/shared/ButtonField';
import InputField from '../../../components/shared/InputField';
import Loaders from '../../../messages/loaders/Loaders';
import {FaTrashAlt} from 'react-icons/fa'

const form = "editOwnerForm";
/* chane management number->CHG-00019880*/ 
class EditOwnerDetails extends Component {
    constructor(props) {
        super(props);

        /** component state */
        this.state = {
            equipments: [],
            isEdit: false,
            ownerData: {},
            selectedMachineSaleId: '',
            selectedEquipment: null,
            dataForInit: null,
            selectedCompanyCode: '',
            selectedServiceProviderName: '',
            data: {
                contractDate: '',
                contractPeriod: null,
                currentDate: moment(),
                selectedImage: ''
            },
            applicationForm: '',
            fileName: null,
            showLoader : true,
            notify: false,
            notifyType: 'success',
            notifyText: '',

        };
        this.toggleEdit = this.toggleEdit.bind(this);
        this.editEquipmentDetails = this.editEquipmentDetails.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.getServiceCompany = this.getServiceCompany.bind(this);
        this.getOwnerDetail = this.getOwnerDetail.bind(this);
        this.contractDate = this.contractDate.bind(this);
        this.contractPeriod = this.contractPeriod.bind(this);
        this.fileUpload = React.createRef();
        this.handleImageChange = this.handleImageChange.bind(this);
    }

    componentDidMount() {
        let { machineSaleId, userId } = this.props.match.params;
        if (userId) {
            let f = document.getElementById("u2");
            if (f && f.dataset['url'] && f.dataset['url'] === '/service/owners/detail/:userId') {
                f.dataset['url'] = `/service/owners/detail/${userId}`;
            }
        }
        this.setState({ selectedMachineSaleId: machineSaleId })
        this.toggleEdit(true);
        //this.props.setLoader({ loader: { open: true } });
    }

    componentWillReceiveProps(newProps) {
        let { initialize, equipments, setLoader } = this.props;
        if (newProps.equipments && typeof newProps.user !== typeof equipments) {
            /** initialize react form */
            if (newProps.equipments.length > 0 && this.state.selectedEquipment == null) {
                let selectedEquipment = newProps.equipments.filter(a => a.machineSaleId == this.state.selectedMachineSaleId)
                this.setState({ selectedEquipment: selectedEquipment[0] })
                this.getOwnerDetail(selectedEquipment[0],null)
                initialize(selectedEquipment[0]);
            }
            setLoader({ loader: { open: false } });
        }
        if (newProps.equipments) this.setState({ equipments: newProps.equipments });
    }

    getOwnerDetail(equipment,selectedServiceProviderName) {
        let { dispatch, setToastr, setLoader } = this.props;
        setLoader({ loader: { open: true } });
        getOwnerDetail(equipment.machineSaleId)
            .then(response => {
                setLoader({ loader: { open: false } });
                if (response.statusCode != 200) {
                    setToastr({ toastr: { message: "Something went wrong", type: "error" } });
                    return;
                }
                this.setState({ ownerData: response.data })
                let ownerData = response.data;
                this.setState({ applicationForm: ownerData.applicationForm })
                let serviceProviders = ownerData.serviceProviders;
                let serviceProvider = selectedServiceProviderName ? selectedServiceProviderName : equipment.companyNames[0].companyName;
                let filtered = serviceProviders.filter(a => a.childCompanyName == serviceProvider)
                setTimeout(() => {
                    dispatch(change(form, "serviceProvider", filtered[0].childCompanyCode));
                    this.setState({ selectedServiceProviderName: filtered[0].childCompanyName , showLoader: false })
                }, 500);

                let equipments = { ...equipment, ...response.data }
                this.props.initialize(equipments);
                this.setState({ dataForInit: equipments, selectedCompanyCode: filtered[0].childCompanyCode })
            })

    }


    editable(e) {
        e.preventDefault();
        this.toggleEdit();
    }

    toggleEdit(type = false) {
        this.setState({ isEdit: !type, preview: type });

        let filledForm = document.forms[form];

        for (let i = 0; i < filledForm.elements.length; i++) {
            if (filledForm.elements[i].className && filledForm.elements[i].className.indexOf("ignore") < 0) filledForm.elements[i].disabled = type;
        }
    }

    resetForm(isEdit = true) {
        
        let { dispatch } = this.props;
        if (!isEdit) {
            this.props.history.goBack();
        } else {
            this.toggleEdit(true);
            this.props.initialize(this.state.dataForInit);
            dispatch(change(form, "serviceProvider", this.state.selectedCompanyCode));
            this.setState({
                ownerData: {
                    ...this.state.ownerData, contractDate:this.state.dataForInit.contractDate,contractPeriod:this.state.dataForInit.contractPeriod
                }
            })
        }
    }

    confirmnDelete() {
        this.setState({
            fileName: '',
            data: {
                ...this.state.data, selectedImage: ''
            }
        })
    }

    handleChange(type, data) {
        if (data === undefined) {
            data = type.target.value;
            type = type.target.name;
        }
        this.setState({
            ownerData: {
                ...this.state.ownerData, [type]: data
            }
        })
    }

    contractDate(e) {
        e.preventDefault();
        this.startcomponent.setOpen(true);
    }

    contractPeriod(e) {
        e.preventDefault();
        this.endcomponent.setOpen(true);
    }

    onDismiss() {
        this.setState({ notify: false });
    }

    updateUserState(updatedData) {
        let keys = Object.keys(updatedData), { user } = this.state;
        keys.forEach(key => user[key] = updatedData[key]);
        return user;
    }

    getServiceCompany() {
        let serviceProvider = ReactDOM.findDOMNode(document.getElementById("serviceProvider")).value
        let selectedServiceProvider = this.state.ownerData.serviceProviders.filter(a => a.childCompanyCode == serviceProvider)
        this.setState({ selectedServiceProviderName: selectedServiceProvider[0].childCompanyName })

    }

    editEquipmentDetails(values) {
        let {setLoader, setToastr } = this.props, { userId, machineSaleId } = this.props.match.params, { ownerData, user } = this.state, data;

        setLoader({ loader: { open: true } });
        /** Updating Customer Details */
        let selectedImage;
        if (this.state.data.selectedImage !== "") {
            selectedImage = this.state.data.selectedImage;
        }
        data = {
            serviceProviders: this.state.selectedServiceProviderName,
            contractDate: this.state.ownerData.contractDate,
            contractPeriod: this.state.ownerData.contractPeriod,
            machineSaleId: values.machineSaleId,
            contractNumber: values.contractNumber,
            remark: values.remark,
            applicationForm: selectedImage
        };
        this.props.setLoader({ loader: { open: true } });

        ownerDetailsUpdate(data)
            .then(response => {
                //setLoader({ loader: { open: false } });
                if (response.data && response.data !== 'false') {   
                    setToastr({ toastr: { message: "Equipment details have been updated.", type: "success" } });
                    setLoader({ loader: { open: false } });
                } else {
                    setToastr({ toastr: { message: "Failed to update details. Please try again later.", type: "error" } });
                    setLoader({ loader: { open: false } });
                }
                /** close editing & loader*/
                //setLoader({ loader: { open: false }, user: this.updateUserState(data) });
                this.toggleEdit(true);
                this.getOwnerDetail(this.state.selectedEquipment,this.state.selectedServiceProviderName);
            })
            .catch(error => {
                setToastr({ toastr: { message: error || "Failed to update details. Please try again later.", type: "error" } });
                setLoader({ loader: { open: false } });
            })

    }

    handleImageUpload(e) {
        e.preventDefault();
        const uploadElement = ReactDOM.findDOMNode(this.fileUpload);
        uploadElement.click()
        //this.setState({ showCtrl: true });
    }
    handleImageChange(e) {
        e.preventDefault();
        if (e.target.type === "file" && e.target.files.length > 0) {
            if (e.target.files[0].type === "image/jpeg" || e.target.files[0].type === "image/png") {
                this.setState({ fileName: e.target.files[0].name })
                var reader = new FileReader();
                let componentInstance = this;
                reader.onload = (function (theFile) {
                    return function (e) {
                        var i = new Image();
                        i.src = e.target.result;
                        i.onload = () => {
                            componentInstance.setState({
                                data: {
                                    ...componentInstance.state.data, selectedImage: e.target.result.replace(/^data:image\/(png|jpg|jpeg);base64,/, '')
                                },
                            });
                        }
                    };
                })(e.target.files[0]);
                reader.readAsDataURL(e.target.files[0]);
            }
            else {
                this.props.setToastr({ toastr: { message: "Please upload form in jpg or png format only.", type: "info" } });
                return
            }
        } else {
            if (!(this.validEntry(e.target.value) === null)) {
                this.setState({
                    data: {
                        ...this.state.data, [e.target.name]: e.target.value
                    }
                });
            }
        }
        e.target.value = null;
    }

    validEntry(s) {
        if (s.length !== 0) {
            return s.match(/^[0-9a-zA-Z]+$/)
        } else {
            return true
        }
    }

    render() {
        let { handleSubmit } = this.props, { isEdit, equipments, ownerData } = this.state;
        return (
            <div className="ssa">
            { this.state.showLoader && <Loaders
                loadingText={ `Please Wait...` }
                modalOpen={ true }
                className={ `loading-contents` }
                color={ '#08a5e1' }
                /> 
            } 
            <Fragment>
                <form onSubmit={handleSubmit(this.editEquipmentDetails)} autoComplete='off' className='form-horizontal' id={form}>
                    <section className='member-machine-info'>
                        <Row className='no-gutters'>
                            {<span className='sub-heading'>Equipment Information</span>}
                            <Field component={renderTextField} type='hidden' />
                            <Col md='6' sm='6' xs='12' className='machine-asset'>
                                <Col md='4' className='float-left'>
                                    Model <span className='required'>*</span>
                                </Col>

                                <Col md='8' className='float-left'>
                                    <Field name="model" id="model" component={renderTextField} className='form-control ignore' type="text" disabled validate={[required]} value="12234" />
                                </Col>
                            </Col>
                            <Col md='6' sm='6' xs='12' className='machine-asset'>
                                <Col md='4' className='float-left'>
                                    SA-R ID <span className='required'>*</span>
                                </Col>

                                <Col md='8' className='float-left'>
                                    <Field name="terminalId" component={renderTextField} className='form-control ignore' type="text" disabled validate={[required]} />
                                </Col>
                            </Col>

                            <Col md='6' sm='6' xs='12' className='machine-asset'>
                                <Col md='4' className='float-left'>
                                    Machine Number <span className='required'>*</span>
                                </Col>

                                <Col md='8' className='float-left'>
                                    <Field name="machineNum" id="machineNum" component={renderTextField} className='form-control ignore' type="text" disabled validate={[required]} onBlur={this.getMachineInfo} />
                                </Col>
                            </Col>

                            <Col md='6' sm='6' xs='12' className='machine-asset'>
                                <Col md='4' className='float-left'>
                                    Equipment Type <span className='required'>*</span>
                                </Col>

                                <Col md='8' className='float-left'>
                                    <Field name="equipmentType" component={renderTextField} className='form-control ignore' type="text" disabled validate={[required]} />
                                </Col>
                            </Col>

                            <Col md='6' sm='6' xs='12' className='machine-asset'>
                                <Col md='4' className='float-left'>
                                    Service Provider <span className='required'>*</span>
                                </Col>

                                <Col md='8' className='float-left'>
                                    <Field name="serviceProvider" id="serviceProvider" component={renderSelectField} validate={[required]} onChange={this.getServiceCompany} style={{ padding: '0.65rem 0.8rem'}}>
                                        <option value="">Select Provider</option>
                                        {this.state.ownerData && this.state.ownerData.serviceProviders && this.state.ownerData.serviceProviders.map((serviceProvider, index) => <option key={index} value={serviceProvider.childCompanyCode}>{serviceProvider.childCompanyName}</option>)}
                                    </Field>
                                </Col>
                            </Col>
                        </Row>
                    </section>
                    <section className='member-contract-info margin-up'>
                        <Row className='no-gutters'>
                            {<span className='sub-heading'>Contract Information</span>}
                            <Col md='6' sm='6' xs='12' className='machine-asset'>
                                <Col md='4' className='float-left'>
                                    Application Date 
                                </Col>

                                <Col md='8' className='d-flex justify-content-start align-items-center float-left' >
                                    <div className="float-left mr-5 position-relative">
                                        <DatePicker
                                            selected={this.state.ownerData.applicationDate && moment(this.state.ownerData.applicationDate)}
                                            autoComplete='off'
                                            dateFormat="DD MMM YY"
                                            id="applicationDate"
                                            className="form-control pointer-event"
                                            readOnly={true}
                                        />
                                    </div>
                                </Col>
                            </Col>

                            <Col md='6' sm='6' xs='12' className='machine-asset'>
                                <Col md='4' className='float-left'>
                                    Contract Number <span className='required'>*</span>
                                </Col>

                                <Col md='8' className='float-left'>
                                    <Field name="contractNumber" id="contractNumber" component={renderTextField} className='form-control' type="text" validate={[required]} onBlur={this.getMachineInfo} />
                                </Col>
                            </Col>

                            <Col md='6' sm='6' xs='12' className='machine-asset'>
                                <Col md='4' className='float-left'>
                                    Contract Period<span className='required'>*</span>
                                </Col>
                                <Col md='8' className='d-flex justify-content-start align-items-center float-left '>
                                    {/*<div className='float-left col-md-8'>*/}
                                    <div className="float-left mr-4 position-relative">
                                        <DatePicker
                                            selected={this.state.ownerData.contractDate && moment(this.state.ownerData.contractDate)}
                                            ref={(r) => {
                                                this.startcomponent = r;
                                            }}
                                            autoComplete='off'
                                            onChange={this.handleChange.bind(this, 'contractDate')}
                                            dateFormat="DD MMM YYYY"
                                            id="contractDate"
                                            required="true"
                                            className="form-control pointer-event"
                                        />
                                        <button className="calLogoContract" onClick={this.contractDate}><FaCalendarAlt /></button>
                                    </div>
                                    <div className="float-left mr-1 position-relative ignore" style={{ left: '6px' }}>
                                        <DatePicker
                                            selected={this.state.ownerData.contractPeriod && moment(this.state.ownerData.contractPeriod)}
                                            //minDate={moment(new Date())}
                                            minDate={moment(this.state.ownerData.contractDate)}
                                            ref={(r) => {
                                                this.endcomponent = r;
                                            }}
                                            autoComplete='off'
                                            onChange={this.handleChange.bind(this, 'contractPeriod')}
                                            dateFormat="DD MMM YYYY"
                                            id="contractPeriod"
                                            required="true"
                                            className="form-control pointer-event"
                                        />
                                        <button className="calLogoContract" onClick={this.contractPeriod}><FaCalendarAlt /></button>
                                    </div>
                                    {/*</div>*/}
                                </Col>
                            </Col>

                            <Col md='6' sm='6' xs='12' className='machine-asset'>
                                <Col md='4' className='float-left'>
                                    Application form
                                </Col>

                                {isEdit ? <Col md='8' className='float-left'>

                                        <div className="float-left col-sm-8 px-0">
                                            <InputField
                                                className="d-none"
                                                handleChange={this.handleImageChange.bind(this)}
                                                name="equpimage"
                                                type="file"
                                                id="equpimage"
                                                elementRef={input => this.fileUpload = input}
                                            />
                                            {/* <Field name="applicationForm" id="applicationForm" component={FileInput} className='form-control' type="file" accept='image/*'/>*/}
                                            <div><ButtonField className="btn-success" handleClick={this.handleImageUpload.bind(this)} clickEvent={true} buttonText={'Upload form'} /></div>
                                            <div>{this.state.fileName ? this.state.fileName : ' '}
                                                &nbsp;&nbsp;
                                                &nbsp;&nbsp;
                                                {this.state.fileName && <FaTrashAlt onClick={() => this.confirmnDelete()} />}
                                            </div>
                                        </div>

                                </Col> : <a style={{ marginLeft: "15px" }} id="preview" target="_blank" href={this.state.applicationForm}>Preview Uploaded File</a>}
                            </Col>

                            <Col md='6' sm='6' xs='12' className='machine-asset'>
                                <Col md='4' className='float-left'>
                                    Remarks <span className='required'>*</span>
                                </Col>

                                <Col md='8' className='float-left'>
                                    <Field name="remark" id="remark" component={renderTextField} className='form-control' type="text" disabled validate={[required]} />
                                </Col>
                            </Col>
                        </Row>
                        <Col className='machine-button' md='12'>
                            <Col md='2' className='float-left' style={{ marginTop: '-50px' }}>
                                {/*<button type="button" className="btn btn-danger srch-btn ignore" onClick={() => this.props.history.goBack()}>Cancel</button>*/}
                                {isEdit && <button type="button" className="btn btn-danger srch-btn ignore" onClick={() => this.resetForm(isEdit)}>Cancel</button>}
                            </Col>
                            <Col md='4' className='float-left' style={{ marginTop: '-29px' }}>
                                {isEdit ? <button type="submit" className="btn btn-success ignore">Update</button> : <button type="button" className="btn btn-primary  ignore" onClick={this.editable.bind(this)}>Edit Details</button>}
                            </Col>
                        </Col>
                    </section>
                </form>
            </Fragment>
        </div>
        );
    }
}
const mapStateToProps = state => {
    return ({
        equipments: state.owner.equipments
    });
}
const mapDispatchToProps = {
    setToastr, setLoader, setConfirmBox, getOwnerEquipments
}
export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({ form: form })(withRouter(EditOwnerDetails)));