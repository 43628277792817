import React, { Fragment, Component, createRef } from "react";
import ReactDOM from "react-dom";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { required, email, max10, min10, onlyNumbers } from "../../../shared/Validation";
import Image from "../../../shared/Image";
import { renderTextField, renderCustomField, FileInput, renderSelectField  } from "../../../shared/renderField";
import { updateProfile } from "../../../../api/profile";
import { setToastr, setLoader } from "../../../../actions/Application";
import { setProfile } from "../../../../actions/Profile";
import { Row, Col } from "reactstrap";
import { FaPlusCircle, FaPencilAlt } from "react-icons/fa";

class MyAccountDetails extends Component {
    constructor(props){
        super(props);

        this.state = {alertRequired: undefined}

        this.onSubmit = this.onSubmit.bind(this);
        this.uploadFile = this.uploadFile.bind(this);
        this.uploader = createRef();
        this.handleKeyDown = this.handleKeyDown.bind(this);
    }

    handleKeyDown =(event) =>{
        if(event.key ==='Enter'){
            event.preventDefault();
        }
    }

    onSubmit(values){
        if(!values.emailFlag && !values.mobileFlag){
            this.setState({ alertRequired: true });
            return false;
        }else{
            this.setState({ alertRequired: false });
        }

        let {unit, ...formdata} = values, {setLoader, setToastr, setProfile, cancelEdit} = this.props;
        /** display loader */
        setLoader({loader:{open:true}});
        /** set other values */
        formdata.distanceUnitDisplay = unit.split("/")[0];
        formdata.volumeUnitDisplay = unit.split("/")[1];
        
        let uriregex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/i.test(formdata.photo);

        if(uriregex){
            /** remove photo from data */
            delete formdata.photo;
        }

        updateProfile(formdata)
            .then(result =>  {
                /** hide loader */
                setLoader({loader:{open:false}});

                /** display success message */
                setToastr({ toastr: { message: this.props.translate('profileUpdated'), type: "success" } })

                /** update store */
                setProfile(formdata);

                /** close edit screen */
                cancelEdit();
            })
            .catch(error => {
                this.props.setToastr({ toastr: { message: error || "This request couldn't be completed. Try again later.", type: "error" } });

                this.props.setLoader({ loader: { open: false } });
            });
    }

    componentDidMount(){
        let { initialize, profile} = this.props;
        /** init form with values */
        profile.unit = profile.distanceUnitDisplay + "/" + profile.volumeUnitDisplay;

        if(profile.emailFlag || profile.mobileFlag){
            this.setState({ alertRequired: false })
        }

        initialize(profile);
    }

    uploadFile(){

        let fileUploadInput = ReactDOM.findDOMNode(this.uploader.current);

        fileUploadInput.click();
    }    

    refineImg(src){
        let i;
        if(src.indexOf("https://") === 0 || src.indexOf("http://") === 0){
            i = [src, "?t=", new Date().getTime()].join("");
        }else{
            i = src;

            if(i.indexOf("data:image") < 0){
                
                i = "data:image/jpg;base64," + src;    
            }
        }
        return i;
    }

    render(){
        const { handleSubmit, submitting, profile, language, timezone ,translate} = this.props, {alertRequired} = this.state;

        return (
            <Fragment>
                <form onSubmit={handleSubmit(this.onSubmit)} onKeyDown={this.handleKeyDown} autoComplete='off'>
                <Row className="members align-items-center">
                    <Col sm='8'>
                    <div className="row profile-asset">
                            <div className="col-sm-12">{translate('userID')}
                            <Field name="loginId" component="input" disabled className='form-control' type="text" />
                            </div>
                        </div>

                        <div className="row profile-asset">
                            <div className="col-sm-4">{translate('firstName')} <span className='required'>*</span>
                                <Field name="firstName" component={renderTextField} className='form-control' type="text" validate={[required]}/>
                            </div>
                            <div className="col-sm-4">{translate('middleName')}
                                <Field name="middleName" component={renderTextField} className='form-control' type="text" />
                            </div>
                            <div className="col-sm-4">{translate('lastName')} <span className='required'>*</span>
                                <Field name="lastName" component={renderTextField} className='form-control' type="text" validate={[required]}/>
                            </div>
                        </div>


                        <div className="row profile-asset">
                            <div className="col-sm-6">{translate('mobileNumber')} <span className='required'>*</span>
                                <Field name="phone" component={renderTextField} className='form-control' type="text" validate={[required, min10, max10, onlyNumbers]}/>
                            </div>

                            <div className="col-sm-6">{translate('alternateMobileNumber')}<span className='required'>*</span>
                            <Field name="alternatePhone" component={renderTextField} className='form-control' type="text" validate={[required, min10, max10, onlyNumbers]}/>
                            </div>
                        </div>


                        <div className="row profile-asset">
                            <div className="col-sm-12">{translate('emailAddress')} 
                                <Field name="email" component={renderTextField} className='form-control' type="text" validate={[email]}/>
                            </div>
                        </div>

                        <div className="row profile-asset">
                            <div className="col-sm-6">{translate('displayLanguage')} 
                                <Field name="languageCode" component={renderSelectField} className='form-control no-padded'>
                                    <option value="">{translate('selectLanguage')}</option>
                                    {language.map(lang => <option key={lang.languageCode} value={lang.languageCode}>{lang.language}</option>)}         
                                </Field>
                            </div>

                            <div className="col-sm-6">{translate('timeZone')} <span className='required'>*</span>
                                <Field name="timezone" component={renderSelectField} className='form-control no-padded' validate={[required]}>
                                    <option value="">{translate('selectTimezone')}</option>
                                    {timezone.map(tz => <option key={tz.timezone} value={tz.timezone}>{tz.timezone}</option>)}
                                </Field>
                            </div>
                        </div>

                        <Row className='profile-asset'>            
                            <Col sm="3" className='d-flex justify-content-start align-items-center'>
                            {translate('alert')}<span className='required'>*</span>
                            </Col>

                            <Col sm="9" className='d-flex justify-content-start align-items-center'>
                                <Field name="mobileFlag" component={renderCustomField} id="alert_mobile" className='form-control custom-reset' type="checkbox" /> {translate('sms')} &nbsp;

                                <Field name="emailFlag" component={renderCustomField} id="alert_mail" className='form-control custom-reset' type="checkbox" /> {translate('email')}
                            </Col>

                            <Col sm="3">&nbsp;</Col>
                            <Col sm="9" className='d-flex justify-content-start align-items-center'>
                                {alertRequired === true && <span class='error'>{translate('selectAtLeastOne')}</span>}
                            </Col>
                        </Row> 
                            <div style={{width:'607px'}}>
                                <Row className='profile-asset'>
                                    <Col sm="4" className='d-flex justify-content-start align-items-center'>
                                        {translate('unitDisplay')}  {profile.unit}<span className='required'>*</span>
                                    </Col>
                                    <Col sm="8" className='d-flex justify-content-start align-items-center'>
                                        <Field name="unit" component={renderCustomField} className='form-control custom-reset' type="radio" value='KM/L' id="untkml" /> {translate('displayKmVolumeL')}  &nbsp;

                                        <Field name="unit" component={renderCustomField} className='form-control custom-reset' type="radio" value='Mile/Gallon' id="untmg" />{translate('displayMileVolumeGallon')}
                                    </Col>
                                </Row>
                            </div>

                        <div className="row profile-asset">
                            <div className="col-sm-4">&nbsp;</div>
                            <div className="col-sm-8">
                                <button type="submit" className="btn btn-primary" disabled={submitting}>{translate('update')}</button> &nbsp;
                                <button type="button" className="btn btn-danger" onClick={() => this.props.cancelEdit()}>{translate('cancel')}</button> &nbsp;
                            </div>
                        </div>
                    </Col>

                    <Col sm='4' className='d-flex justify-content-center'>
                        <Col sm='9' className='d-flex member-image-upload-area justify-content-center'>
                            <div className='image-uploader text-center relative'>
                                {profile && profile.photo ? <Image src={profile.photo} className='rounded-circle user-image' alt="profile-image"/> : <FaPlusCircle size="10em" />}
                                <Field name="photo" component={FileInput} className='form-control d-none' type="file" accept='image/*' ref={this.uploader}/>
                                <p className='text-center'>{translate('memberPhoto')}</p>
                                <span className='x-time' onClick={this.uploadFile}><FaPencilAlt /></span>
                            </div>
                        </Col>
                    </Col> 
                </Row>
                </form>
            </Fragment>
        );
    }
}

const EditProfileForm = reduxForm({
    form: "editProfileForm"
})(MyAccountDetails);


const mapStateToProps = state => {
    return ({ language: state.app.language || [], timezone: state.app.timezone || [] });
}

const mapDispatchToProps = { setToastr, setProfile, setLoader };

export default connect(mapStateToProps, mapDispatchToProps)(EditProfileForm);