import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import _find from "lodash/find";
import Pagination from "../../shared/Pagination";
import { LIMIT, MapKey } from "../../../constants/Constants";
import moment from "moment";
import { CSVLink } from 'react-csv';
import maplocationPointer from '../../../assets/images/my-equipment-page-icons/tractorlocation.png';
import Stopped from '../../../assets/images/my-equipment-page-icons/location-pointer.png';
import Running from '../../../assets/images/my-equipment-page-icons/green_logo_pin.png';
import MapBox from '../../../components/shared/MapBox';
import {FaSortDown, FaSortUp, FaSort} from 'react-icons/fa';
import session from "../../../services/session";
import { getCSVDownload } from "../../../api/service-provider/equipments";
import { getSPEquipments } from "../../../actions/service-provider/equipment";
import { setLoader } from "../../../actions/Application";
import Moment from "react-moment";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import cal from '../../../assets/images/admin/calender.svg'
import 'bootstrap-daterangepicker/daterangepicker.css';

class Equipment extends Component{

    constructor(props){
        super(props);

        this.state = {
            equipments: [],
            loading: false,
            activePage:0,
            total:0,
            isMarkerShown: false,
            lat: 0,
            lon: 0,
            eventField: undefined,
            order: undefined,
            downloadEquipments: [],
            daterange: {
                startDate: moment().format("MM-DD-YYYY"),
                endDate: moment().format("MM-DD-YYYY")
            },
            hourmeterFlag: false,
            checkbox: {},
            selectedStartDate:moment().format("DD MMM YYYY"),
            selectedEndDate:moment().format("DD MMM YYYY"),
            CSVData:[],
        }

        this.getEquipments = this.getEquipments.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.onDateChange = this.onDateChange.bind(this);
        this.getCSVDownload = this.getCSVDownload.bind(this);
    }

    componentDidMount(){
        this.props.setLoader({ loader: {open:true}});
        this.props.getSPEquipments({page : 1});
    }

    getCSVDownload() {
        if (!this.state.loading) {
            this.setState({
                loading: true
            });
            let data = {
                hourmeterFlag: this.state.hourmeterFlag,
                fromDate: this.state.selectedStartDate,
                toDate: this.state.selectedEndDate
            }
            getCSVDownload(data).then(result => {
                if (result.statusCode == 200 && result.data) {
                    this.setState({
                        downloadEquipments: result.data,
                        loading: false
                    });
                    let element = document.getElementById('CSV');
                    this.simulateMouseClick(element);
                    window.location.reload();
                }
            }).catch(() => {
                this.setState({
                    loading: false
                });
            });
        }
    }

    componentWillReceiveProps(newProps){
        if(newProps.countResult){
            this.props.setLoader({ loader: {open:false}});
            this.setState({
                loading: false, 
                equipments: newProps.equipments, 
                activePage: this.state.activePage || 1,
                total: (newProps.countResult && newProps.countResult.totalCounts) ? newProps.countResult.totalCounts : this.state.total 
            });
        }else{
            this.props.setLoader({ loader: {open:false}});

            this.setState({
                loading: false, 
                equipments: [], 
                activePage: 1,
                total: 0
            });
        }
    }

    onPageChange(page){
        let { order, eventField } = this.state;
        this.props.setLoader({ loader: {open:true}});
        this.setState({loading: true, activePage: page || 1}, () => {
            this.props.getSPEquipments({page, data: {eventField, order: order === true ? "asc":"desc"}});
        });  
    }

    sortBy(eventField) {
        let { order, activePage } = this.state;
        this.setState({ eventField, order: typeof order === undefined ? true : !order }, () => {
            this.props.getSPEquipments({page:activePage, data: {eventField, order: this.state.order === true ? "asc":"desc"}});
            this.props.setLoader({ loader: {open:true}});
        });
    }

    getEquipments(page){
        /** start loader */
        this.props.setLoader({ loader: {open:true}});
        this.setState({loading: true, activePage: page || 1});
        this.props.getSPEquipments({page});
    }

    loadMap(e) {
        e.stopPropagation();
        let lat = parseFloat(e.target.parentElement.getAttribute('lat')) || 0;
        let lon = parseFloat(e.target.parentElement.getAttribute('lon')) || 0;

        
        this.setState({ lat, lon, isMarkerShown: true  })
    }

    csvExportData(){
        const Equipment_Operating_Info_Header = [
            { label: 'Machine SaleId', key: 'machineSaleId' },
            { label: 'Model', key: 'model' },
            { label: 'Machine No.', key: 'machineNum' },
            { label: 'Owner Name', key: 'ownerName' },
            { label: 'Phone No.', key: 'phone' },
            { label: 'State', key: 'state' },
            { label: 'Work Date', key: 'workDate' },
            { label: 'Hourmeter (Hrs)', key: 'hourMeter' },
            //{ label: 'Hourmeter diffrence', key: 'dayWiseDiffrence' },    
        ];  
        let header = [];
        header = Equipment_Operating_Info_Header
        if(this.state.hourmeterFlag){
            header.push({ label: 'Hourmeter diffrence', key: 'dayWiseDiffrence' })
        }else {
            if(header[header.length-1].key == 'dayWiseDiffrence'){
                header.pop();
            }   
        }
        
        return [header];
    }

    /*method for checkbox for hourmeter difference*/ 
    clickCheckBox = (type) => {
        this.setState({hourmeterFlag: type.target.checked})
    }

    /*method for cancel on daterangepicker*/ 
    onCancel(){
        let startDate = moment().format("MM-DD-YYYY"),
        endDate = moment().format("MM-DD-YYYY");
        this.setState({ daterange: {startDate, endDate}, apply : false, cancel :true })
    }

    onDateChange(event, picker){
        let startDate = moment(picker.startDate).format("MM-DD-YYYY"),
        endDate = moment(picker.endDate).format("MM-DD-YYYY");
        let selectedStartDate = moment(picker.startDate).format("DD MMM YYYY"),
        selectedEndDate = moment(picker.endDate).format("DD MMM YYYY");   
        this.setState({ daterange: {startDate, endDate}, apply : true, cancel :false , selectedStartDate:selectedStartDate,selectedEndDate:selectedEndDate});
    }

    simulateMouseClick(element) {
        element.dispatchEvent(
            new MouseEvent('click', {
                view: window,
                bubbles: true,
                cancelable: true,
                buttons: 1
            })
        )
    }

    render(){
        let {equipments, total, activePage, loading, isMarkerShown, lat, lon, eventField,daterange,selectedStartDate,selectedEndDate} = this.state, { push } = this.props.history;
        let sortType = (this.state.order) ? <FaSortUp/> : <FaSortDown/>;
        let routeList = (session && session.get("prev","cookie")) || [];
        let hasEqDetails = _find(routeList, {uuid:"/service/equipment-operating-info"});
        let opt = { 
            //onApply: this.onDateChange,
            onCancel: this.onCancel.bind(this),
            startDate: daterange.startDate,
            endDate: daterange.endDate,
            maxDate: moment(),
            showDropdowns: true,
            cancelClass: "btn-danger cancelbtn",
            opens: 'left',
            maxSpan: {
                'days': 90
            },
        }
     
        return(
            <Fragment>
                {isMarkerShown && <MapBox
                    googleMapURL = {`https://maps.googleapis.com/maps/api/js?key=${MapKey}`}
                    defaultZoom = {15}
                    isMarkerShown
                    lat={lat}
                    lon={lon}
                    icon = { maplocationPointer }
                    showMap = { () => this.setState({ isMarkerShown: false }) }
                    containerElement={ <div className="popupUI"/> } 
                    loadingElement={ <div className="popupUI"/> }
                    mapElement={ <div style={{ height: `100%` }} /> }
                    /> }
                {/*CHG-00020881 - Download CSV for selected date changes starts here */ }
                <div className="searchcomponent float-right" style={{ width: "270px" }}>
                    <DateRangePicker initialSettings={opt} onApply={this.onDateChange} >
                        <img src={cal} alt="calender" className='pointer m-2' style={{ width: "1.9rem" }}/>
                    </DateRangePicker>

                    <button className="float-right btn-primary text-uppercase m-2 btn btn-secondary" onClick={() => {
                        this.getCSVDownload()
                    }}
                    > {loading ? 'Please Wait...' : 'Download report'}
                    </button>
                    <CSVLink
                        data={this.state.downloadEquipments}
                        headers={this.csvExportData()[0]}
                        filename={moment(new Date()).format("YYYYMMDD") + "_OperatingInfoData.csv"}
                        id="CSV"
                    >
                    </CSVLink>
                </div>

                <div className="float-right m-2">
                    <div className="checkbox-group">
                        <label >
                            <input type="checkbox" onChange={event => this.clickCheckBox(event)} />
                            <span className='checkmark'></span>
                        </label>
                    </div>
                </div>
                
                <div className="containerbox float-right m-2">
                    <span style={{fontWeight:'500'}}>With difference of hourmeter</span>&nbsp;&nbsp;
                    
                    <i className="tooltip1 tooltipicon">i<span className="tooltiptext">
                        Download CSV report with difference in hourmeter of current and previous date.
                    </span></i>
                    
                </div>
                {/*CHG-00020881 - Download CSV for selected date changes ends here */ }
                <table className="tabledashboard table table-striped table-advance table-hover m-top-30">
                    <thead>
                        <tr>
                            {/* <th onClick={() => this.sortBy('numberPlate')}>
                                Number Plate
                                { eventField === 'numberPlate' ? sortType : <FaSort/> }
                            </th> */}
                            {/*<th>Operating Status</th>*/}
                            <th> Location</th>
                            <th onClick={() => this.sortBy('type')}>
                                Type
                                { eventField === 'type' ? sortType : <FaSort/> }
                            </th>
                            <th onClick={() => this.sortBy('model')}>
                                Model
                                { eventField === 'model' ? sortType : <FaSort/> }
                            </th>
                            <th onClick={() => this.sortBy('machineNum')}>
                                Machine No.
                                { eventField === 'machineNum' ? sortType : <FaSort/> }
                            </th>
                            <th onClick={() => this.sortBy('ownerName')}>
                                Owner Name
                                { eventField === 'ownerName' ? sortType : <FaSort/> }
                            </th>
                            <th>Hourmeter (Hrs)</th>
                            <th>Contract Date</th>
                            <th>Contract Expiry</th>
                            <th>Last Operating Date/Time</th>
                        </tr>
                    </thead>
                    <tbody>
                        {equipments.length > 0 && equipments.map((equipment, i) => <Tr hasPermission={hasEqDetails ? true: false } equipment={equipment} key={i} push={push} loadMap={this.loadMap.bind(this)}/>)}

                        {loading && <tr><td colSpan='9' align='center' className='blinking'>Loading...</td></tr>}

                        {!loading && equipments.length===0 && <tr><td colSpan='9' align='center'>No Record Found</td></tr>}
                    </tbody>
                </table>

                <Pagination 
                    limit={LIMIT}
                    total={total}
                    activePage={activePage}
                    onChange={this.onPageChange}
                    data={equipments}
                />

            </Fragment>
        );
    }
}

const Tr = ({equipment, push, loadMap, hasPermission}) => <tr onClick={() => hasPermission ? push(`/service/equipment-detail/${equipment.id}/${equipment.machineSaleId}`) : "#"}>
    {/* <td>{equipment.numberPlate || " "}</td> */}
    {/*<td>{equipment.operatingStatus || " "}</td>*/}
    <td style={{width:'5%'}}>
        {(equipment.latitude && equipment.longitude) ? <span className="pointer mappointer" onClick={loadMap} lat={equipment.latitude} lon={equipment.longitude}>
        {(equipment.operatingStatus=='STOPPED') ? <img src={Stopped} alt="location" className="poi" style={{width:"30px"}} /> : <img src={Running} alt="location" className="poi" style={{width:"30px"}} /> }
        </span>: " "}
    </td>
    <td>{equipment.equipmentType ? equipment.equipmentType.toUpperCase() : " "}</td>
    <td>{equipment.model || " "}</td>
    <td>{equipment.machineNum || " "}</td>
    <td>{equipment.ownerName || " "}</td>
    <td>{equipment.totalOperatingHour ? parseFloat(equipment.totalOperatingHour).toFixed(1) : " "}</td>
    <td> { equipment.contractDate ? (<Moment format="DD MMM YYYY ">
            {equipment.contractDate}
        </Moment>) : " "} </td>
    <td> { equipment.contractExpireDate ? (<Moment format="DD MMM YYYY ">
            {equipment.contractExpireDate}
        </Moment>) : " "} </td>
    <td style={{width:'15%'}}>
        {/*{equipment.lastOperatingDateTime || " "}*/}
        { equipment.lastOperatingDateTime ? (<Moment format="DD MMM YYYY HH:mm:ss" tz="Asia/Kolkata">
            {equipment.lastOperatingDateTime}
        </Moment>) : " "} 
    </td>
</tr>

const mapDispatchToProps = {
    getSPEquipments, setLoader
}

const mapStateToProps = state => {
    return ({
        equipments: state.sp.equipments || [],
        countResult: state.sp.countResult || undefined
    });
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Equipment));

