import React, { Component, Fragment } from "react";
import { Field, reduxForm, reset, change } from "redux-form";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import { required } from "../../shared/Validation";
import { renderTextField } from "../../shared/renderField";
import { Row, Col } from "reactstrap";
import { saveListFota } from "../../../api/admin/fota";
import { setLoader, setToastr } from "../../../actions/Application";
import ListFotaComponent from "./ListFotaComponent";
import { getFotaEquipmentList } from "../../../actions/admin/fota"
import { getFotaCombIdList } from "../../../api/admin/fota";

let form = "FotaForm";

class AddFotaComponent extends Component {

    constructor(props) {
        super(props)
        this.state = {
            ack: false,
            searchItems: [],
            keyword: "",
            fieldType: "",
        };
        this.getEquipmentList = this.getEquipmentList.bind(this);
        this.goToItem = this.goToItem.bind(this);
    }
    getEquipmentList(e) {
        let fieldType = e.target.id
        let { value } = ReactDOM.findDOMNode(document.getElementById(e.target.id)), { searchEquipmentList } = this.props, regex = new RegExp(value.trim(), 'i');
        let filtered = [], models = [], machines = [];
        if (fieldType === 'comb_id' && value.length >= 1) {
            this.props.setLoader({ loader: { open: true } });
            getFotaCombIdList()
                .then((response) => {
                    this.props.setLoader({ loader: { open: false } });
                    const data = Array.isArray(response) ? response : response.data || [];
                    const filtered = data.filter(item =>
                        item.comb_id === null || item.comb_id.toString().includes(value)
                    );
                    if (filtered.length === 0) {
                        this.setState({ searchItems: [], fieldType })
                        console.log('error')
                    } else {
                        this.setState({ searchItems: filtered, fieldType });
                    }
                })
                .catch((error) => {
                    console.error('error', error);
                    this.setState({ serachItems: [] });
                });
        } else {
            if (fieldType && fieldType.includes('search')) {
                filtered = searchEquipmentList.filter(e => {
                    if (!models.includes(e.model.toString()) && fieldType === 'searchmodel') {
                        if (regex.test(e.model.toString())) {
                            models.push(e.model.toString());
                            return e;
                        }
                    } else if (!machines.includes(e.machineNum.toString()) && fieldType === 'searchmachineNum') {
                        if (regex.test(e.machineNum)) {
                            machines.push(e.machineNum.toString())
                            return e;
                        }
                    }
                });
            } 
        }

        this.setState({ searchItems: filtered, keyword: value, fieldType: fieldType });
    }

    componentDidMount() {
        this.props.getFotaEquipmentList();
    }

    goToItem = (attr, fieldType) => {
        //setting value
        fieldType = this.state.fieldType;
        if (fieldType && fieldType.includes('search')) {
            fieldType = fieldType.replace('search', '');
            this.props.dispatch(change('SearchCustomMapForm', fieldType, attr));
        } else {
            this.props.dispatch(change('FotaForm', fieldType, attr));
        }
        this.setState({ keyword: "", searchItems: [], fieldType: "" });
    }

    onSubmit = (values) => {
        values.model=values.model.toUpperCase();
        this.props.setLoader({ loader: { open: true } });
        saveListFota(values)
            .then(response => {
                if (response.data) {
                    this.props.setToastr({ toastr: { message: "Equipment has been added.", type: "success" } });
                    this.setState({ ack: true }, () => this.setState({ ack: false }));
                    //this.props.setTab(1);
                    this.props.dispatch(reset(form));
                    this.props.getFotaEquipmentList();
                } else {
                    throw new Error();
                }
                this.props.setLoader({ loader: { open: false } });
            })
            .catch(error => {
                this.props.setToastr({ toastr: { type: "error", message: error || "Something went wrong." } });
                this.props.setLoader({ loader: { open: false } });
            })
    }

    clearSearch = () => {
        this.setState({
            keyword: '',
            searchItems: [],
        });
    };

    handleCombIdSelection = (item) => {
        let { dispatch } = this.props;
        dispatch(change(form, "comb_id", item.comb_id ||""));
        dispatch(change(form, "sw_ver", item.sw_ver ||""));
        dispatch(change(form, "kernel_ver", item.kernel_ver ||""));
        this.setState({
            searchItems:[]
        });
    };

    render() {
        const { handleSubmit, submitting } = this.props, { ack, searchItems, fieldType, keyword } = this.state;
        return (
            <Fragment>
                <form name="FotaForm" id="FotaForm" onSubmit={handleSubmit(this.onSubmit)} autoComplete='off'>
                    <Row className="members align-items-center form-fota">
                        <Col sm="12">
                            <div className="row profile-asset">
                                <div className="col-sm-2">Model <span className='required'>*</span>
                                    <Field name="model" id='model' component={renderTextField} className='form-control' type="text" validate={[required]} placeholder="Enter Model No."/>
                                </div>
                                <div className="col-sm-2">Machine Number <span className='required'>*</span>
                                    <Field name="machineNum" id='machineNum' component={renderTextField} className='form-control' type="text" validate={[required]} onChange={this.getEquipmentList} placeholder="Enter Machine No." />
                                </div>
                                <div className="col-sm-2">
                                    Comb. ID <span className="required">*</span>
                                    <Field
                                        name="comb_id"
                                        id="comb_id"
                                        component={renderTextField}
                                        className="form-control"
                                        type="text"
                                        validate={[required]}
                                        onChange={(e) => {this.setState({comb_id:e.target.value});
                                        this.getEquipmentList(e);}}
                                        placeholder="Enter Combination ID."
                                    />
                                    {keyword.length > 0 && searchItems.length > 0 && fieldType === 'comb_id' && (
                                        <div className="search-area">
                                            <div className="card search-card" style={{width:'210%'}}>
                                                <div className="card-body" style={{ overflowY: "auto", maxHeight: "300px" }}>
                                                    <table className="table table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <th>Comb ID</th>
                                                                <th>Version</th>
                                                                <th>Kernel Version</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {searchItems.map((item, index) => (
                                                                <tr
                                                                    key={index}
                                                                    className="pointer"
                                                                    onClick={() => this.handleCombIdSelection(item)}
                                                                >
                                                                    <td>{item.comb_id || "N/A"}</td>
                                                                    <td>{item.sw_ver || "N/A"}</td>
                                                                    <td>{item.kernel_ver || "N/A"}</td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="col-sm-2">Version
                                    <Field name="sw_ver" id="sw_ver" component={renderTextField} className='form-control' type="text" readOnly/>
                                </div>
                                <div className="col-sm-2">Kernel Version
                                    <Field name="kernel_ver" id="kernel_ver" component={renderTextField} className='form-control' type="text" readOnly/>
                                </div>
                                <div className="col-sm-1">
                                    <button type="submit" className="btn btn-success srch-btn" disabled={submitting}>Save</button>
                                </div>
                            </div>

                        </Col>
                    </Row>

                </form>
                <ListFotaComponent ack={ack} getEquipmentList={this.getEquipmentList} searchItems={searchItems} goToItem={this.goToItem} keyword={keyword} fieldType={fieldType} clearSearch={this.clearSearch} />
            </Fragment>
        );
    }
}

const Item = props => {
    let { attr, goToItem, fieldType } = props;
    var listOption = attr.item[fieldType];
    return (
        <li className="list-group-item pointer" onClick={() => goToItem(listOption, fieldType)} >
            <Row>
                <Col sm='12' className='no-padd-lr'>
                    <span>{listOption}</span> &nbsp;
                </Col>
            </Row>
        </li>
    );
}
let formComponent = reduxForm({ form })(AddFotaComponent);
let mapDispatchToProps = { setLoader, setToastr, getFotaEquipmentList };
const mapStateToProps = state => {
    return ({
        searchEquipmentList: state.fota && state.fota.SearchCustomMapEqList && state.fota.SearchCustomMapEqList.data,
    });
}
export default connect(mapStateToProps, mapDispatchToProps)(formComponent);;
