import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import ReactDOM from "react-dom";
import { withRouter } from "react-router-dom";
import { FaTrash, FaPencilAlt } from "react-icons/fa";
/** importing sub-components */
import Pagination from "../../shared/Pagination";

/** importing APIs */
import { CRUDDataControl } from "../../../api/admin/data-control";
/** importnig constants */
import { LIMIT } from "../../../constants/Constants";
/** importing actions */
import { setConfirmBox, setLoader, setToastr } from "../../../actions/Application";

import { getDC } from "../../../actions/admin/dataControl";

class DataControl extends Component{
    constructor(props){
        super(props);

        this.state = {
            data: {
                msCompaniesPageList: [],
                msCompaniesTotalList: []
            },
            pages: 0,
            total:0,
            activePage:1,
            loading: true,
            editRow: [],
            copy: true
        }

        /** funciton binding */
        this.listDataControl = this.listDataControl.bind(this);
        this.editToggle = this.editToggle.bind(this);
        this.sortData = this.sortData.bind(this);
        this.deleteDataConfirmation = this.deleteDataConfirmation.bind(this);
        this.deleteRow = this.deleteRow.bind(this);
        this.upsertData = this.upsertData.bind(this);
        this.copyData = this.copyData.bind(this);
        this.clearSelection = this.clearSelection.bind(this);
        
    }

    componentDidMount(){
        //this.listDataControl();
        this.props.setLoader({ loader: {open:true}});

        this.props.getDC({page:1});
    }


    editToggle(key, action='add'){
        let {editRow, copy, data} = this.state;

        if(action === 'remove'){
            //editRow.splice(editRow.indexOf(key), 1);
            editRow.pop();
        }else{
            //editRow.push(key);
            if(!copy && editRow.length){
                data.msCompaniesPageList.splice(editRow[0], 1);    
                copy = true;
                editRow[0] = key-1;
            }else{
                editRow[0] = key;
            }
            
        }
        this.setState({ editRow, copy });
    }

    componentWillReceiveProps(newProps){
        if(newProps.countResult){
            this.props.setLoader({ loader: {open:false}});

            this.setState({
                loading: false, 
                data: { msCompaniesPageList: newProps.data.msCompaniesPageList, msCompaniesTotalList: newProps.data.msCompaniesTotalList || [] }, 
                activePage: this.state.activePage || 1,
                pages: (newProps.countResult && newProps.countResult.totalCounts) ? Math.ceil(newProps.countResult.totalCounts/LIMIT) : this.state.pages,
                total: (newProps.countResult && newProps.countResult.totalCounts) ? newProps.countResult.totalCounts : this.state.total 
            });
        }
    }

    listDataControl(page){
        //let offset = (page && page > 1) ? ((page - 1) * LIMIT) : 0;

        this.props.setLoader({ loader: {open:true}});
        this.setState({loading: true, activePage: page || 1});
        this.props.getDC({page});

        /** start loader */
        /* this.setState({loading: true});

        getDataControl(offset, LIMIT, true)
        .then(response => {
            let msCompaniesPageList = response.data.msCompaniesPageList || [], 
            childParentCompanies = response.data.childParentCompanies || [],
            data = (msCompaniesPageList && msCompaniesPageList.length) ?  msCompaniesPageList : childParentCompanies;

            this.setState({
                loading: false, 
                data: { msCompaniesPageList: data, msCompaniesTotalList: response.data.msCompaniesTotalList || [] }, 
                activePage: page || 1,
                pages: (response.data.countResult && response.data.countResult.totalCounts) ? Math.ceil(response.data.countResult.totalCounts/LIMIT) : this.state.pages,
                total: (response.data.countResult && response.data.countResult.totalCounts) ? response.data.countResult.totalCounts : this.state.total 
            });

        })
        .catch(error => this.setState({loading: false})); */
    }

    sortData(data){ 
        data.sort((a, b) => {
            let nameA=a.companyName.toLowerCase(), nameB=b.companyName.toLowerCase();
            if (nameA < nameB) return -1; //sort string ascending
            if (nameA > nameB) return 1;
            return 0; //default return value (no sorting)
        }); 
        return data;
    }

    deleteDataConfirmation(row){
        let company = JSON.parse(row), {setConfirmBox} = this.props;

        if(company && company.parentChildId){
            setConfirmBox({ modal: { open: true, data: {...company}, title: "Delete Data Row", content: "Do you really want to remove this row?", done: this.deleteRow }});
        }
    }


    deleteRow(data){
        return new Promise((resolve, reject) => {
            CRUDDataControl("delete", data)
                .then(result => {
                    if(result.data === 'false') throw new Error();
                    /** resolve promise */
                    resolve({ toastr: { message: "Data has been removed.", type: "success" } });
                })
                .catch(error => {
                    setToastr({ toastr: { message: "This request couldn't be completed. Try again later.", type: "error" } });
                    /** reject promise */
                    reject(error);
                })
        });
    }

    upsertData(child, parent, key){

        let p = document.getElementById("parentCompany"+key).value;

        if(!child || !p) return false;
        let childCompanyCode = child, parentChildId = parseInt(parent), i = key, parentSelectBox = ReactDOM.findDOMNode(document.getElementById("parentCompany"+i)).value, {setToastr, setLoader} = this.props;

        setLoader({ loader: { open: true }});
        this.setState({ copy: true });

        CRUDDataControl(parentChildId ? "update" : "save", {parentChildId: parentChildId || undefined, childCompanyCode, parentCompanyCode: parentSelectBox})
        .then(response => {

            if(response.data === 'false'){
                setToastr({ toastr: { message: "This operation couldn't be completed. Please try again later.", type: "error" } });
            }else{
                this.setState({ editRow: []});
                this.listDataControl();
                setToastr({ toastr: { message: "Data has been saved.", type: "success" } });
            }
            
            setLoader({ loader: { open: false }});
        })
        .catch(error => {
            setToastr({ toastr: { message: error || "This request couldn't be completed. Try again later.", type: "error" } });
            setLoader({ loader: { open: false }});
        })
    }

    copyData(key, action='copy', row=undefined){
        let { data, editRow } = this.state, copy = false;
        /** set parent child field to undefined */
        if(action === 'remove'){
            editRow.pop();
            //editRow.splice(editRow.indexOf(key), 1);
            if(row && row.copy) data.msCompaniesPageList.splice(key, 1);    
            copy = true;
        }else{
            data.msCompaniesPageList.splice(key+1, 0, {...row, copy:true, parentChildId:undefined});   
            editRow[0] = key+1;   
            //editRow.splice(editRow.indexOf(key), 1);
        }
        this.setState({data, editRow, copy});
    }

    clearSelection(){
        this.setState({editRow: []});
    }

    render(){   
        let {data, loading, editRow, copy, activePage, total} = this.state;

        return(
            <Fragment>

                <table className="tabledashboard table table-striped table-advance table-hover">
                    <thead>
                        <tr>
                            <th>Company ID</th>
                            <th>Company Name</th>
                            <th>Parent Company</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.msCompaniesPageList.length > 0 && data.msCompaniesPageList.map((row, index) => {
                                               
                            return(
                                <tr key={index}>
                                    <td width="20%"  className={row.copy ? "highlighted-row" : ""}>{row.companyCode || " "}</td>
                                    <td width="25%"  className={row.copy ? "highlighted-row" : ""}>{row.companyName || " "}</td>
                                    <td width="25%"  className={row.copy ? "highlighted-row" : ""}>
                                        <div>
                                            {editRow.includes(index) ? 
                                            <select name={`parentCompany${index}`} id={`parentCompany${index}`} className="form-control no-padded" defaultValue={row.parentCompanyCode ?row.parentCompanyCode.trim() : ""}>
                                                <option value=''>Select Parent Company</option>
                                                {row.parentChild && row.parentChild.map((company, i) => {
                                                    return <option key={i} value={company.companyCode.trim()}>{company.companyNameEN}</option>;
                                                })}
                                            </select>
                                            : <span className={row.parentCompanyName ? 'dashed' : "red-dashed"} onClick={() => this.editToggle(index)}>{(row.parentCompanyName || (row.parentChild!==null && row.parentChild.length)) ? row.parentCompanyName || "Choose Company" :""}</span>}
                                        </div>
                                    </td>
                                    <td width="30%"  className={row.copy ? "highlighted-row" : ""}>
                                    <Fragment>
                                        {editRow.includes(index)
                                            ? <Fragment>
                                                <button type='button' className='btn btn-success' data-child={row.companyCode.trim()} data-parent={row.parentChildId || undefined} onClick={() => this.upsertData(row.companyCode.trim(), (row.parentChildId || undefined), index)} data-key={index} >Save</button> &nbsp;

                                                {copy && row.parentChildId >0 && 
                                                    <Fragment>
                                                        <button className='btn btn-default' onClick={() => this.copyData(index, "copy", row)} >Copy</button> &nbsp;
                                                    </Fragment>    
                                                }
                                                <button className='btn btn-danger' onClick={() => row.parentChildId ? this.editToggle(index, "remove") : this.copyData(index, "remove", row)}>Cancel</button>
                                            </Fragment>
                                            : <Fragment> 

                                                {(row.parentChildId > 0) && <button className='clearbtn'  onClick={() => this.deleteDataConfirmation(JSON.stringify({parentChildId: row.parentChildId, childCompanyCode: row.companyCode, parentCompanyCode: row.parentCompanyCode}))}><FaTrash size='1.2em'/></button>}

                                                {(!row.parentChildId && row.parentChild!==null && row.parentChild.length>0 )&& <button className='clearbtn' onClick={() => this.editToggle(index)}><FaPencilAlt size='1.2em'/></button> }
                                              
                                            </Fragment>
                                        }
                                    </Fragment>
                                    </td>
                                </tr>
                            );
                        })}

                        {loading && <tr><td colSpan='9' align='center' className='blinking'>Loading...</td></tr>}

                        {!loading && data.msCompaniesPageList.length===0 && <tr><td colSpan='9' align='center'>No Record Found</td></tr>}
                    </tbody>
                </table>

                <Pagination
                    limit={LIMIT}
                    total={total}
                    activePage={activePage}
                    onChange={this.listDataControl}
                    data={data.msCompaniesPageList}
                />
            </Fragment>   
        );
    }
}


const mapDispatchToProps = { setConfirmBox, setLoader, setToastr, getDC }

const mapStateToProps = state => {
    return ({ 
        data: state.dataControl.data || [],
        countResult: state.dataControl.countResult || undefined
    });
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DataControl));