import { takeLatest, call, put } from 'redux-saga/effects';
import {getRoles,getRolesList} from '../../api/admin/roles';
import { GET_ROLE_LIST, SET_TOASTR, SET_ROLES,SET_ROLES_LIST,GET_ROLES } from '../../types';

/**
 * Call the api and dispatch data to the reducer
 * @param { Object } action Contained object data to be send to API call
 */
export function* getRoleListData(action) {
  try {
     const roles = yield call(getRoles, action.data);
     yield put({ type: SET_ROLES, data: {roles: roles.data.roles || [], countResult: roles.data.countResult} });
  }
  catch (e) {
      yield put({
          type: SET_TOASTR,
          data: {
              toastr: {
                  message: "This URL does not valid.", 
                  type:"info"
              }
          }
      });
  }
}
export function* getRolesData(action) {
  try {
     const roles = yield call(getRolesList, action.data);
     yield put({ type: SET_ROLES_LIST, data: {roles: roles.data.roles || [], countResult: roles.data.countResult} });
  }
  catch (e) {
      yield put({
          type: SET_TOASTR,
          data: {
              toastr: {
                  message: "This URL does not valid.", 
                  type:"info"
              }
          }
      });
  }
}
/**
 * map the dispatched action to the above function
 */
export default function* watchgetRoleListData() {
  yield takeLatest(GET_ROLE_LIST, getRoleListData)
  yield takeLatest(GET_ROLES,getRolesData)
}
