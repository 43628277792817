import React, { Component } from 'react';
import { Col, Row, FormGroup, CustomInput } from 'reactstrap';
import ScrollMenu from 'react-horizontal-scrolling-menu';
import SelectField from '../../../../components/shared/SelectField';
import Loaders from '../../../../messages/loaders/Loaders';
import ButtonField from '../../../../components/shared/ButtonField';
import SearchReportForm  from '../../../../forms/SearchReportForm';
import Graphs from '../../../../components/shared/Graphs'
import moment from 'moment';
import { CSVLink } from 'react-csv';
import defaultImg from "../../../../assets/images/tractor.jpg";
import noimage from "../../../../assets/images/noimage.png";
const list = ['January','February','March','April', 'May', 'June', 'July', 'August','September','October','November','December'];

const MenuItem = ({ text, selected }) => {
  return (
    <div
      className={`menu-item ${selected ? 'active' : ''}`}
      >
      {text}
    </div>
  );
};

export const Menu = (list) => list.map(name => {
  return (
    <MenuItem
      text={name.slice(0, 3)}
      key={name}
      />
  );
});

const Arrow = ({ text, className }) => {
  return (
    <div
      className={className}
      >{text}</div>
  );
};

export const ArrowLeft = Arrow({ text: '<', className: 'arrow-prev' });
export const ArrowRight = Arrow({ text: '>', className: 'arrow-next' });

/* A Work Report component */
class UtilizationReportComponent extends Component {

  constructor(props) {
    super(props)
    this.state = {
      data: {
          machineSaleId : '',
          fromDate: moment().subtract(1, "d"),
          toDate: moment().subtract(1, "d"),
          year : "",
          month : '',
      },
      selected: '',
      selecedLegends:[],
      itemsCount: list.length,
      translate: 0,
      alignCenter: true,
      dragging: true,
      clickWhenDrag: false,
      transition: 3,
      wheel: true,
      numberPlateOption: {},
      results: false,
      selectError : false,
      showData: true,
      selectedEq : {},
      gData: [],
      mapPoints: ['AVERAGE BATTERY VOLTAGE'], // define satatic data 
      mapProps: [],
      graphData: {
        /* totalDistanceTravelled : [],
        totalOperatingHour : [],
        averageEngineLoadFactor : [],
        averageActualEngineSpeed : [],
        averageDiffIntermediateTemp : [],
        averageCoolantTemp : [] */
      },
      yearError : false
    };
    this.xAxisData =[];
    this.menu = null;
    this.menuItems = Menu(list.slice(0, list.length), this.state.selected);
    this.setMapdata = this.setMapdata.bind(this);
    this.removeGraphItem = this.removeGraphItem.bind(this);
  }

  onSelect = key => {
    if(this.state.data.year ===""){
      this.setState({ yearError : true })
    } else {
      this.setState({ selected: key, yearError : false });
      let graphData = {
          /* totalDistanceTravelled : [],
          totalOperatingHour : [],
          averageEngineLoadFactor : [],
          averageActualEngineSpeed : [],
          averageDiffIntermediateTemp : [],
          averageCoolantTemp : [] */
      };
      this.setState({
        data: {
            ...this.state.data, "month": (list.indexOf(key)+1)
        } 
      },()=>{
        this.props.handleChange(this.state.data,0);
        this.setState({  graphData })
      });
    }
  }

  componentWillReceiveProps(nextProps){
    
   let gData = [];
    
    this.setState({showData:true});
    

    if (this.props.data !== nextProps.data) {
      this.setState({ showLoader: false })
    }

    if(this.props.showLoader !== nextProps.showLoader){
      this.setState({ showLoader: nextProps.showLoader })
    }

    if(nextProps.data && nextProps.data.length > 0){
      let mapPoints = [], mapProps=[], graphData = [];
      for(let k in nextProps.data[0].props){
          let key = k.replace(/ /i,"_");
          mapProps.push(k);
          mapPoints.push(key);
          graphData[key] = [];
      }

      if(nextProps.data && nextProps.data.length){
        nextProps.data.map(d => {
          gData.push({ "RECORD DATE": moment(d.reportDate).format("D MMM YYYY"), ...d.props });
        });
      }

      let _data = {};
      this.state.selecedLegends.forEach( e=> {
        let data = []
        nextProps.data.map((value)=> {
            data.push( parseFloat(value.props[e]));
          });
          
          _data[e.replace(/ /i, "_")] = data;
      });
      this.setState({ graphData: _data , mapPoints, mapProps, gData })

    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { alignCenter } = prevState;
    const {
      alignCenter: alignCenterNew
    } = this.state;
    if (alignCenter !== alignCenterNew) {
      this.menu.setInitial();
    }
  }

  takcareGraph() {
    if(document.getElementsByClassName('custom-control-input').length > 0){
       for(let d in document.getElementsByClassName('custom-control-input')){
         if(document.getElementsByClassName('custom-control-input')[d].type === "checkbox"){
           document.getElementsByClassName('custom-control-input')[d].checked = false;
         }
       }
     }
     this.setState({showData:false});
  }

  getYear() {
    let options = [];
    const d = new Date();
    for (let i = d.getFullYear(); i >= (d.getFullYear() - 20); i--) {
      options.push(<option key={i} value={i}> {i} </option>);
    }
    return { year : options, default : d.getFullYear()}
  }

  handleChange(type, data){
    if(data === undefined){
        data = type.target.value;
        type = type.target.name;
    }

    this.setState(
      {
          data: {
              ...this.state.data, [type]: data
          } 
      }, () => {
        if(type === "year"){
          this.handleClick();
        }
      });

      
  }
  
  handleClick(opt, e){
    let graphData = {
        /* totalDistanceTravelled : [],
        totalOperatingHour : [],
        averageEngineLoadFactor : [],
        averageActualEngineSpeed : [],
        averageDiffIntermediateTemp : [],
        averageCoolantTemp : [] */
    };
    let data = this.state.data;
    if(opt){
      this.setState({
          selected : "", data: {
              ...this.state.data, 'year' : ''
          } 
      });
      data = {
        fromDate : this.state.data.fromDate,
        toDate : this.state.data.toDate,
        machineSaleId : this.state.data.machineSaleId
      }
      if(document.getElementsByClassName('menu-item-wrapper').length > 0){
        for( let p in document.getElementsByClassName('menu-item-wrapper')){
          if(!isNaN(p)){
            document.getElementsByClassName('menu-item-wrapper')[p].className = 'menu-item-wrapper'
          }
        }
      }
    }

    this.setState({showData:true, graphData });
    
    if(this.state.data.machineSaleId === "" || this.state.data.machineSaleId === "null"){
      this.setState({ selectError: true })
    }else{

     this.props.handleChange(data);
     // this.takcareGraph();
      let selectedEq = this.props.equipments.equipmentData.filter((val,index)=>{
        return (val.machineSaleId === parseInt(this.state.data.machineSaleId)) ? val : ''
      });
      this.setState({ results: true, showLoader: false,selectError: false, selectedEq: selectedEq  })
    }
  }

  csvExportData(){
    const { mapProps, mapPoints, gData } =  this.state;

    const headers = [
 /*        
        { label: this.props.langtranslate('avActEnbSpeed'), key: 'averageActualEngineSpeed' },
        { label: this.props.langtranslate('avAshAmount'), key: 'averageAshAmount' },
        { label: this.props.langtranslate('avCoolantTemp'), key: 'averageCoolantTemp' },
        { label: this.props.langtranslate('avDiffAshAmt'), key: 'averageDiffAshAmount' },
        { label: this.props.langtranslate('avDiffIntTemp'), key: 'averageDiffIntermediateTemp' },
        { label: this.props.langtranslate('averageDiffPmAmount'), key: 'averageDiffPmAmount' },
        { label: this.props.langtranslate('averageEngLdFac'), key: 'averageEngineLoadFactor' },
        { label: this.props.langtranslate('averagePmAmount'), key: 'averagePmAmount' },
        { label: this.props.langtranslate('averageSpeed'), key: 'averageVehicleSpeed' },
        { label: this.props.langtranslate('fuelConsumption'), key: 'totalFuelConsumption' },
        { label: this.props.langtranslate('totalDistance'), key: 'totalDistanceTravelled' },
        { label: this.props.langtranslate('operatingHours'), key: 'totalOperatingHour' },
        { label: this.props.langtranslate('reportDate'), key: 'reportDate' } */
      ];

      /* mapProps.map((point, i) => {
        headers.push({ label: point, key: mapPoints[i] });
      }) */

    let data = (gData && gData.length > 0)?gData:[];
    return [headers, data];
  }

  setMapdata( item ) {
    let _data = this.props.data.map((value)=>{
      return parseFloat(value.props[item]);
    });

    this.setState({
      graphData: {
          ...this.state.graphData, [item.replace(/ /i, "_")]: _data
      }
    });


  }

  render() {
    const { langtranslate, numberPlateOption, data  } = this.props;
    let xAxisData;
    if(data && data.length > 0){
      xAxisData = data.map((value)=>{
        return moment(value['reportDate']).format('Do MMM YY');
      });
 
    }
   
    const {
      selected,
      translate,
      alignCenter,
      dragging,
      clickWhenDrag,
      transition,
      wheel,
      mapProps,
      mapPoints
    } = this.state;


    const menu = this.menuItems;
    {this.state.selecedLegends && mapProps && mapProps.map((prop, i) => {
    })}
    return (
      
      <div className="workreport">
       { this.state.showLoader && <Loaders
          loadingText={ `Please Wait...` }
          modalOpen={ true }
          className={ `loading-contents` }
          color={ '#08a5e1' }
          /> }
      <Row>
          <Col lg={12}>
              <SearchReportForm
                  module='utilization'
                  translate = { langtranslate }
                  numberPlateOption = { numberPlateOption}
                  handleChange = { this.handleChange.bind(this) }
                  fromDate = { this.state.data.fromDate }
                  toDate = { this.state.data.toDate }
                  report = { true }
                  selectError = { this.state.selectError }
              />
              <ButtonField className="float-right btn-success text-uppercase m-2"
                clickEvent={true}
                buttonText={langtranslate('search')}
                handleClick = { this.handleClick.bind(this,true) }
              />
          </Col>
      </Row>
      { this.state.results && 
        <div>
          <Row className="eqpdata">
              <Col lg={3} sm={4} className="details">
                <img className="eqpLogo" src={this.state.selectedEq[0].logo || noimage} alt="logo"/>
                <div className="tractord">equipment detail</div>
              </Col>
              <Col lg={3} sm={2} className="details">
                <div className="title">{ langtranslate('model') }</div>
                <div>{ this.state.selectedEq[0].model }</div>
              </Col>
              <Col lg={3} sm={3} className="details">
                <div className="title">{ langtranslate('machineno') }</div>
                <div>{this.state.selectedEq[0].machineNum }</div>
              </Col>
              <Col lg={3} sm={3} className="details">
                <div className="title">{ langtranslate('address') }</div>
                <div>{ this.state.selectedEq[0].address }</div>
              </Col>
          </Row>
          <Row>
            <Col lg={2}>
              <SelectField
                divClass = "m-4"
                type = "select"
                options =  { this.getYear().year }
                value = { this.state.data.year }
                name = "year"
                className = { (this.state.yearError) ? 'border-danger' : '' }
                handleChange = { this.handleChange.bind(this) }
                defaultOption = "Select Year"
                />
            </Col>
            <Col lg={7}>
              <div className="scrollmenu">
                <ScrollMenu
                  ref={el => this.menu = el}
                  data={menu}
                  arrowLeft={ArrowLeft}
                  arrowRight={ArrowRight}
                  transition={+transition}
                  onSelect={this.onSelect}
                  selected={selected}
                  translate={translate}
                  alignCenter={alignCenter}
                  dragging={dragging}
                  clickWhenDrag={clickWhenDrag}
                  wheel={wheel}
                  />
              </div>
            </Col>
            <Col lg={3}>
            {(data && data.length > 0) ?
              <CSVLink
                className="float-right btn btn-success text-uppercase mt-4"
                data={this.csvExportData()[1]}
                
                filename = { 'utilizationreportdata.csv'}
              >
                {langtranslate('downloadCSV') }
              </CSVLink>
              :
              ''
            }
            </Col>
          </Row>
          {(data && data.length && this.state.showData > 0) ?
            <Row>
              <div className="cardCover">
                
                {mapProps && mapProps.length > 0 && <Col lg={12} className="float-left">
                  <Graphs graphData={this.state.graphData} xAxisData = {xAxisData} />
                </Col>}
                
                  <Col lg={12} className="float-left">
                  <div className="selectedMonth">{this.state.selected} {this.state.data.year}</div>
                  <div className="reportstype">{langtranslate('typesOfReports')} </div>
                  <div className="reportstypelist">
                    <FormGroup check>
                      {mapProps && mapProps.map((prop, i) => {
                        return <CustomInput key={i} type="checkbox" checked={this.state.selecedLegends.indexOf(prop)!==-1} onChange={this.clickCheckBox.bind(this) } name={prop} id={mapPoints[i]} label={prop}/>;
                      })}
                     
                      </FormGroup>
                  </div>
                </Col>

                {mapProps && mapProps.length === 0 && langtranslate('noReportFound') }
                
              </div>
            </Row>
            :
              <Row>
                <div className="cardCover text-center">
                  <b> { (this.state.showLoader) ? langtranslate('loading') : langtranslate('dataNotAvailable')} </b>
                </div>
              </Row>
          }
        </div>
      }
      </div>
    );
  }
 /*  removeGraphItem(e) {
    var array = [...this.state.graphData]; // make a separate copy of the array
    var index = array.indexOf(e.target.value);
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({graphData: array});
    }
  } */

  removeGraphItem(e) {
    let obj = this.state.graphData;
    delete obj[e.replace(/ /i, "_")];
    this.setState({graphData: obj});
}
  clickCheckBox(e){
    const item = e.target.name;
    let selecedLegends = this.state.selecedLegends;
   
    if(e.target.checked) {
      selecedLegends.indexOf(item) === -1 ? selecedLegends.push(item) :console.log('');
      //selecedLegends.push(item);
      this.setMapdata(item);
    } else {
      let index = selecedLegends.indexOf(item);
      selecedLegends.splice(index, 1);
      this.removeGraphItem(item);
      //
    }

    selecedLegends.filter((a, b) => selecedLegends.indexOf(a) === b);
    this.setState( { selecedLegends});
  }
 

}
export default UtilizationReportComponent;
