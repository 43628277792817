import http from "../../services/http";
import {  __api_saveListFota, __api_getSavedMapEqList, __api_deleteFota, __api_getFota ,__api_getFotaEqList,__api_getFotaCombIdList} from "./admin.endpoints";
import { LIMIT } from "../../constants/Constants";

export const saveListFota = (data) => http.Request("POST", __api_saveListFota, data);

export const deleteFota = (id) => http.Request("DELETE", [__api_deleteFota, id].join("/"));

export const getFota = (req) => {
    if(req.qs){ req.data = req.qs};
    let offset = (req.page && req.page > 1) ? ((req.page - 1) * LIMIT) : 0;
    return http.Request("GET", [__api_getFota, offset, LIMIT].join("/"), req.data);
};

export const getFotaCombIdList = (req) => {
    return http.Request("GET",__api_getFotaCombIdList)
};

export const getFotaEqList = (data) =>{
    return http.Request("GET",__api_getFotaEqList)
}
export const getAllFotaEqList = (data) =>{
    //need to change
    return http.Request("GET",__api_getSavedMapEqList)
}