import http from "../../services/http";
import { LIMIT } from "../../constants/Constants";
import { __api_getCustomMap, __api_saveCustomMap, __api_getRegisteredMaps, __api_getCustomAddedMap,__api_getMapEqList,__api_getSavedMapEqList, __api_deleteMap, __api_uploadMap } from "./admin.endpoints";

/* export const getDataControl2 = (offset, limit=LIMIT, countFlag=true) => http.Request("GET", [__api_getDataControl, offset, limit, countFlag].join("/"));
 */
export const getCustomMap = (req) => {
    let offset = (req.page && req.page > 1) ? ((req.page - 1) * LIMIT) : 0;
    return http.Request("GET", [[__api_getCustomMap, offset, LIMIT].join("/"), req.qs].join("?"), req.data);
};

export const getCustomAddedMap = (req) => {
    if(req.qs){ req.data = req.qs};
    let offset = (req.page && req.page > 1) ? ((req.page - 1) * LIMIT) : 0;
    return http.Request("GET", [__api_getCustomAddedMap, offset, LIMIT].join("/"), req.data);
};

export const getRegisteredMaps = (req) => {
    let offset = (req.page && req.page > 1) ? ((req.page - 1) * LIMIT) : 0;
    return http.Request("GET", [[__api_getRegisteredMaps, offset, LIMIT].join("/")].join("?"), req.qs);
};

export const deleteMap = (id) => http.Request("DELETE", [__api_deleteMap, id].join("/"));

export const saveMap = (data) => http.Request("POST", __api_saveCustomMap, data);

export const getCustomMapEqList = (data) =>{
    return http.Request("GET",__api_getMapEqList)
}
export const getSavedCustomMapEqList = (data) =>{
    return http.Request("GET",__api_getSavedMapEqList)
}

