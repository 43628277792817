import React, { Component } from 'react';
import DataSheet from './datasheet/DataSheet';


/* A Equipment Alert component */
class ScheduleServiceAlertComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            serviceAlertData: [],
        };

    }

    handleSubmit(data){
        this.props.handleSubmit(data);
    }

    render() {
        const { translate, serviceAlertData } = this.props;
        return (
            <div className="eqalertcontainer">
                <DataSheet serviceAlertData={ serviceAlertData } apiError = { this.props.apiError } handleSubmit={this.handleSubmit.bind(this)} translate = { translate } />
            </div>
        );
    }
}

export default ScheduleServiceAlertComponent;
