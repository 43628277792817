import React, { Component } from 'react';
import ReactDOM from "react-dom";
import { Link } from 'react-router-dom';
import InputField from '../components/shared/InputField';
import ButtonField from '../components/shared/ButtonField';
import Validator from 'validator';
import InlineError from '../messages/InlineErrors/Inline';
import Loaders from '../messages/loaders/Loaders';
import profileImage from '../assets/images/logo.jpg';
import session from "../services/session";
import { Alert, CustomInput, Form, FormGroup, FormText, Card, CardBody, Label } from 'reactstrap';


/** An dump login component */
class LoginForm extends Component {

	constructor(props) {
        super(props);
        this.state = {
			data: {
				username: '',
				password: '',
			},
			loading: false,
			showPopUp: false,
			showLoader: false,
			errors: {},
			serverError: '',
			wrongDetail : false
		};

	}


	componentDidMount(){
		let data = session.get("rememberme", "cookie");
		let persistentData = session.get("remembermepersistent","cookie");
		if (data) {
			this.setState({ data });
			/** uncheck the remember me checkbox */
			ReactDOM.findDOMNode(document.getElementById("rememberme")).checked = false;
		}
		else if(persistentData){
			this.setState({ data:persistentData });
			/** check the remember me checkbox */
			ReactDOM.findDOMNode(document.getElementById("rememberme")).checked = true;
		}
	}
    /**
     * Get the new props and set into state if they are not same.
     * @param  { Object } nextProps Contains the next props
     */
    componentWillReceiveProps(nextProps) {
		if(this.props.loggedInData !== nextProps.loggedInData){
			let notify = (nextProps.msg.length > 0 && nextProps.loggedInData.isSending === false) ? true : false ;
			this.setState({ showLoader: nextProps.loggedInData.isSending, serverError: nextProps.msg, wrongDetail : notify })
		}
    }

    /**
     * Get and validate the user inputs pass to the parent components
     * @param  { Object } e Contains the user data
     */
	handleSubmit(e) {
		e.preventDefault();
		const errors = this.validate(this.state.data);
		this.setState({ errors });
		if (Object.keys(errors).length === 0) {

			let remember = ReactDOM.findDOMNode(document.getElementById("rememberme"));
			if(remember.checked){
				// save user data in persistent cookie
				session.setPersistentCookie("remembermepersistent", this.state.data,60);
				//clear session cookie
				session.clear("rememberme","cookie");
			}else{
				//save user data in session cookie 
				session.set("rememberme", this.state.data,"cookie");
				//clear persistent cookie
				session.clear("remembermepersistent","cookie");
			}

			this.setState({ showLoader: true });
			this.props.loginForm(this.state.data)
		}
	}

	/**
	 * Validate the user inputs
	 * @param  { Object } data User inputs
	 */
	validate(data) {
		const errors = {};
		if (Validator.isEmpty(data.username)) {
			errors.username = 'Enter valid user ID'
			this.setState({ isValidUsername: true })
		} else {
			this.setState({ isValidUsername: false })
		}
		if (!data.password) {
			this.setState({ isValidPass: true })
			errors.password = "Password can't be empty";
		}

		return errors;
	}
	
	/**
	 * Set the state of user input on change
	 * @param  { Object } e Contains the user data
	 */
	handleChange(e) {
		e.preventDefault();
		this.setState({
			data: {
	    		...this.state.data, [e.target.name]: e.target.value
	    	} 
		});
	}

	/**
	 * Hide the error pop up
	 */
	hidePopUp = () => {
      this.setState({wrongDetail: false})
	}

	render() {
		const { data,serverError } = this.state;
		const { translate } = this.props;
		const url = window.location.pathname;
		return (
			<div className="view loginView">
				<div className="box-login">
					<Card className="mb-3 form-card">
						<div className="topheader"><img src={ profileImage } alt="avatar"/></div>
						<CardBody>
							{ this.state.showLoader && <Loaders
								loadingText={ `Please Wait...` }
								modalOpen={ true }
								className={ `loading-contents` }
								color={ '#08a5e1' }
								/> }
							{ this.state.wrongDetail && <Alert isOpen={true} toggle={this.hidePopUp.bind(this)} color="danger"><InlineError text={serverError || ''}/></Alert> }
							<Form className="loginforms" onSubmit={this.handleSubmit.bind(this) }>
								<FormGroup>
									<Label>{translate('userID') }</Label>
									<InputField
										name="username"
										type="text"
										id="username"
										value={data.username}
										handleChange={ this.handleChange.bind(this) }
										placeholder={translate('pleaseEnterYourLoginID')}
										/>
									{ this.state.isValidUsername && <InlineError text={ this.state.errors.username || '' }/> }
								</FormGroup>
								<FormGroup>
									<Label>{translate('password') }</Label>
									<InputField
										name="password"
										id="password"
										type="password"
										value={data.password}
										handleChange={ this.handleChange.bind(this) }
										formGroupClass={ `mb-0` }
										placeholder={translate('pleaseEnterYourLoginpassword')}

										/>
									{ this.state.isValidPass && <InlineError text={ this.state.errors.password || '' }/> }
								</FormGroup>
								<FormGroup check>
									<CustomInput type="checkbox" id="rememberme" label={translate('rememberIdPassword') } value={true} />
								</FormGroup>
								<FormGroup className="text-right">
									<ButtonField
										type="submit"
										name = "loginbtn"
										id = "loginbtn"
										className = "btn-danger myloginbtn"
										buttonText = {translate('login') }
										handleClick = {this.handleSubmit.bind(this) }
										/>
								</FormGroup>

								<FormGroup>
									<FormText className="text-right">
										
										{url.indexOf("customer") >= 0 ? <Link to={`${url}/forget-password`} className={'text-red'}>{translate('forgotPassword') }</Link>
										:
										<Link to={`#`} className={'text-red admin fp'}>{translate('forgotPassword') }</Link>}
									</FormText>
								</FormGroup>
							</Form>
						</CardBody>
					</Card>
				</div>
			</div>
		);
	}
}

export default LoginForm;
